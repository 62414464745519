import React, { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  PopoverBody,
  Row,
  Spinner,
  UncontrolledPopover,
} from 'reactstrap';
import {
  delistPublishedResellingProducts,
  getPublishedForResellingProducts,
  updatePublishedResellingProducts,
} from '../../../api/resellingProducts.js';
import DataTable from '../../tables/table';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Cols } from '../../SeparatorStyle/SeparatorStyle';
import { Link } from 'react-router-dom';
import Select from 'react-select';

export const ResellerListedProducts = () => {
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchTrigger, setSearchTrigger] = useState(false);

  const [paginationLoading, setPaginationLoading] = useState(false);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [allVendors, setAllVendors] = useState([]);

  const [total, setTotal] = useState('');
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [masterCheck, setMasterCheck] = useState(false);
  const [delistLoading, setDelistLoading] = useState(false);
  const [editResaleData, setEditResaleData] = useState({});
  const [updateLoading, setUpdateLoading] = useState(false);
  const [sellersOptions, setSellersOptions] = useState([]);
  const [sellersFilterValue, setSellersFilterValue] = useState([]);

  let totalPagesList = Math.ceil(total / pageLimit);

  const loadPublishedProducts = useCallback(
    async ({
      limit = pageLimit,
      page = pageNumber,
      title = searchValue,
      sellers = sellersFilterValue?.map((obj) => obj.value),
    }) => {
      setMasterCheck(false);
      setSelectedList([]);
      try {
        const { data } = await getPublishedForResellingProducts({
          limit,
          page,
          title,
          sellers,
        });

        if (data) {
          setProducts(data.products);
          setTotal(data.totalCount);

          if (sellersFilterValue.length === 0) {
            let options = data.products.map((obj) => {
              return {
                value: obj.resellingData[0].seller[0]._id,
                label: obj.resellingData[0].seller[0].userName,
              };
            });

            let uniqueObjArray = [
              ...new Map(options.map((item) => [item['value'], item])).values(),
            ];

            setSellersOptions(uniqueObjArray);
          }

          setLoading(false);
          setFilterLoading(false);
          setPaginationLoading(false);

          data.totalCount === 0 &&
          !searchValue &&
          sellersFilterValue.length === 0
            ? setEmptyStatus(true)
            : setEmptyStatus(false);
        }
      } catch (err) {
        toast.error('Reselling Products loading error!');
      }
    },
    [pageLimit, pageNumber, searchTrigger, sellersFilterValue]
  );
  console.log(sellersOptions);
  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    loadPublishedProducts({});
  }, [loadPublishedProducts]);

  const toTitleCase = (str) => {
    let replaced = str?.replace(
      /\w\S*/g,
      (txt) => txt?.charAt(0).toUpperCase() + txt?.substr(1).toLowerCase()
    );
    return replaced;
  };

  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const onItemCheck = (item, e, ind) => {
    // let tempList = products;
    // tempList.map((obj, index) => {
    //   if (index === ind) {
    //     obj.selected = e.target.checked;
    //   }
    //   return obj;
    // });
    // setSelectedList(tempList.filter((e) => e.selected));
  };

  const handleSelect = (product, e, index) => {
    let tempList = products;
    tempList.map((obj, i) => {
      if (index === i) {
        obj.selected = e.target.checked;
      } else {
        obj.selected = false;
      }
      return obj;
    });
    setSelectedList(tempList.filter((e) => e.selected));
    setEditResaleData(JSON.parse(JSON.stringify(product.resellingData[0])));
  };

  const handleMasterCheck = (e) => {
    // let tempList = products;
    // setMasterCheck(e.target.checked);
    // tempList.map((obj) => (obj.selected = e.target.checked));
    // setSelectedList(tempList.filter((e) => e.selected));
  };

  const handleCloseSideBar = () => {
    let tempList = products;
    setMasterCheck(false);
    tempList.map((obj) => (obj.selected = false));

    setSelectedList([]);
  };

  const blockInvalidChar = (e) => {
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  };

  const getUnique = (array) => {
    let uniqueArray = [];

    // Loop through array values
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  };

  // const vendorOptions = getUnique(allVendors.map((p) => p.vendor)).map((p) => {
  //   return { value: p, label: p };
  // });

  const handleResaleData = (e) => {
    let { name, value } = e.target;
    setEditResaleData({ ...editResaleData, [name]: value });
  };

  const handleUpdateListedProduct = async (product) => {
    setUpdateLoading(true);
    try {
      const response = await updatePublishedResellingProducts(product);
      if (response.status === 200) {
        toast.success('Product updated successfully!');
        setUpdateLoading(false);
        handleCloseSideBar();
        setLoading(true);
        setPageLimit(50);
        setPageNumber(1);
        loadPublishedProducts({});
      } else {
        setUpdateLoading(false);
        toast.error(response.error);
      }
    } catch (error) {
      setUpdateLoading(false);
      toast.error('Internal server error!');
    }
  };

  const handleDelistForResalling = async (deleting) => {
    setDelistLoading(true);
    try {
      const response = await delistPublishedResellingProducts(deleting);

      if (response.status === 200) {
        toast.success('Product delisted successfully!');
        setUpdateLoading(false);
        handleCloseSideBar();
        setDelistLoading(false);
        setLoading(true);
        setPageLimit(50);
        setPageNumber(1);
        loadPublishedProducts({});
      } else {
        setDelistLoading(false);
        toast.error(response.error);
      }
    } catch (error) {
      setDelistLoading(false);
      toast.error('Internal server error!');
    }
  };

  const handleSearch = (e) => {
    let searchValue = e.target.value;

    setSearchValue(searchValue);
    setEmptyStatus(false);

    const timeOutId = setTimeout(async () => {
      if (searchValue === e.target.value && searchValue.trim().length > 2) {
        setPageNumber(1);
        setPageLimit(50);

        setSearchTrigger(!searchTrigger);
        setFilterLoading(true);
      }
      if (!searchValue && searchValue === e.target.value) {
        setPageNumber(1);
        setPageLimit(50);
        setSearchTrigger(!searchTrigger);
        setFilterLoading(true);
      }
    }, 2000);
    return () => clearTimeout(timeOutId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let editData = selectedList.map((obj) => {
      return {
        resellingPrice: editResaleData?.resellingPrice,
        publishStatus: editResaleData?.publishStatus,
        resellingProductId: editResaleData?._id,
        sellerPid: obj._id,
      };
    });

    handleUpdateListedProduct(editData[0]);
  };

  const ListForResaleRows =
    products &&
    products?.map((product, index) => {
      return {
        check: (
          <label className="custom-control mb-0 pointer">
            <Input
              className="item-check pointer"
              style={{
                border: '0.1px solid gray',
              }}
              type="checkbox"
              checked={product?.selected}
              label=""
              disabled={paginationLoading || filterLoading}
              onChange={(e) => handleSelect(product, e, index)}
            />
          </label>
        ),
        image: (
          <img
            src={product?.image?.src || './img/products/no-image.png'}
            alt=""
            className="listingImage"
          />
        ),
        sku: product.resellingData[0]?.resellingSKU ? (
          <>
            {String(product.resellingData[0]?.resellingSKU)?.slice(0, 20)}
            {String(product.resellingData[0]?.resellingSKU).length > 20 && (
              <>
                {' ... '}
                <i className="simple-icon-info pointer" id={'sku' + index}>
                  <UncontrolledPopover
                    trigger="hover"
                    placement="top"
                    target={'sku' + index}
                  >
                    <PopoverBody className="text-center">
                      {product.resellingData[0]?.resellingSKU}
                    </PopoverBody>
                  </UncontrolledPopover>
                </i>
              </>
            )}
          </>
        ) : (
          'N/A'
        ),
        productName: (
          <>
            {toTitleCase(product?.title).slice(0, 20)}
            {product.title.length > 20 && ' ... '}
            {product.title.length > 20 && (
              <i className=" simple-icon-info pointer" id={'Tooltip-' + index}>
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target={'Tooltip-' + index}
                >
                  <PopoverBody className="text-center">
                    {product.title}
                  </PopoverBody>
                </UncontrolledPopover>
              </i>
            )}
          </>
        ),

        status: (
          <Badge
            pill
            color={
              product.resellingData[0]?.publishStatus === 'active'
                ? 'success'
                : 'danger'
            }
          >
            {toTitleCase(product.resellingData[0]?.publishStatus)}
          </Badge>
        ),

        saleCount: 'N/A',
        type: product.type,
        collection: product.category,
        storePrice: product.resellingData[0]?.resellingPrice,
        currentQuantity: product.resellingData[0]?.resellingInventory || 'N/A',
        dailySale: 'N/A',
        seller: product.resellingData[0]?.seller[0]?.userName || 'N/A',

        action: (
          <span
            className="simple-icon-pencil fs-5"
            // onClick={() => {
            //   handleSelect(product, index);
            //   // setSelectedList([product]);
            //   // setResellingData(
            //   //   JSON.parse(JSON.stringify(product.forResaleData[0]))
            //   // );
            //   // setMultiShippingCharges(
            //   //   JSON.parse(
            //   //     JSON.stringify(product.forResaleData[0].shippingCharges)
            //   //   )
            //   // );
            // }}
          ></span>
        ),
      };
    });

  let ListedFoorResaleCols = [
    {
      Header: (
        <label className="custom-control mb-0 pointer">
          <Input
            disabled={paginationLoading || filterLoading}
            checked={masterCheck}
            onChange={(e) => handleMasterCheck(e)}
            type="checkbox"
            className="item-check pointer"
            style={{
              border: '0.1px solid gray',
            }}
          />
        </label>
      ),
      accessor: 'check',

      Cell: (props) => props?.value,
      cellClass: 'w-5',
    },
    {
      Header: 'Image',
      accessor: 'image',
      cellClass: 'text-muted w-1',
      Cell: (props) => props?.value,
    },
    {
      Header: 'SKU',
      accessor: 'sku',
      cellClass: 'w-10',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Name',
      accessor: 'productName',
      cellClass: 'w-15',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Status',
      accessor: 'status',
      cellClass: 'w-10',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Type',
      accessor: 'type',
      cellClass: ' w-10',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Collection',
      accessor: 'collection',
      cellClass: 'w-10',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Store Price',
      accessor: 'storePrice',
      cellClass: 'w-10',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Current Qty',
      accessor: 'currentQuantity',
      cellClass: 'w-12',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Daily Sale',
      accessor: 'dailySale',
      cellClass: 'w-12',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Seller',
      accessor: 'seller',
      cellClass: 'w-12',
      Cell: (props) => props?.value,
    },

    {
      Header: 'Actions',
      accessor: 'action',
      cellClass: 'w-1',
      Cell: (props) => props?.value,
    },
  ];

  const ListedFoorResaleColsMemo = useMemo(
    () => ListedFoorResaleCols,
    [masterCheck, products, paginationLoading, filterLoading]
  );

  return (
    <main>
      <Row className="top-text">
        <Cols xxs="12">
          <h1>Reseller Listed Products</h1>
          <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
            <ol className="breadcrumb pt-0">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard" style={{ color: 'black' }}>
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Reseller Listed Products
              </li>
            </ol>
          </Breadcrumb>
        </Cols>
      </Row>
      {loading ? (
        <div className=" position-relative" style={{ height: '60vh' }}>
          <span className=" loading position-absolute"></span>
        </div>
      ) : emptyStatus ? (
        <Card>
          <div
            className=" d-flex align-items-center justify-content-center text-danger"
            style={{ height: '30vh' }}
          >
            <h3 className="fw-bold">No Products Found!</h3>
          </div>
        </Card>
      ) : (
        <Card
          className={selectedList.length > 0 ? 'w-70 transform-table' : ''}
          style={{ transition: 'transform .5s' }}
        >
          <CardBody>
            <div className=" d-flex justify-content-between my-3 flex-wrap">
              <div className=" mb-2 mr-2 " style={{ width: '200px' }}>
                <div className=" position-relative" style={{ width: '180px' }}>
                  <Input
                    disabled={paginationLoading || filterLoading}
                    className="rounded-3"
                    type="text"
                    name="keyword"
                    value={searchValue}
                    id="search"
                    placeholder="Search"
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  {searchValue && (
                    <span
                      className=" position-absolute far fa-close fs-6 text-danger pointer"
                      style={{ right: '13px', top: '11px' }}
                      onClick={() => {
                        setSearchTrigger(!searchTrigger);
                        setSearchValue('');
                        setFilterLoading(true);
                      }}
                    ></span>
                  )}
                </div>
              </div>
              <div className=" d-flex">
                <div
                  style={
                    sellersFilterValue.length === 0 ? { width: '160px' } : {}
                  }
                >
                  <Select
                    hideSelectedOptions
                    isMulti
                    isDisabled={paginationLoading || filterLoading}
                    isClearable
                    value={sellersFilterValue}
                    onChange={(e) => {
                      setPageNumber(1);
                      setPageLimit(50);
                      setFilterLoading(true);
                      setSellersFilterValue(e);
                    }}
                    options={sellersOptions}
                    placeholder={'Select Sellers'}
                    className=""
                  />
                </div>
              </div>
            </div>

            {paginationLoading && (
              <div className="mt-3 mx-3">
                <Alert color="info">
                  <Spinner
                    color="light"
                    size={'sm'}
                    style={{ marginBottom: '3px' }}
                  ></Spinner>{' '}
                  &nbsp;
                  <span style={{ fontSize: '16px', color: 'black' }}>
                    Products Loading!
                  </span>
                </Alert>
              </div>
            )}

            {filterLoading ? (
              <div className=" position-relative" style={{ height: '180px' }}>
                <span className=" position-absolute loading"></span>
              </div>
            ) : products.length === 0 ? (
              <div
                className=" d-flex align-items-center justify-content-center text-danger"
                style={{ height: '30vh' }}
              >
                <h3 className="fw-bold">No Products Matched!</h3>
              </div>
            ) : (
              <DataTable
                fetchData={loadPublishedProducts}
                columns={ListedFoorResaleColsMemo}
                data={ListForResaleRows}
                setPageLimitInParent={setPageLimit}
                setPageNumberInParent={setPageNumber}
                pageCount={totalPagesList}
                setPaginationLoading={setPaginationLoading}
                paginationLoading={paginationLoading}
              />
            )}
            {
              //---------------//
              <Offcanvas
                show={selectedList.length > 0}
                onHide={() => {
                  handleCloseSideBar();
                }}
                placement={'end'}
                scroll={true}
                backdrop={false}
              >
                <Offcanvas.Header closeButton className="px-3">
                  <div className=" mt-2 d-flex align-content-center w-100 justify-content-between align-items-center">
                    <div className=" d-flex flex-column">
                      <h3 className="p-0">
                        Edit Products : {selectedList.length}
                      </h3>
                    </div>
                    <div className="pe-2" hidden={updateLoading}>
                      {delistLoading ? (
                        <Button
                          color="danger"
                          style={{ width: '112px', height: '38px' }}
                          disabled
                        >
                          <Spinner size="sm" color="light" type="grow">
                            Loading...
                          </Spinner>
                        </Button>
                      ) : (
                        <Button
                          color="danger"
                          size="sm"
                          style={{ width: '112px', height: '38px' }}
                          onClick={() => {
                            let deletingProducts = selectedList.map((obj) => {
                              return {
                                sellerPid: obj._id,
                                resellingProductId: obj.resellingData[0]._id,
                              };
                            });
                            handleDelistForResalling(deletingProducts[0]);
                          }}
                        >
                          Delist Product
                        </Button>
                      )}
                    </div>
                  </div>
                </Offcanvas.Header>
                {<hr className="mt-0 mb-2" />}

                <Offcanvas.Body>
                  <form
                    onSubmit={handleSubmit}
                    className="h-100 overflow-hidden d-flex flex-column"
                  >
                    <div
                      className="px-3 customScroll"
                      style={{
                        minHeight: '85%',
                        maxHeight: '85%',
                        overflow: 'auto',
                      }}
                    >
                      <div>
                        <FormGroup>
                          <Label for="ResalePrice">Price</Label>
                          <Input
                            required
                            value={editResaleData.resellingPrice}
                            onKeyDown={blockInvalidChar}
                            onChange={handleResaleData}
                            id="ResalePrice"
                            name="resellingPrice"
                            placeholder="0.00"
                            type="number"
                            min={'0'}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="SKU">SKU</Label>
                          <Input
                            value={editResaleData.resellingSKU}
                            disabled
                            id="SKU"
                            name="resellingSKU"
                            type="text"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="PublishStatus">Publish Status</Label>
                          <Select
                            name="publishStatus"
                            hideSelectedOptions
                            onChange={(e) => {
                              setEditResaleData({
                                ...editResaleData,
                                publishStatus: e.value,
                              });
                            }}
                            isMulti={false}
                            value={[
                              {
                                value: editResaleData.publishStatus,
                                label: toTitleCase(
                                  editResaleData.publishStatus
                                ),
                              },
                            ]}
                            options={[
                              { value: 'active', label: 'Active' },
                              { value: 'inActive', label: 'inActive' },
                            ]}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div hidden={delistLoading}>
                      <hr className="mt-1" />
                      <div className="d-flex justify-content-center">
                        <Button
                          disabled={updateLoading}
                          className=" mr-2"
                          color="secondary"
                          onClick={() => {
                            handleCloseSideBar();
                          }}
                        >
                          Cancel
                        </Button>
                        {updateLoading ? (
                          <Button
                            type="submit"
                            color="primary"
                            style={{ width: '80px', height: '36px' }}
                            disabled
                          >
                            <Spinner size="sm" color="light" type="grow">
                              Loading...
                            </Spinner>
                          </Button>
                        ) : (
                          <Button type="submit" color="primary">
                            Update
                          </Button>
                        )}
                      </div>
                    </div>
                  </form>
                </Offcanvas.Body>
              </Offcanvas>
            }
          </CardBody>
        </Card>
      )}
    </main>
  );
};
