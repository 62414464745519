import React, { useState, useEffect, useCallback, useRef } from "react";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import CreatableSelect from "react-select/creatable";
import DataTable from "./ResellingOrderTable";

import SpinnerButton from "../../common/SpinnerButton";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Button,
  Alert,
} from "reactstrap";
import {
  updateOrdersCouriers,
  EditOrderCityName,
  getOrderByDispatchStatus,
  addTrackingNumbers,
  updateOrderDispatchStatus,
} from "../../../api/orders";
import { getAllCitiesNames } from "../../../api/cities";
import { ExportToCsv } from "export-to-csv";
import { toPostExFormat } from "../../../helpers/courierFileFormats/PostEx";
import { toLeopardFormat } from "../../../helpers/courierFileFormats/Leopard";
import { toSwyftFormat } from "../../../helpers/courierFileFormats/Swyft";
import { toMPFormat } from "../../../helpers/courierFileFormats/M&P";
import { toTPLFormat } from "../../../helpers/courierFileFormats/TPL";
import { getUserPreferences } from "../../../api/courier";
import { sortByDate, DateFormatted } from "../../../helpers/dateTime";
import Select from "react-select";
import { ConsigneeDetails, OrdersDetailsPopup } from "../../Modals/Popups";
import { formatPhoneNumber } from "../../../helpers/phoneNumber";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  saveVariants,
  updateShippingCityFromOrders,
} from "../../../api/cityTypos";
import {
  getCourierOrdersObject,
  removeInvalidShipmentTypeOrders,
  validateCourierShipmentTypes,
} from "./helpers/ConfirmedOrderHelpers";
import DateFilters from "../../Date/DateFilters.jsx";
import { showItemDetails } from "../../orders/orderTabs/helperFunctions/tableDetailsInfo";
const ResellingConfirmedOrders = ({
  setOrderExported,
  setOrderConfirmed,
  orderConfirmed,
  modalOpen,
}) => {
  const [search, setSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [selectedOrdersInBulk, setSelectedOrdersInBulk] = useState([]);
  const [isAllOrdersChecked, setIsAllOrdersChecked] = useState(false);
  const [unfulfilledOrders, setUnfulfilledOrders] = useState([]);
  const [unFulfilledOrdersLoaded, setUnFulfilledOrdersLoaded] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});
  const [couriers, setCouriers] = useState([]);
  const [citiesCourier, setCitiesCourier] = useState([]);
  const [dataDefaultCourier, setDataDefaultCourier] = useState();
  const [itemDetailsData, setItemDetailsData] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const courierDDValueRef = useRef(null);
  const shipmentOptionsDDRef = useRef(null);
  const [cityOrderId, setCityOrderId] = useState();
  const [editCityName, setEditCityName] = useState({});
  const [newCity, setNewCity] = useState();
  const [disabledCity, setDisabledCity] = useState(false);
  const [courierAfterCityUpdate, setCourierAfterCityUpdate] = useState({});
  const [orderDetailsData, setOrderDetailsData] = useState();
  const [dateTime, setDateTime] = useState();
  const [areSelectedOrdersProcessing] = useState(false);
  const [selectedDispatchStatus, setSelectedDispatchStatus] = useState(null);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [checkCounter, setCheckCounter] = useState(0);
  const [cities, setCities] = useState([]);
  const [consigneeDetails, setConsigneeDetails] = useState();
  const [createOption, setCreateOption] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchNameBool, setSearchNameBool] = useState(false);
  const [searchCityBool, setSearchCityBool] = useState(false);

  const dateFilterOptions = [
    { value: 0, label: "Today" },
    { value: 1, label: "Yesterday" },
    { value: 7, label: "Last 7 days" },
    { value: 30, label: "Last 30 days" },
  ];
  let statusDispatchOptions = [
    { value: "AssignOrders", label: "Assign Orders" },
    { value: "ExportOrders", label: "Export Orders" },
    { value: "ManualOrders", label: "Manual Orders" },
    { value: "CancelOrder", label: "Cancel Orders" },
  ];
  const couriersShipmentTypes = [
    { value: "2", label: "next day", courier: "tpl", field: "DeliveryTypeId" },
    { value: "1", label: "delay", courier: "tpl", field: "DeliveryTypeId" },
    {
      value: "OVERNIGHT",
      label: "next day",
      courier: "leopard",
      field: "shipment_type",
    },
    {
      value: "OVERLAND",
      label: "delay",
      courier: "leopard",
      field: "shipment_type",
    },
    { value: "Flyer", label: "next day", courier: "swyft", field: "PACKAGING" },
  ];

  const shipmentTypeData = [
    { value: "next day", label: "Next Day" },
    { value: "delay", label: "Delay" },
  ];

  // const loadCities = useCallback(async () => {
  //   try {
  //     const { data: citiesData } = await getAllCitiesNames();
  //     setCities(citiesData.cities);
  //   } catch (error) {
  //     console.log("error found when fetch order data", error);
  //   }
  // }, []);

  // const loadOrders = useCallback(
  //   async ({
  //     page = pageNumber,
  //     limit = pageLimit,
  //     startDateIs = startDate,
  //     endDateIs = endDate,
  //   }) => {
  //     try {
  //       setLoading(true);
  //       const { data } = await getOrderByDispatchStatus({
  //         dispatchStatus: "confirm",
  //         isAssigned: false,
  //         page,
  //         limit,
  //         startDateIs,
  //         endDateIs,
  //       });
  //       data && setLoading(false);
  //       setTotalPagesCount(Math.ceil(data.count / limit));
  //       setUnfulfilledOrders(data.orders);
  //       setUnFulfilledOrdersLoaded(true);
  //       console.log(data.orders);
  //       return data.orders;
  //     } catch (error) {
  //       console.log("error found when fetch order data", error);
  //     }
  //   },
  //   [pageNumber, pageLimit, endDate, shipmentOptionsDDRef]
  // );

  // const loadCouriers = useCallback(async () => {
  //   try {
  //     const { data } = await getUserPreferences();
  //     // setDefaultCourier(Object.keys(data.defaultCityCourier)[0]);
  //     setCouriers(data.couriers);
  //     setCitiesCourier(data.courierCities);
  //     setDataDefaultCourier(data.defaultCitiesCourier);
  //   } catch (error) {
  //     console.log("error found when fetch order data", error);
  //   }
  // }, []);

  useEffect(() => {
    // loadOrders({});
    // loadCities();
    // if (orderConfirmed) {
    //   setOrderConfirmed(false);
    // }
    // if (couriers.length <= 0) {
    //   loadCouriers();
    // }
    setUnfulfilledOrders([
      {
        _id: "628e09554c50fcd10046757d",
        courier: "",
        seller: "Umar",
        sellerOrder: "121358",
        courierAssigned: false,
        dispatchStatus: "confirm",
        deliveryStatus: "not_delivered",
        storeId: "62878032582e6500dc75ac17",
        storeName: "codingstorte.myshopify.com",
        orderId: "4787435471066",
        orderRefNumber: "#1013-CodingStorte",
        cancel_reason: null,
        cancelled_at: null,
        created_at: "2022-05-25T15:47:46+05:00",
        current_total_price: "114.84",
        financial_status: "paid",
        fulfillment_status: null,
        name: "#1013",
        note: null,
        order_number: 1013,
        processed_at: "2022-05-25T15:47:46+05:00",
        subtotal_price: "99.00",
        total_price: "114.84",
        total_price_set: {
          shop_money: {
            amount: "114.84",
          },
        },
        total_weight: 0,
        updated_at: "2022-05-25T15:47:47+05:00",
        line_items: [
          {
            id: 12233094725850,
            price: "70.00",
            quantity: 1,
            sku: "AD-03-black-OS",
            title: "ADIDAS | CLASSIC BACKPACK",
          },
          {
            id: 12233094758618,
            price: "29.00",
            quantity: 1,
            sku: "VN-01-burgandy-4",
            title: "VANS |AUTHENTIC | LO PRO | BURGANDY/WHITE",
          },
        ],
        shipmentTracking: [],
        shipperDetail: [
          {
            shopDetail: {
              name: "CodingStorte",
              email: "usamariaz2844@gmail.com",
              domain: "codingstorte.myshopify.com",
              address1: "Punjab Pakistan",
              city: "Faisalabad",
              phone: "",
              country_name: "Pakistan",
            },
          },
        ],
      },
      {
        _id: "628b7c9c4c50fcd100467422",
        courier: "",
        seller: "Zaghum",
        sellerOrder: "55358",
        courierAssigned: false,
        dispatchStatus: "confirm",
        deliveryStatus: "not_delivered",
        storeId: "62878032582e6500dc75ac17",
        storeName: "codingstorte.myshopify.com",
        orderId: "4785821417690",
        orderRefNumber: "#1008-CodingStorte",
        cancel_reason: null,
        cancelled_at: null,
        created_at: "2022-05-23T17:22:48+05:00",
        current_total_price: "114.84",
        financial_status: "paid",
        fulfillment_status: null,
        name: "#1008",
        note: null,
        order_number: 1008,
        processed_at: "2022-05-23T17:22:48+05:00",
        subtotal_price: "99.00",
        total_price: "114.84",
        total_price_set: {
          shop_money: {
            amount: "114.84",
          },
        },
        total_weight: 0,
        updated_at: "2022-05-23T17:22:49+05:00",
        line_items: [
          {
            id: 12229918916826,
            price: "70.00",
            quantity: 1,
            sku: "AD-03-black-OS",
            title: "ADIDAS | CLASSIC BACKPACK",
          },
          {
            id: 12229918949594,
            price: "29.00",
            quantity: 1,
            sku: "VN-01-burgandy-4",
            title: "VANS |AUTHENTIC | LO PRO | BURGANDY/WHITE",
          },
        ],
        shipmentTracking: [],
        shipperDetail: [
          {
            shopDetail: {
              name: "CodingStorte",
              email: "usamariaz2844@gmail.com",
              domain: "codingstorte.myshopify.com",
              address1: "Punjab Pakistan",
              city: "Faisalabad",
              phone: "",
              country_name: "Pakistan",
            },
          },
        ],
      },
    ]);
  }, []);

  // const getCurrentCityCourier = (destinationCity = " ") => {
  //   destinationCity = destinationCity.toLowerCase().trim();
  //   let selectedCourier = null;
  //   for (const courierOfCities in citiesCourier) {
  //     const cities = citiesCourier[courierOfCities];
  //     const cityMatched = cities.some(
  //       (city) => city.toLowerCase().trim() === destinationCity
  //     );
  //     if (cityMatched) {
  //       selectedCourier = courierOfCities;
  //     }
  //   }

  //   if (dataDefaultCourier && !selectedCourier) {
  //     selectedCourier = Object.keys(dataDefaultCourier)[0];
  //   }
  //   return selectedCourier;
  // };

  // const handleCourierChange = (e, orderData) => {
  //   e.preventDefault();
  //   const selectedCourier = e.target.value;

  //   const ordersWitCourierUpdated = selectedOrdersInBulk.map(
  //     (selectedOrder) => {
  //       if (selectedOrder.orderId === orderData.orderId) {
  //         return { ...selectedOrder, courier: selectedCourier };
  //       }
  //       return selectedOrder;
  //     }
  //   );
  //   setSelectedOrdersInBulk([...ordersWitCourierUpdated]);
  // };

  const filterByDateOrders = async (startDateIs, endDateIs) => {
    try {
      // const response = await loadOrders({ startDateIs, endDateIs });
      // if (response.length <= 0) {
      //   setShowResetButton(true);
      // }
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  };

  const handleShipmentTypeChange = (e, orderData) => {
    e.preventDefault();
    const selectedShipmentType = e.target.value;

    const ordersWitShipmentTypeUpdated = selectedOrdersInBulk.map(
      (selectedOrder) => {
        if (selectedOrder.orderId === orderData.orderId) {
          return { ...selectedOrder, shipmentType: selectedShipmentType };
        }
        return selectedOrder;
      }
    );
    setSelectedOrdersInBulk([...ordersWitShipmentTypeUpdated]);
  };

  const onCheckItem = (event, orderId, orderData = {}) => {
    // const { key: orderCourier } = orderData?.courier?.props?.children.find(
    //   (courier) => courier.props.selected
    // );

    const shipmentOption =
      shipmentOptionsDDRef?.current?.value === undefined
        ? "next day"
        : shipmentOptionsDDRef?.current?.value;
    console.log(shipmentOption);

    setCheckboxes({ ...checkboxes, [orderId]: event.target.checked });
    let checkedOrders = [];

    unfulfilledOrders.forEach((order) => {
      if (order.orderId === orderId) {
        order.courier
          ? checkedOrders.push(order)
          : checkedOrders.push({
              ...order,
              // courier: orderCourier,
              shipmentType: shipmentOption,
            });
      }
    });

    // add checked order to list of selected orders and remove on uncheck.
    if (event.target.checked) {
      setSelectedOrdersInBulk([...selectedOrdersInBulk, ...checkedOrders]);
    } else {
      if (selectedOrdersInBulk.length > 0) {
        const removeUnCheckedOrder = selectedOrdersInBulk.filter(
          (order) => order.orderId !== orderId
        );
        setSelectedOrdersInBulk(removeUnCheckedOrder);
      }
    }
    if (event.target.checked && orderId) {
      setCheckCounter(checkCounter + 1);
    } else if (!event.target.checked) {
      setCheckCounter(checkCounter - 1);
    }
  };

  const resetFilters = async () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedDateFilter(null);
    setUnFulfilledOrdersLoaded(true);
    // await loadOrders({});
  };

  const onCheckAllOrders = (e, ordersToMark) => {
    console.log(ordersToMark);
    setIsAllOrdersChecked(e.target.checked);
    const orderIdsToMark = {};
    const ordersCompleteData = [];
    const shipmentOption =
      shipmentOptionsDDRef?.current?.value === undefined
        ? "next day"
        : shipmentOptionsDDRef?.current?.value;
    ordersToMark.forEach((order) => {
      orderIdsToMark[order.id] = e.target.checked;
      // const { key: courier } = order.courier.props.children.find(
      //   (element) => element.props.selected
      // );

      const matchedOrderInAllOrders = unfulfilledOrders.find(
        (completeOrder) => {
          if (completeOrder.orderId === order.id) {
            // completeOrder.courier = courier;
            completeOrder.shipmentType = shipmentOption;
            return { ...completeOrder };
          }
          return false;
        }
      );
      ordersCompleteData.push({ ...matchedOrderInAllOrders });
    });

    console.log(selectedDispatchStatus);
    // add checked order to list of selected orders.
    if (e.target.checked) {
      setCheckboxes(orderIdsToMark);
      setSelectedOrdersInBulk(ordersCompleteData);
      setCheckCounter(ordersCompleteData.length);
    }
    // remove on uncheck
    else {
      if (selectedOrdersInBulk.length > 0) {
        setCheckboxes({});
        setSelectedOrdersInBulk([]);
        setCheckCounter(selectedOrdersInBulk.length);
      }
    }
    if (!e.target.checked) {
      console.log(e.target.checked);
      return setCheckCounter(0);
    }
  };

  const cancelEditCityMenu = () => {
    setCityOrderId();
    setCreateOption(false);
  };

  const changeButton = (orderId) => {
    setCityOrderId(orderId);
  };

  // const setValue = async (cityName) => {
  //   setDisabledCity(true);
  //   try {
  //     if (createOption === true) {
  //       await updateShippingCityFromOrders(newCity, cityOrderId);
  //       setCreateOption(false);
  //     } else {
  //       await saveVariants(newCity, cityName, cityOrderId);
  //     }
  //     // const updatedCity = data && data.shipping_address.city
  //     setCityOrderId();
  //     setDisabledCity(false);
  //     setEditCityName({ orderId: cityOrderId, cityName: newCity });
  //     // const selectedCourier = getCurrentCityCourier(newCity);
  //     // setCourierAfterCityUpdate({
  //     //   orderId: cityOrderId,
  //     //   courier: selectedCourier,
  //     // });
  //     const shipmentOption = shipmentOptionsDDRef?.current?.value;

  //     // update unfulfillled orders state to reflect change in to update courier in dropdowns
  //     const updatedCourierUnfulfilledInOrders = unfulfilledOrders.map(
  //       (order) => {
  //         if (order.orderId === cityOrderId) {
  //           return {
  //             ...order,
  //             courier: selectedCourier,
  //             shipmentType: order.shipmentType
  //               ? order.shipmentType
  //               : shipmentOption,
  //             shipping_address: { ...order.shipping_address, city: newCity },
  //           };
  //         }
  //         return order;
  //       }
  //     );

  //     setUnfulfilledOrders(updatedCourierUnfulfilledInOrders);

  //     // update checked orders state to update courier
  //     if (selectedOrdersInBulk.length > 0) {
  //       const updatedCourierInSelectedOrders = selectedOrdersInBulk.map(
  //         (selectedOrder) => {
  //           if (selectedOrder.orderId === cityOrderId) {
  //             return {
  //               ...selectedOrder,
  //               courier: selectedCourier,
  //               shipmentType: selectedOrder.shipmentType
  //                 ? selectedOrder.shipmentType
  //                 : shipmentOption,
  //               shipping_address: {
  //                 ...selectedOrder.shipping_address,
  //                 city: newCity,
  //               },
  //             };
  //           }
  //           return selectedOrder;
  //         }
  //       );
  //       setSelectedOrdersInBulk(updatedCourierInSelectedOrders);
  //     }
  //   } catch (error) {
  //     setCityOrderId();
  //     setDisabledCity(false);
  //   }
  // };

  // const submitOnEnter = (e, cityName) => {
  //   if (e.key === "Enter") {
  //     setValue(cityName);
  //   }
  // };

  const findCity = async (checkNewCity) => {
    setNewCity(checkNewCity);
    let checkCityInDB = await cities.find(
      (checkCity) => checkCity.label === checkNewCity
    );
    if (!checkCityInDB) {
      setCreateOption(true);
    } else {
      setCreateOption(false);
    }
  };

  const consigneeDetail = (item) => {
    let orderItems = item.slice(0, 12);
    let itemsLength = item.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 12 ? "...." : ""}`}
          {itemsLength > 12 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="consigneeDetailPopover"
              onMouseEnter={() => {
                setConsigneeDetails([item]);
              }}
            />
          ) : (
            ""
          )}

          <ConsigneeDetails
            consigneeDetails={consigneeDetails && consigneeDetails}
          />
        </div>
      </>
    );
  };

  const orderDetails = (items) => {
    let orderItems = items[0].title.slice(0, 12);
    let itemsLength = items[0].title.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 12 ? "...." : ""}`}
          {itemsLength > 12 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="orderDetailPopover"
              onMouseEnter={() => {
                setOrderDetailsData(items);
              }}
            />
          ) : (
            ""
          )}
          <OrdersDetailsPopup
            orderDetailsData={orderDetailsData && orderDetailsData}
          />
        </div>
      </>
    );
  };

  const ordersData =
    unfulfilledOrders &&
    unfulfilledOrders.length > 0 &&
    unfulfilledOrders
      .filter((order) => {
        if (search === "") {
          return order;
        } else if (order.hasOwnProperty("shipping_address")) {
          return order.shipping_address.name
            ? order.shipping_address.name
                .toLowerCase()
                .includes(search.toLowerCase())
            : "";
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.address1
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return order;
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.phone
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return order;
        } else if (order.name.toLowerCase().includes(search.toLowerCase())) {
          return order;
        }
        return false;
      })
      .map((order) => {
        return {
          id: order.orderId,
          name:
            order.shipping_address && order.shipping_address.name
              ? order.shipping_address.name
              : "N/A",
          orderDate: DateFormatted(order.created_at, setDateTime, dateTime),
          address: order?.shipping_address?.address1
            ? consigneeDetail(order?.shipping_address?.address1)
            : "N/A",
          phone: order?.shipping_address?.phone
            ? formatPhoneNumber(order?.shipping_address?.phone)
            : "N/A",
          weight: order.total_weight,
          amount: Math.trunc(
            order.total_price_set.shop_money.amount
          ).toLocaleString(),
          referenceNo: order.orderId,
          details: orderDetails(order.line_items),
          remarks: order.note ? order.note : "none",
          orderId: order.name,
          city: order?.shipping_address ? order.shipping_address?.city : "N/A",
          storeName: order.storeName.split(".")[0],
          seller: order.seller,
          sellerOrder: order.sellerOrder,
          updated_at: order.updatedAt,
          items:
            order.line_items && order.line_items.length > 0
              ? showItemDetails(
                  order.line_items,
                  setItemDetailsData,
                  itemDetailsData
                )
              : "N/A",
          created_at: order.createdAt,
          courier: (
            <button
              key={order.orderId}
              // onChange={(e) => handleCourierChange(e, order)}
              ref={courierDDValueRef}
              className={"form-control"}
              disabled={checkboxes[order.orderId] === true ? false : true}
            >
              Postex
              {/* {couriers.length > 0 &&
                couriers.map((courier) => {
                  const selectedCourier =
                    Object.keys(courierAfterCityUpdate).length > 0 &&
                    courierAfterCityUpdate.orderId === order.orderId
                      ? courierAfterCityUpdate.courier
                      : "Postex";
                  return (
                    <option
                      selected={true}
                      // disabled={showTopCourierDD}
                      key={courier.label}
                      value={"Postex"}
                      // selected={
                      //   courier.label === selectedCourier ? true : false
                      // }
                    >
                      {/* {courier.label === selectedCourier
                        ? selectedCourier
                        : courier.label} */}
              {/* 'Postex'
                    </option> */}
              {/* ); */}
              {/* })}  */}
            </button>
          ),
          shipmentOptions: (
            <form>
              <select
                onChange={(e) => handleShipmentTypeChange(e, order)}
                className="form-control"
                placeholder="Shipping Option"
                disabled={checkboxes[order.orderId] === true ? false : true}
                ref={shipmentOptionsDDRef}
              >
                {shipmentTypeData.map((shipmentType, index) => (
                  <option
                    key={index * order.orderId}
                    value={shipmentType.value}
                  >
                    {shipmentType.label}
                  </option>
                ))}
              </select>
            </form>
          ),
        };
      });

  const cityOrderData =
    unfulfilledOrders &&
    unfulfilledOrders.length > 0 &&
    unfulfilledOrders
      .filter((order) => {
        if (citySearch === "") {
          return order;
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.city &&
          order.shipping_address.city
            .toLowerCase()
            .includes(citySearch.toLowerCase())
        ) {
          return order;
        }
        return false;
      })
      .map((order) => ({
        id: order.orderId,
        name:
          order.shipping_address && order.shipping_address.name
            ? order.shipping_address.name
            : "N/A",
        orderDate: DateFormatted(order.created_at, setDateTime, dateTime),
        address: order?.shipping_address?.address1
          ? consigneeDetail(order?.shipping_address?.address1)
          : "N/A",
        phone: order?.shipping_address?.phone
          ? formatPhoneNumber(order?.shipping_address?.phone, true)
          : "N/A",
        weight: order.total_weight,
        amount: Math.trunc(
          order.total_price_set.shop_money.amount
        ).toLocaleString(),
        referenceNo: order.orderId,
        details: orderDetails(order.line_items),
        remarks: order.note ? order.note : "none",
        orderId: order.name,
        city: order?.shipping_address ? order?.shipping_address?.city : "N/A",
        storeName: order.storeName.split(".")[0],
        seller: order.seller,
        sellerOrder: order.sellerOrder,
        updated_at: order.updatedAt,
        created_at: order.createdAt,
        courier: (
          <select
            key={Math.random() * order.id}
            // onChange={(e) => handleCourierChange(e, order)}
            ref={courierDDValueRef}
            // className={`${showTopCourierDD ? "form-control disabled" : "form-control"}`}
            className={"form-control"}
            disabled={checkboxes[order.orderId] === true ? false : true}
          >
            {couriers.length > 0 &&
              couriers.map((courier) => {
                const selectedCourier =
                  Object.keys(courierAfterCityUpdate).length > 0 &&
                  courierAfterCityUpdate.orderId === order.orderId
                    ? courierAfterCityUpdate.courier
                    : "Postex";

                return (
                  <option
                    // disabled={showTopCourierDD}
                    key={courier.label}
                    value={
                      courier.label === selectedCourier
                        ? selectedCourier
                        : courier.label
                    }
                    selected={courier.label === selectedCourier ? true : false}
                  >
                    {courier.label === selectedCourier
                      ? selectedCourier
                      : courier.label}
                  </option>
                );
              })}
          </select>
        ),
        shipmentOptions: (
          <form>
            <Select
              onChange={(e) => handleShipmentTypeChange(e, order)}
              className="form-control"
              disabled={checkboxes[order.orderId] === true ? false : true}
              ref={shipmentOptionsDDRef}
            >
              {shipmentTypeData.map((shipmentType, index) => (
                <option key={index * order.orderId} value={shipmentType.value}>
                  {shipmentType.label}
                </option>
              ))}
            </Select>
          </form>
        ),
      }));

  const cols = React.useMemo(
    () => [
      {
        Header: "Order#",
        accessor: "orderId",
        cellClass: "table-text w-3 text-center",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: "table-text w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: "table-text w-10",
        Cell: (props) => <>{props.value}</>,
      },
      // {
      //   Header: 'Address',
      //   accessor: 'address',
      //   cellClass: 'table-text w-6',
      //   Cell: (props) => <>{props.value}</>,
      // },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: "table-text w-10",
        Cell: (props) => <>{props.value}</>,
      },
      // {
      //   Header: 'Weight',
      //   accessor: 'weight',
      //   cellClass: 'table-text w-2',
      //   Cell: (props) => <>{props.value}</>,
      // },
      {
        Header: "COD",
        accessor: "amount",
        cellClass: "table-text w-10",
        Cell: (props) => <>{props.value}</>,
      },
      // {
      //   Header: 'Product Details',
      //   accessor: 'details',
      //   cellClass: 'table-text w-12',
      //   Cell: (props) => <>{props.value}</>,
      // },
      {
        Header: "Items(Count Only)",
        accessor: "items",
        cellClass: "table-text w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: "table-text w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Seller",
        accessor: "seller",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "SellerOrder#",
        accessor: "sellerOrder",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Courier",
        accessor: "courier",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Shipping",
        accessor: "shipmentOptions",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      // {
      //   Header: 'Store',
      //   accessor: 'storeName',
      //   cellClass: 'table-text w-6',
      //   Cell: (props) => <>{props.value}</>,
      // },
    ],
    []
  );
  const moreActions = (e) => {
    setSelectedDispatchStatus(e.value);
    console.log(e.value);
  };
  return (
    <>
      {unFulfilledOrdersLoaded && unfulfilledOrders.length <= 0 ? (
        <Card className="my-3 py-5">
          <CardBody
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h4 style={{ color: "red" }}>
              <b>No Order Confirmed!</b>
            </h4>
            {showResetButton === false ? (
              ""
            ) : (
              <Button
                color="primary"
                className="mt-1 btn-sm"
                onClick={resetFilters}
              >
                Reset Data
              </Button>
            )}
          </CardBody>
        </Card>
      ) : !unFulfilledOrdersLoaded && unfulfilledOrders.length === 0 ? (
        <>
          <div className="loading" />
          <div
            style={{
              height: "70vh",
            }}
          ></div>
        </>
      ) : (
        <Row>
          <Cols xxs="12">
            <Card className="mb-4">
              <CardBody className={{ padding: "0px" }}>
                <CardTitle className="order_table_card_title">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className="container"
                  >
                    <div className="search-sm mb-2 global-search-font">
                      <input
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          if (e) {
                            setSearch(e.target.value);
                            setSearchNameBool(true);
                          } else {
                            setSearchNameBool(false);
                          }
                        }}
                      />
                    </div>
                    <div>
                      <DateFilters
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        selectedDateFilter={selectedDateFilter}
                        setSelectedDateFilter={setSelectedDateFilter}
                        setShowResetButton={setShowResetButton}
                        loadOrders={() => {}}
                        resetFilters={resetFilters}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="city_search_container  mb-2 ml-3"
                    >
                      <div className="search-sm d-inline-block confirm-city-search">
                        <input
                          type="text"
                          name="keyword"
                          id="city_search"
                          placeholder="Search City"
                          value={citySearch}
                          onChange={(e) => {
                            if (e) {
                              setSearchCityBool(true);
                              setCitySearch(e.target.value);
                            } else {
                              setSearchCityBool(false);
                            }
                          }}
                        />
                      </div>
                      {/* <button
                        onClick={handleSelectedOrders}
                        type="button"
                        className="btn btn-primary custom-btn "
                        style={{ marginLeft: '10px' }}
                      >
                        Assign Orders
                        {areSelectedOrdersProcessing ? (
                          <Spinner
                            style={{ width: '1rem', height: '1rem' }}
                            type="grow"
                            color="light"
                          />
                        ) : null}
                      </button> */}
                      <div
                        className="openOrderSelect ml-2"
                        style={{ width: "140px" }}
                      >
                        <Select
                          className="mb-2"
                          placeholder="Actions"
                          defaultValue={selectedDispatchStatus}
                          options={statusDispatchOptions}
                          isDisabled={
                            selectedOrdersInBulk.length >= 1 ? false : true
                          }
                          onChange={(e) => moreActions(e)}
                        />
                      </div>
                      <button
                        // onClick={() =>
                        //   selectedDispatchStatus === 'AssignOrders'
                        //     ? handleSelectedOrders
                        //     : handleExportOrdersBtn
                        // }
                        type="button"
                        disabled={selectedOrdersInBulk.length === 0}
                        className="btn btn-primary custom-btn "
                        style={{
                          marginLeft: "10px",
                          height: "36px",
                          marginTop: "1px",
                        }}
                      >
                        {selectedDispatchStatus === "ExportOrders"
                          ? "Export Order"
                          : "Submit Order"}
                        {areSelectedOrdersProcessing ? (
                          <Spinner
                            style={{ width: "1rem", height: "1rem" }}
                            type="grow"
                            color="light"
                          />
                        ) : null}
                      </button>
                      {/* <div
                        style={{
                          marginLeft: '10px',
                          height: '36px',
                          marginTop: '1px',
                        }}
                      >
                        <SpinnerButton
                          btnText={
                            selectedDispatchStatus === 'ExportOrders'
                              ? 'Export Order'
                              : 'Submit Orders'
                          }
                          isProcessing={areSelectedOrdersProcessing}
                        />
                      </div> */}
                    </div>
                  </div>
                </CardTitle>
                {loading && (
                  <div className="mx-2" style={{ marginBottom: "-5px" }}>
                    <Alert color="success">
                      <Spinner
                        color="success"
                        size={"sm"}
                        style={{ marginBottom: "2px" }}
                      ></Spinner>{" "}
                      &nbsp;
                      <span style={{ fontSize: "15px" }}>Orders Loading!</span>
                    </Alert>
                  </div>
                )}
                {((cityOrderData.length === 0 && searchCityBool) ||
                  (ordersData.length === 0 && searchNameBool)) && (
                  <h4
                    style={{
                      color: "red",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <b>No Order Matched!</b>
                  </h4>
                )}

                {cityOrderData.length !== 0 && ordersData.length !== 0 && (
                  <DataTable
                    setPageLimitInParent={setPageLimit}
                    setPageNumberInParent={setPageNumber}
                    // fetchData={loadOrders}
                    pageCount={totalPagesCount}
                    columns={cols}
                    data={
                      citySearch !== ""
                        ? cityOrderData.sort(sortByDate)
                        : ordersData.sort(sortByDate)
                    }
                    onCheckItem={onCheckItem}
                    checkedOrders={checkboxes}
                    isAllOrdersChecked={isAllOrdersChecked}
                    onCheckAllOrders={onCheckAllOrders}
                    checkCounter={checkCounter}
                  />
                )}
              </CardBody>
            </Card>
          </Cols>
        </Row>
      )}
    </>
  );
};

export default ResellingConfirmedOrders;
