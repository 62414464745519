import axios from 'axios';
import { getCookie } from '../helpers/cookie';
import { getAPIURL } from './utils';
const url = getAPIURL();
const token = getCookie();
let response = '';

export const getProductsForReselling = async ({
  limit,
  page,
  title,
  vendor,
}) => {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('page', page.toString());
  params.append('vendor', vendor.toString());
  params.append('title', title.toString().trim());

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  try {
    response = await axios.get(
      `${url}/api/sellingProducts/getProductsForReselling`,
      config
    );
  } catch (err) {
    response = err.response.data;
  }

  return response;
};

export const listSellingProduct = async (data) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    response = await axios.post(
      `${url}/api/sellingProducts/listSellingProduct`,
      data,
      config
    );
  } catch (err) {
    response = err?.response?.data ? err.response.data : err.response;
  }
  return response;
};

export const getListedProducts = async ({ limit, page, title, vendor }) => {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('page', page.toString());
  params.append('title', title.toString().trim());
  params.append('vendor', vendor.toString());

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  try {
    response = await axios.get(
      `${url}/api/sellingProducts/getListedSellingProducts`,
      config
    );
  } catch (err) {
    response = err.response.data;
  }
  return response;
};

export const delistSellingProducts = async (data) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    response = await axios.post(
      `${url}/api/sellingProducts/delistSellingProducts`,
      data,
      config
    );
  } catch (err) {
    response = err.response.data;
  }
  return response;
};

export const updateProductsFromListed = async (data) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    response = await axios.put(
      `${url}/api/sellingProducts/updateListedSellingProducts`,
      data,
      config
    );
  } catch (err) {
    response = err?.response?.data ? err.response.data : err.response;
  }
  return response;
};

export const getAllAccountsInfo = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    response = await axios.get(`${url}/api/sellers/getAllSellersData`, config);
  } catch (err) {
    response = err.response.data;
  }
  return response;
};
// export const getResellProducts = async ({ limit, page }) => {
//   const params = new URLSearchParams();
//   params.append('limit', limit.toString());
//   params.append('page', page.toString());
//   const config = {
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${token}`,
//     },
//     params,
//   };

//   try {
//     response = await axios.get(`${url}/api/product/getResellProducts`, config);
//   } catch (err) {
//     response = err.response.data;
//   }
//   return response;
// };
