import React, { useState } from "react";
import { TabContent, TabPane } from "reactstrap";
import OrderHeader from "../../orders/OrderHeader";
import ResellingOrderTabs from "./ResaleOrderTabs";
import ResaleBookedOrders from "./ResaleBookedOrders";
import ResaleCancelledOrders from "./ResaleCancelledOrders";
import ResaleFulfilledOrders from "./ResaleFulfilledOrders";
import ResaleManualOrders from "./ResaleManualOrders";

const ResaleAssignmentOrdersList = () => {
  const activeTabFromStorage = localStorage.getItem("activeExportOrderTab");

  const [activeTab, setActiveTab] = useState(
    activeTabFromStorage ? activeTabFromStorage : "assignedOrders"
  );
  // const [orderExported, setOrderExported] = useState(false);
  // const [orderConfirmed, setOrderConfirmed] = useState(false);
  // const [orderPending, setOrderPending] = useState(false);
  const [orderCancelled, setOrderCancelled] = useState(false);
  const [orderFulfilled, setOrderFulfilled] = useState(false);
  const [orderManual, setOrderManual] = useState(false);

  return (
    <>
      <main>
        <OrderHeader OrdersComponent="Resale" />
        <div
          className="table_tabs_header px-2"
          style={{
            width: "100%",
            justifyContent: "space-between",
            // display: 'flex',
          }}
        >
          <ResellingOrderTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="cancelledOrders">
            {activeTab === "cancelledOrders" ? (
              <ResaleCancelledOrders
                setOrderCancelled={setOrderCancelled}
                orderCancelled={orderCancelled}
              />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="assignedOrders">
            {activeTab === "assignedOrders" ? (
              <ResaleBookedOrders setOrderFulfilled={setOrderFulfilled} />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="fulfilledOrders">
            {activeTab === "fulfilledOrders" ? (
              <ResaleFulfilledOrders
                setOrderFulfilled={setOrderFulfilled}
                orderFulfilled={orderFulfilled}
              />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="manualOrders">
            {activeTab === "manualOrders" ? (
              <ResaleManualOrders
                setOrderManual={setOrderManual}
                orderManual={orderManual}
              />
            ) : (
              ""
            )}
          </TabPane>
        </TabContent>
      </main>
    </>
  );
};

export default ResaleAssignmentOrdersList;
