import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";

const url = getAPIURL();

export const signup = (userData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  return axios.post(`${url}/api/auth/signup`, userData, config);
};

export const signIn = (userData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  return axios.post(`${url}/api/auth/signin`, userData, config);
};

export const getUserData = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${url}/api/auth`, config);

  return response;
};

export const getUserAccount = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${url}/api/auth/readAccount`, config);

  return response;
};

export const resetPassword = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await axios.post(
    `${url}/api/auth/requestForResetPassword`,
    data,
    config
  );
};

export const NewPass = async (password) => {
  const email = localStorage.getItem("resetEmail");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      password,
      email,
    }),
  };
  return await axios.post(
    `${url}/api/auth/newPassword`,
    { password, email },
    config
  );
};

export const update = async (userId, uData) => {
  let username = uData.get("username");
  let email = uData.get("email");
  let password = uData.get("password");
  const updateData = {
    email,
    username,
    password,
  };
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  await axios.put(`${url}/api/auth/${userId}`, updateData, config);
};

export const updateMyEmail = async (email, password) => {
  const data = { email, password };
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  await axios.put(`${url}/api/auth/updateEmail`, data, config);
};

export const updateMyProfile = async (
  firstname,
  lastname,
  phone,
  image,
  userName
) => {
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  await axios.put(
    `${url}/api/auth/updateProfile`,
    { firstname, lastname, phone, image, userName },
    config
  );
};

export const accountDeletion = async () => {
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  await axios.delete(`${url}/api/auth/deleteAccount`, config);
};

export const accountDeletionById = async (accountId) => {
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await axios.delete(
    `${url}/api/auth/deleteAccountById/${accountId}`,
    config
  );
};

export const updateMyPassword = async (oldPassword, newPassword) => {
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.put(
    `${url}/api/auth/updatePassword`,
    { oldPassword, newPassword },
    config
  );
};

export const checkPassword = async (password) => {
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.post(`${url}/api/auth/checkPassword`, { password }, config);
};

export const updateUserRole = async () => {
  let response, error;
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    response = await axios.post(`${url}/api/auth/updateRole`, {}, config);
  } catch (e) {
    error = e;
  }

  return new Promise((resolve, reject) => {
    if (error) {
      reject(error);
    } else {
      resolve(response);
    }
  });
};

export const shopifyCredentials = (storeData) => {
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.put(`${url}/api/auth/credential`, storeData, config);
};

// --account verification---------

export const verifyEmail = (credentials) => {
  return axios.post(`${url}/api/auth/verifyEmail`, credentials, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
