import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Row, TabContent, TabPane } from "reactstrap";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import Orders from "./Orders";
import ResellingOrderTabs from "./ResellingOrderTabs";
import ResellingTransaction from "./ResellingTransaction";

const ResellingPayments = () => {
  const activeTabFromStorage = localStorage.getItem(
    "activeOrdeResellingPaymentTab"
  );

  const [activeTab, setActiveTab] = useState(
    activeTabFromStorage ? activeTabFromStorage : "Orders"
  );

  return (
    <div>
      <main>
        <Row className="top-text ms-sm-1">
          <Cols xxs="12">
            <h1>Reselling Payments</h1>
            <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" style={{ color: "black" }}>
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Reselling Payments
                </li>
              </ol>
            </Breadcrumb>
          </Cols>
        </Row>
        <ResellingOrderTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        <TabContent activeTab={activeTab}>
          <TabPane tabId="Orders">
            {activeTab === "Orders" ? (
              <Orders activeTab={activeTab} setActiveTab={setActiveTab} />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="Transactions">
            {activeTab === "Transactions" ? <ResellingTransaction /> : ""}
          </TabPane>
        </TabContent>
      </main>
    </div>
  );
};

export default ResellingPayments;
