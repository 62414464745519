import React, { useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import { Cols } from '../SeparatorStyle/SeparatorStyle';
import Select from 'react-select';

const SellerDashboard = () => {
  const daysFilterData = [
    { label: 'Last 90 Days', value: 90, key: 1 },
    { label: 'Last 30 Days', value: 30, key: 2 },
    { label: 'Last 15 Days', value: 15, key: 3 },
    { label: 'Last Week', value: 7, key: 4 },
    { label: 'Today', value: 0, key: 5 },
  ];

  const [selectedOption, setSelectedOption] = useState({
    label: 'Last 30 Days',
    value: 30,
    key: 2,
  });
  return (
    <div>
      <main>
        <Row className=" mb-5 marginL-md dashboardHead">
          <div className="px-4 d-flex justify-content-between mb-4 flex-wrap dashboardName">
            <div className="me-3">
              <h1>Seller Dashboard</h1>
            </div>
            <div className=" float-end dashboardFilter-xs">
              <Select
                hideSelectedOptions
                // isDisabled={loading}
                className="react-select"
                classNamePrefix="react-select"
                name="form-field-name"
                value={selectedOption}
                // onChange={(e) => daysFilter(e)}
                options={daysFilterData}
              />
            </div>
          </div>
        </Row>
        <Row className="">
          <Cols xxs="12" className="row icon-cards-row mb-2">
            <Cols xxs="12" sm="6" md="4" lg="3" className="mb-4">
              <Card>
                <CardBody className="text-center">
                  <h1 className=" card-text  iconsminds-mail-read " />

                  <h3 className="card-text font-weight-semibold">
                    Total Orders
                  </h3>

                  <p className="lead text-center">
                    <b>8</b>
                  </p>
                </CardBody>
              </Card>
            </Cols>
            <Cols xxs="12" sm="6" md="4" lg="3" className="mb-4">
              <Card>
                <CardBody className="text-center">
                  <h1 className="card-text iconsminds-basket-coins" />
                  <h3 className="card-text font-weight-semibold">
                    Total Sales
                  </h3>

                  <p className="lead text-center">
                    <b>Rs 78</b>
                  </p>
                </CardBody>
              </Card>
            </Cols>
            <Cols xxs="12" sm="6" md="4" lg="3" className="mb-4">
              <Card>
                <CardBody className="text-center">
                  <h1 className="card-text iconsminds-clock" />
                  <h3 className="card-text font-weight-semibold">
                    Unfulfilled Orders Count
                  </h3>

                  <p className="lead text-center">
                    <b>89</b>
                  </p>
                </CardBody>
              </Card>
            </Cols>
            <Cols xxs="12" sm="6" md="4" lg="3" className="mb-4">
              <Card>
                <CardBody className="text-center">
                  <h1 className=" card-text iconsminds-arrow-refresh" />
                  <h3 className="card-text font-weight-semibold">
                    Unfulfilled Orders Amount
                  </h3>

                  <p className="lead text-center">
                    <b>Rs 87</b>
                  </p>
                </CardBody>
              </Card>
            </Cols>
          </Cols>
        </Row>
      </main>
    </div>
  );
};

export default SellerDashboard;
