import React, { useCallback, useEffect, useState } from "react";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import DataTable from "../../tables/table";
import { getOrderByDispatchStatus } from "../../../api/orders";
import { ConsigneeDetails, OrdersDetailsPopup } from "../../Modals/Popups";
import { formatPhoneNumber } from "../../../helpers/phoneNumber";
// import {DateFormatted} from '../../../helpers/dateTime';
import { sortByDate, DateFormatted } from "../../../helpers/dateTime";
import DateFilters from "../../Date/DateFilters.jsx";
import { showItemDetails } from "../../orders/orderTabs/helperFunctions/tableDetailsInfo";
const ResaleManualOrders = ({ setOrderManual, orderManual }) => {
  const [search, setSearch] = useState("");
  const [orderDetailsData, setOrderDetailsData] = useState();
  const [manualOrders, setManualOrders] = useState([]);
  const [dateTime, setDateTime] = useState();
  const [citySearch, setCitySearch] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [manualOrdersLoaded, setManualOrdersLoaded] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const [consigneeDetails, setConsigneeDetails] = useState();
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchNameBool, setSearchNameBool] = useState(false);
  const [searchCityBool, setSearchCityBool] = useState(false);
  const [itemDetailsData, setItemDetailsData] = useState();

  // const loadManualOrders = useCallback(
  //   async ({
  //     page = pageNumber,
  //     limit = pageLimit,
  //     startDateIs = startDate,
  //     endDateIs = endDate,
  //   }) => {
  //     try {
  //       setLoading(true);
  //       const { data } = await getOrderByDispatchStatus({
  //         dispatchStatus: "manual",
  //         isAssigned: false,
  //         page,
  //         limit,
  //         startDateIs,
  //         endDateIs,
  //       });
  //       data && setLoading(false);
  //       setTotalPagesCount(Math.ceil(data.count / limit));
  //       setManualOrders(data.orders);
  //       setManualOrdersLoaded(true);
  //       return data.orders;
  //     } catch (error) {
  //       console.log("error found when fetch order data", error);
  //     }
  //   },
  //   [setManualOrders, pageNumber, pageLimit, endDate]
  // );

  useEffect(() => {
    // loadManualOrders({});
    // if (orderManual) {
    //   setOrderManual(false);
    // }
    setManualOrders([
      {
        _id: "628e09554c50fcd10046757d",
        courier: "",
        courierAssigned: false,
        resellerName: "360Brain",
        dispatchStatus: "confirm",
        deliveryStatus: "not_delivered",
        storeId: "62878032582e6500dc75ac17",
        storeName: "codingstorte.myshopify.com",
        orderId: "4787435471066",
        orderRefNumber: "#1013-CodingStorte",
        cancel_reason: null,
        cancelled_at: null,
        created_at: "2022-05-25T15:47:46+05:00",
        current_total_price: "114.84",
        financial_status: "paid",
        fulfillment_status: null,
        name: "#1013",
        note: null,
        order_number: 1013,
        processed_at: "2022-05-25T15:47:46+05:00",
        subtotal_price: "99.00",
        total_price: "114.84",
        total_price_set: {
          shop_money: {
            amount: "114.84",
          },
        },
        total_weight: 0,
        updated_at: "2022-05-25T15:47:47+05:00",
        line_items: [
          {
            id: 12233094725850,
            price: "70.00",
            quantity: 1,
            sku: "AD-03-black-OS",
            title: "ADIDAS | CLASSIC BACKPACK",
          },
          {
            id: 12233094758618,
            price: "29.00",
            quantity: 1,
            sku: "VN-01-burgandy-4",
            title: "VANS |AUTHENTIC | LO PRO | BURGANDY/WHITE",
          },
        ],
        shipmentTracking: [],
        shipperDetail: [
          {
            shopDetail: {
              name: "CodingStorte",
              email: "usamariaz2844@gmail.com",
              domain: "codingstorte.myshopify.com",
              address1: "Punjab Pakistan",
              city: "Faisalabad",
              phone: "",
              country_name: "Pakistan",
            },
          },
        ],
      },
      {
        _id: "628b7c9c4c50fcd100467422",
        courier: "",
        courierAssigned: false,
        resellerName: "360Rack",
        dispatchStatus: "confirm",
        deliveryStatus: "not_delivered",
        storeId: "62878032582e6500dc75ac17",
        storeName: "codingstorte.myshopify.com",
        orderId: "4785821417690",
        orderRefNumber: "#1008-CodingStorte",
        cancel_reason: null,
        cancelled_at: null,
        created_at: "2022-05-23T17:22:48+05:00",
        current_total_price: "114.84",
        financial_status: "paid",
        fulfillment_status: null,
        name: "#1008",
        note: null,
        order_number: 1008,
        processed_at: "2022-05-23T17:22:48+05:00",
        subtotal_price: "99.00",
        total_price: "114.84",
        total_price_set: {
          shop_money: {
            amount: "114.84",
          },
        },
        total_weight: 0,
        updated_at: "2022-05-23T17:22:49+05:00",
        line_items: [
          {
            id: 12229918916826,
            price: "70.00",
            quantity: 1,
            sku: "AD-03-black-OS",
            title: "ADIDAS | CLASSIC BACKPACK",
          },
          {
            id: 12229918949594,
            price: "29.00",
            quantity: 1,
            sku: "VN-01-burgandy-4",
            title: "VANS |AUTHENTIC | LO PRO | BURGANDY/WHITE",
          },
        ],
        shipmentTracking: [],
        shipperDetail: [
          {
            shopDetail: {
              name: "CodingStorte",
              email: "usamariaz2844@gmail.com",
              domain: "codingstorte.myshopify.com",
              address1: "Punjab Pakistan",
              city: "Faisalabad",
              phone: "",
              country_name: "Pakistan",
            },
          },
        ],
      },
    ]);
  }, []);

  const resetFilters = async () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedDateFilter(null);
    setManualOrdersLoaded(true);
    // await loadManualOrders({});
  };

  const consigneeDetail = (item) => {
    let orderItems = item.slice(0, 30);
    let itemsLength = item.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 20 ? "...." : ""}`}
          {itemsLength > 20 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="consigneeDetailPopover"
              onMouseEnter={() => {
                setConsigneeDetails([item]);
              }}
            />
          ) : (
            ""
          )}

          <ConsigneeDetails
            consigneeDetails={consigneeDetails && consigneeDetails}
          />
        </div>
      </>
    );
  };
  const orderDetails = (items) => {
    let orderItems = items[0].title.slice(0, 30);
    let itemsLength = items[0].title.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 20 ? "...." : ""}`}
          {itemsLength > 20 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="orderDetailPopover"
              onMouseEnter={() => {
                setOrderDetailsData(items);
              }}
            />
          ) : (
            ""
          )}
          <OrdersDetailsPopup
            orderDetailsData={orderDetailsData && orderDetailsData}
          />
        </div>
      </>
    );
  };

  const ManualOrdersData =
    manualOrders &&
    manualOrders.length > 0 &&
    manualOrders
      .filter((order) => {
        if (search === "") {
          return order;
        } else if (order.hasOwnProperty("shipping_address")) {
          return order.shipping_address.name
            ? order.shipping_address.name
                .toLowerCase()
                .includes(search.toLowerCase())
            : "";
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.address1
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return order;
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.phone
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return order;
        } else if (order.name.toLowerCase().includes(search.toLowerCase())) {
          return order;
        }
        return false;
      })
      .map((order) => ({
        id: order.orderId,
        name: order.shipping_address ? order.shipping_address.name : "N/A",
        orderDate: DateFormatted(order.created_at, setDateTime, dateTime),
        address: order?.shipping_address?.address1
          ? consigneeDetail(order?.shipping_address?.address1)
          : "N/A",
        phone: order?.shipping_address?.phone
          ? formatPhoneNumber(order?.shipping_address?.phone, true)
          : "N/A",
        amount: Math.trunc(
          order.total_price_set.shop_money.amount
        ).toLocaleString(),
        details: orderDetails(order.line_items),
        orderId: order.name,
        resellerName: order.resellerName,
        city: order.shipping_address ? order.shipping_address.city : "N/A",
        items:
          order.line_items && order.line_items.length > 0
            ? showItemDetails(
                order.line_items,
                setItemDetailsData,
                itemDetailsData
              )
            : "N/A",
        storeName: order.storeName.split(".")[0],
        updated_at: order.updatedAt,
        created_at: order.createdAt,
      }));

  const cityOrderData =
    manualOrders &&
    manualOrders.length > 0 &&
    manualOrders
      .filter((order) => {
        if (citySearch === "") {
          return order;
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.city &&
          order.shipping_address.city
            .toLowerCase()
            .includes(citySearch.toLowerCase())
        ) {
          return order;
        }
        return false;
      })
      .map((order) => ({
        id: order.orderId,
        name: order.shipping_address ? order.shipping_address.name : "N/A",
        orderDate: DateFormatted(order.created_at, setDateTime, dateTime),
        address: order?.shipping_address?.address1
          ? consigneeDetail(order?.shipping_address?.address1)
          : "N/A",
        phone: order?.shipping_address?.phone
          ? formatPhoneNumber(order?.shipping_address?.phone, true)
          : "N/A",
        amount: Math.trunc(
          order.total_price_set.shop_money.amount
        ).toLocaleString(),
        details: orderDetails(order.line_items),
        orderId: order.name,
        resellerName: order.resellerName,
        city: order.shipping_address ? order.shipping_address.city : "N/A",
        items:
          order.line_items && order.line_items.length > 0
            ? showItemDetails(
                order.line_items,
                setItemDetailsData,
                itemDetailsData
              )
            : "N/A",
        storeName: order.storeName.split(".")[0],
        updated_at: order.updatedAt,
        created_at: order.createdAt,
      }));

  const cols = React.useMemo(
    () => [
      {
        Header: "Order#",
        accessor: "orderId",
        cellClass: "table-text w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "COD",
        accessor: "amount",
        cellClass: "table-text w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Items(Count Only)",
        accessor: "items",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Reseller",
        accessor: "resellerName",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      // {
      //   Header: 'Store',
      //   accessor: 'storeName',
      //   cellClass: 'table-text w-10',
      //   Cell: (props) => <>{props.value}</>,
      // },
    ],
    []
  );

  return (
    <>
      {manualOrdersLoaded && manualOrders.length <= 0 ? (
        <Card className="my-3 py-5">
          <CardBody
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h4 style={{ color: "red" }}>
              <b>No Manual Orders!</b>
            </h4>
            {showResetButton === false ? (
              ""
            ) : (
              <Button
                color="primary"
                className="mt-1 btn-sm"
                onClick={resetFilters}
              >
                Reset Data
              </Button>
            )}
          </CardBody>
        </Card>
      ) : !manualOrdersLoaded && manualOrders.length === 0 ? (
        <>
          <div className="loading" />
          <div
            style={{
              height: "70vh",
            }}
          ></div>
        </>
      ) : (
        <Row>
          <Cols xxs="12">
            <Card className="mb-4">
              <CardBody>
                <CardTitle>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className="container"
                  >
                    <div className="search-sm mb-2 global-search-font">
                      <input
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          if (e) {
                            setSearch(e.target.value);
                            setSearchNameBool(true);
                          } else {
                            setSearchNameBool(false);
                          }
                        }}
                      />
                    </div>
                    <div>
                      <DateFilters
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        selectedDateFilter={selectedDateFilter}
                        setSelectedDateFilter={setSelectedDateFilter}
                        setShowResetButton={setShowResetButton}
                        // loadOrders={loadManualOrders}
                        resetFilters={resetFilters}
                      />
                    </div>
                    <div className="city_search_container  mb-2 ml-3">
                      <div className="search-sm d-inline-block confirm-city-search">
                        <input
                          type="text"
                          name="keyword"
                          id="city_search"
                          placeholder="Search City"
                          value={citySearch}
                          onChange={(e) => {
                            if (e) {
                              setSearchCityBool(true);
                              setCitySearch(e.target.value);
                            } else {
                              setSearchCityBool(false);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </CardTitle>
                {loading && (
                  <div className="mx-2" style={{ marginBottom: "-5px" }}>
                    <Alert color="success">
                      <Spinner
                        color="success"
                        size={"sm"}
                        style={{ marginBottom: "2px" }}
                      ></Spinner>{" "}
                      &nbsp;
                      <span style={{ fontSize: "15px" }}>Orders Loading!</span>
                    </Alert>
                  </div>
                )}
                {((cityOrderData.length === 0 && searchCityBool) ||
                  (ManualOrdersData.length === 0 && searchNameBool)) && (
                  <h4
                    style={{
                      color: "red",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <b>No Order Matched!</b>
                  </h4>
                )}

                {cityOrderData.length !== 0 &&
                  ManualOrdersData.length !== 0 && (
                    <DataTable
                      setPageLimitInParent={setPageLimit}
                      setPageNumberInParent={setPageNumber}
                      // fetchData={loadManualOrders}
                      pageCount={totalPagesCount}
                      columns={cols}
                      data={
                        citySearch !== ""
                          ? cityOrderData.sort(sortByDate)
                          : ManualOrdersData.sort(sortByDate)
                      }
                    />
                  )}
              </CardBody>
            </Card>
          </Cols>
        </Row>
      )}
    </>
  );
};

export default ResaleManualOrders;
