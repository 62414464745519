import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { MdContentCopy } from "react-icons/md";
import { data } from "./data";
import {
  Badge,
  Breadcrumb,
  Card,
  Button,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  CardTitle,
  Table,
} from "reactstrap";
const ResellerDetail = () => {
  const { id } = useParams();
  const [products, setProducts] = useState(data);
  const [openDelete, setOpenDelete] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [copyNumber, setCopyNumber] = useState(false);
  const [comment, setComment] = useState();
  const [postComment, setPostComment] = useState([]);
  const [error, setError] = useState(false);
  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };
  const commentChangeHandler = (evt) => {
    const { value } = evt.target;
    setComment(value);
    value.length < 1 ? setError(true) : setError(false);
  };
  console.log(comment);
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const commentHandler = () => {
    if (comment) {
      setError(false);
      setPostComment([...postComment, comment]);
      setComment("");
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <main>
        <Row className="mobileMargin ms-sm-3">
          <Cols xxs="12">
            <h1 className="pb-0">{products[id - 1]?.vendor}</h1>
            <div className="float-right">
              <Button
                type="button"
                color="primary"
                outline
                className="mr-2"
                onClick={() => setOpenDelete(true)}
              >
                Ban Reseller
              </Button>
              <Button type="button" color="primary" className="">
                Export History
              </Button>
            </div>
          </Cols>
          <Cols xxs="12">
            <p>Lahore,Pakistan Reselling for 4 months</p>
          </Cols>
        </Row>
        <Row className=" mobileMargin ms-sm-3">
          <Cols xxs="8">
            <Card className="mb-4 pt-3">
              <CardBody className="px-4">
                <Table>
                  <thead>
                    <tr>
                      <th className="p-0 border-0 ">Last Order</th>
                      <th className="p-0 border-0 text-muted">
                        Total spent to date
                      </th>
                      <th className="p-0 border-0 text-muted">
                        Average Order Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ border: "none" }}>
                        <h5 className="bold mb-0">1 Day Ago</h5>
                      </td>
                      <td style={{ border: "none" }}>
                        <h5 className="bold mb-0">RS 150,350.70</h5>
                      </td>
                      <td style={{ border: "none" }}>
                        <h5 className="bold mb-0">Rs 581,5.80</h5>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "none" }} className="text-muted">
                        From Online Store
                      </td>
                      <td style={{ border: "none" }} className="text-muted">
                        82 Orders
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <Card className="mb-4">
              <CardBody className="px-4">
                <div className="row">
                  <div className="col-1 ">
                    <img
                      src={products[id - 1].image}
                      alt="image not found"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "25px",
                      }}
                    />
                  </div>
                  <div className="col-11">
                    <Input
                      type="textarea"
                      className="text-muted"
                      placeholder="Leave a comment..."
                      rows="2"
                      name="first"
                      value={comment}
                      onChange={commentChangeHandler}
                    />
                    {error ? (
                      <p style={{ color: "red" }}>
                        please write your comment first
                      </p>
                    ) : (
                      ""
                    )}
                    <Button
                      color="primary"
                      type="submit"
                      className="float-right mt-2"
                      onClick={commentHandler}
                    >
                      Post
                    </Button>
                  </div>
                </div>
                <p className="text-right pt-1 text-muted">
                  Only you and other staff can see commants
                </p>
                <p className="text-muted ms-5 mb-0">YESTERDAY</p>
                <ul className="list-change me-3">
                  {postComment.map((data) => (
                    <li>
                      <span className="space">{data}</span>
                    </li>
                  ))}
                </ul>
              </CardBody>
            </Card>
          </Cols>
          <Cols xxs="4">
            <Card className="mb-4 pt-2">
              <CardBody className="px-4">
                <div className="d-flex justify-content-between">
                  <h3 className="bold">Note</h3>
                  <Link to={"#"} style={{ color: "#9E1E63" }}>
                    Edit
                  </Link>
                </div>
                <h6 className="pt-2 text-muted">
                  No notes about this customer
                </h6>
              </CardBody>
            </Card>
            <Card className="mb-4  pt-2">
              <CardBody className="px-4">
                <h3 className="bold">Reseller</h3>
                <div className="d-flex justify-content-between">
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    autohide={true}
                    target="showCopyTextTooltip"
                    toggle={toggle}
                  >
                    {copyNumber ? "copied" : "copy text!"}
                  </Tooltip>
                  {products[id - 1].phoneNum}
                  <CopyToClipboard text={products[id - 1].phoneNum}>
                    <MdContentCopy
                      style={{ cursor: "pointer" }}
                      id="showCopyTextTooltip"
                      onClick={() => setCopyNumber(true)}
                    />
                  </CopyToClipboard>
                </div>
                <p>No account</p>
                <hr />
                <h4 className="bold">DEFAULT ADDRESS</h4>
                <div className="d-flex justify-content-between">
                  <address>{products[id - 1].address}</address>
                </div>
              </CardBody>
            </Card>
          </Cols>
        </Row>
      </main>

      {/* ///////////////// Ban Reseller Modal /////////////////// */}

      <div className="ModalDelist">
        <Modal isOpen={openDelete} toggle={() => setOpenDelete(false)}>
          <ModalHeader className="p-3 " toggle={() => setOpenDelete(false)}>
            {" "}
            <strong> Ban Reseller</strong>
          </ModalHeader>
          <ModalBody className="pt-4 pb-2">
            <p>Are you sure you want to ban this seller? </p>
          </ModalBody>
          <ModalFooter className="py-2">
            <Button
              className="secondary"
              type="button"
              onClick={() => {
                setOpenDelete(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              outline
              className=""
              onClick={() => setOpenDelete(false)}
            >
              Ban Reseller
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default ResellerDetail;
