import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Row } from "reactstrap";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import { TabContent, TabPane } from "reactstrap";
import ResellerOrderTab from "../ResalePayments/ResellerOrderTabs";
import AllResaleOrders from "../ResalePayments/AllResaleOrders";
import PendingPayments from "./PendingPayment";
import TransactionHistory from "./TransactionHistory";

const ResalePayments = () => {
  const activeTabFromStorage = localStorage.getItem(
    "activeOrderResalePaymentTab"
  );

  const [activeTab, setActiveTab] = useState(
    activeTabFromStorage ? activeTabFromStorage : "Orders"
  );

  return (
    <div>
      <main>
        <Row>
          <Cols xxs="12">
            <div className="top-text ms-sm-3">
              <h1>Resale Payments</h1>
              <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                <ol className="breadcrumb pt-0">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard" style={{ color: "black" }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Resale Payments
                  </li>
                </ol>
              </Breadcrumb>
            </div>
          </Cols>
        </Row>
        <ResellerOrderTab activeTab={activeTab} setActiveTab={setActiveTab} />
        <TabContent activeTab={activeTab}>
          <TabPane tabId="Orders">
            {activeTab === "Orders" ? <AllResaleOrders /> : ""}
          </TabPane>
          <TabPane tabId="Pending">
            {activeTab === "Pending" ? <PendingPayments /> : ""}
          </TabPane>
          <TabPane tabId="Transaction History">
            {activeTab === "Transaction History" ? <TransactionHistory /> : ""}
          </TabPane>
        </TabContent>
      </main>
    </div>
  );
};

export default ResalePayments;
