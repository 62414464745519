import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";

const url = getAPIURL();

export const updateOrderDispatchStatus = async (
  orderId,
  dispatchStatus,
  storeId
) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const data = {
    orderDispatchStatus: dispatchStatus,
    orderIdNumber: orderId,
    storeIdNumber: storeId,
  };
  const response = await axios.put(
    `${url}/api/orders/orderDispatchStatus`,
    data,
    config
  );
  return response;
};

export const getOrderByDispatchStatus = async ({
  dispatchStatus,
  isAssigned,
  fulfillmentStatus = null,
  deliveryStatus = "not_delivered",
  page = 1,
  limit = 50,
  startDateIs = null,
  endDateIs = null,
  title,
  searchByCity,
  filterByCity,
}) => {
  let token = getCookie();
  const params = new URLSearchParams();
  params.append("fulfillmentStatus", fulfillmentStatus);
  params.append("status", dispatchStatus);
  params.append("isAssigned", isAssigned?.toString());
  params.append("deliveryStatus", deliveryStatus);
  params.append("title", title?.toString()?.trim());
  params.append("searchByCity", searchByCity?.toString()?.trim());
  params.append("filterByCity", filterByCity);
  params.append("page", page?.toString());
  params.append("limit", limit?.toString());
  params.append("startDate", startDateIs);
  params.append("endDate", endDateIs);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };
  return await axios.get(`${url}/api/orders/orderDispatchStatus`, config);
};

export const updateOrderDeliveryStatus = async ({
  orderId,
  deliveryStatus,
  storeId,
}) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const data = { deliveryStatus, orderId, storeId };
  const response = await axios.put(
    `${url}/api/orders/orderDeliveryStatus`,
    data,
    config
  );
  return response;
};

export const getOrderByDeliveryStatus = async ({
  deliveryStatus,
  fulfillmentStatus = null,
}) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { status: deliveryStatus, fulfillmentStatus },
  };
  const response = await axios.get(
    `${url}/api/orders/orderDeliveryStatus`,
    config
  );
  return response;
};

export const addTracking = async (trackingData) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${url}/api/orders/addTracking`,
    trackingData,
    config
  );
  return response;
};

export const fulfillOrderItems = async (line_items, orderId, storeId) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const data = { orderId, line_items, storeId };
  const response = await axios.post(
    `${url}/api/orders/updateOrdersFulfillment`,
    data,
    config
  );
  return response;
};

export const fulfillSelectedOrders = async (
  orderId,
  companyName,
  line_items,
  hasCourierAPI,
  trackingNumber,
  storeId
) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const data = {
      orderId,
      companyName,
      line_items,
      hasCourierAPI,
      trackingNumber,
      storeId,
    };
    const response = await axios.post(
      `${url}/api/orders/autoOrdersFulfillment`,
      data,
      config
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const getOrders = async ({
  title = "",
  status = "",
  payment = [],
  fulfillment = [],
  returnCountOnly = false,
  page = 1,
  limit = 50,
  startDateIs = null,
  endDateIs = null,
} = {}) => {
  let token = getCookie();
  const params = new URLSearchParams();
  params.append("title", title?.toString().trim());
  params.append("status", status);
  params.append("payment", payment?.toString());
  params.append("fulfillment", fulfillment);
  params.append("returnCountOnly", returnCountOnly?.toString());
  params.append("page", page.toString());
  params.append("limit", limit.toString());
  params.append("startDate", startDateIs);
  params.append("endDate", endDateIs);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };
  return axios.get(`${url}/api/orders/allOrders`, config);
};

export const getOrderById = async (id) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${url}/api/orders/${id}`, config);
  return response;
};

export const getOrdersByFulFillment = async (statuses) => {
  let token = getCookie();
  const config = {
    Authorization: `Bearer ${token}`,
  };
  let params = {};
  params.status = statuses;
  const response = await axios({
    url: `${url}/api/orders/fulfilledOrders`,
    params: params,
    headers: config,
  });
  return response;
};
export const getOrdersByDateForFilter = async (startDate, endDate) => {
  const token = getCookie();
  const data = { startDate, endDate };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${url}/api/orders/filterOrderByDate`,
      data,
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

export const getOrdersByPaymentStatus = async (statuses) => {
  let token = getCookie();
  const config = {
    Authorization: `Bearer ${token}`,
  };
  let params = {};
  params.status = statuses;
  const response = await axios({
    url: `${url}/api/orders/paymentStatus`,
    params: params,
    headers: config,
  });
  return response;
};

export const getPendingOrders = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${url}/api/orders/pending`, config);
  return response;
};

export const updateOrdersCouriers = async (orders) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${url}/api/orders/assignedOrders`,
    { orders },
    config
  );
  return response;
};

// get unfulfilled orders with assigned or unassigned couriers
export const getCourierAssignedOrders = async (isAssigned) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${url}/api/orders/assignedOrders?isAssigned=${isAssigned}`,
    config
  );
  return response;
};

export const initializeBulkDataJob = async (storeId) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(
    `${url}/api/jobs/shopifyBulkOperation`,
    { storeId },
    config
  );
};

export const storeOrders = async ({
  accessToken = null,
  domainName = null,
  statusType = "status",
  status = "any",
} = {}) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  let data = null;
  if (accessToken && domainName) {
    data = { accessToken, domainName };
  }
  const response = await axios.post(
    `${url}/api/orders/allOrders?statusType=${statusType}&status=${status}`,
    data,
    config
  );
  return response;
};

// get order tracking number from user and then check order status
export const getOrderTracking = async (trackingNumber) => {
  const response = await axios.get(
    `${url}/api/tracking/orderTracking/${trackingNumber}`
  );
  return response;
};

export const addTrackingNumbers = async ({
  orderId,
  storeId,
  trackingCompany,
  shipmentType,
}) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  let data = { orderId, storeId, trackingCompany, shipmentType };
  const response = await axios.post(
    `${url}/api/orders/saveShipmentRecord`,
    data,
    config
  );
  return response;
};

export const addTrackingNumbersFromFile = async ({ orders, companyName }) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${url}/api/orders/addShipmentRecord`,
    { orders, companyName },
    config
  );
  return response;
};

export const EditOrderCityName = async (newCity, orderId) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(
    `${url}/api/orders/editOrderCity`,
    { newCity, orderId },
    config
  );
};

export const getCourierSlip = async ({
  name,
  orderRefNumber,
  trackingNumber,
  courierCompany,
  deliveryType,
  sku,
  id,
  printPages,
}) => {
  const token = getCookie();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      responseType: "arraybuffer",
      responseEncoding: "binary",
    },
  };
  const response = await axios.post(
    `${url}/api/pdf/courierSlip`,
    {
      name,
      orderRefNumber,
      trackingNumber,
      courierCompany,
      deliveryType,
      sku,
      id,
      printPages,
    },
    config
  );
  return response;
};

export const getInvoiceSlip = async (data) => {
  const token = getCookie();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      responseType: "arraybuffer",
      responseEncoding: "binary",
    },
  };
  const response = await axios.post(
    `${url}/api/pdf/invoiceSlip`,
    {
      ...data,
    },
    config
  );
  return response;
};

export const getCouriersLoadsheet = async (data) => {
  const token = getCookie();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      responseType: "arraybuffer",
      responseEncoding: "binary",
    },
  };
  const response = await axios.post(
    `${url}/api/pdf/loadSheetPDF`,
    {
      ...data,
    },
    config
  );
  return response;
};

export const getTraxLabel = async (payload) => {
  const headers = {
    Authorization: payload.courierToken,
    // responseType: "arraybuffer",
    // responseEncoding: "binary",
  };
  const params = { tracking_number: payload.orderTracking, type: 1 };
  const pdf = await axios.get("https://sonic.pk/api/shipment/air_waybill", {
    params,
    headers,
    responseType: "arraybuffer",
  });
  console.log(pdf);
  return { pdf: pdf?.data };
};

export const updateOrderOnshopify = async (payload) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${url}/api/orders/updateOrder`,
    { ...payload },
    config
  );
  return response;
};
