import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Row } from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";

const ResellingReports = () => {
  return (
    <div>
      <main>
        <Row className="top-text ms-sm-3">
          <Cols xxs="12">
            <h1>Reselling Reports</h1>
            <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" style={{ color: "black" }}>
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Reselling Reports
                </li>
              </ol>
            </Breadcrumb>
          </Cols>
        </Row>
      </main>
    </div>
  );
};

export default ResellingReports;
