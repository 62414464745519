import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

const ResellingOrderTabs = ({ activeTab, setActiveTab }) => {
  return (
    <>
      <Nav
        tabs
        className="separator-tabs ml-0 mb-5"
        style={{ cursor: "pointer" }}
      >
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "AllOrders",
              "nav-link": true,
            })} ${activeTab === "AllOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "AllOrders");
              setActiveTab("AllOrders");
            }}
            location={{}}
            to="#"
          >
            {"All"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "openOrders",
              "nav-link": true,
            })} ${activeTab === "openOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "openOrders");
              setActiveTab("openOrders");
            }}
            location={{}}
            to="#"
          >
            {"Open"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "pendingOrders",
              "nav-link": true,
            })} ${activeTab === "pendingOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "pendingOrders");
              setActiveTab("pendingOrders");
            }}
            location={{}}
            to="#"
          >
            {"Pending"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "confirmedOrders",
              "nav-link": true,
            })} ${activeTab === "confirmedOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "confirmedOrders");
              setActiveTab("confirmedOrders");
            }}
            location={{}}
            to="#"
          >
            {"Confirmed"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "assignedOrders",
              "nav-link": true,
            })} ${activeTab === "assignedOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "assignedOrders");
              setActiveTab("assignedOrders");
            }}
            location={{}}
            to="#"
          >
            {"Booked"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={` ${classnames({
              active: activeTab === "unAssignedOrders",
              "nav-link": true,
            })} ${activeTab === "unAssignedOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "unAssignedOrders");
              setActiveTab("unAssignedOrders");
            }}
            location={{}}
            to="#"
          >
            {"UnAssigned"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "shippedOrders",
              "nav-link": true,
            })} ${activeTab === "shippedOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "shippedOrders");
              setActiveTab("shippedOrders");
            }}
            location={{}}
            to="#"
          >
            {"Shipped"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={` ${classnames({
              active: activeTab === "deliveredOrders",
              "nav-link": true,
            })} ${activeTab === "deliveredOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "deliveredOrders");
              setActiveTab("deliveredOrders");
            }}
            location={{}}
            to="#"
          >
            {"Delivered"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={` ${classnames({
              active: activeTab === "onholdOrders",
              "nav-link": true,
            })} ${activeTab === "fulfilledOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "onholdOrders");
              setActiveTab("onholdOrders");
            }}
            location={{}}
            to="#"
          >
            {"Onhold"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={` ${classnames({
              active: activeTab === "cancelledOrders",
              "nav-link": true,
            })} ${activeTab === "cancelledOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "cancelledOrders");
              setActiveTab("cancelledOrders");
            }}
            location={{}}
            to="#"
          >
            {"Cancelled"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={` ${classnames({
              active: activeTab === "manualOrders",
              "nav-link": true,
            })} ${activeTab === "manualOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "manualOrders");
              setActiveTab("manualOrders");
            }}
            location={{}}
            to="#"
          >
            {"Manual"}
          </NavLink>
        </NavItem>
      </Nav>
    </>
  );
};

export default ResellingOrderTabs;
