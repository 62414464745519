import { Link } from "react-router-dom";
// import { getCurrentBillingData } from "../../../api/billing";
import { Row, Card, CardBody, CardTitle, Form, Col } from "reactstrap";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import Mastercard_Logo from "../../../images/Billing/Mastercard_Logo.png";

const BillingCycleShow = () => {
  // const history = useHistory();
  // const [bills, setBills] = useState([]);
  // const [toggleSubscription, setToggleSubscription] = useState(false);
  // const [usd, setUSD] = useState([]);
  // const [percent, setPercent] = useState([]);

  let today = new Date();
  let date =
    today.getDate() +
    " " +
    today.toLocaleString("en-us", { month: "short" }) +
    " " +
    today.getFullYear();

  //Load Bill at First Render
  // const loadBill = useCallback(async () => {
  //   try {
  //     const billData = await getCurrentBillingData();
  //     setBills(billData.data);
  //     setUSD(billData.data.filter((data) => data.multiplier === '0.1 USD'));
  //     setPercent(billData.data.filter((data) => data.multiplier === '0.5%'));
  //   } catch (error) {
  //     console.log('error found when fetch order data', error);
  //   }
  // }, []);

  // useEffect(() => {
  //   loadBill();
  // }, [loadBill]);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <main>
        <div className="ml-3 mb-2">
          <Link
            to={`/admin/payments/billing`}
            onClick={() => {
              handleScrollToTop();
            }}
          >
            <button
              className="material-icons-outlined mr-3"
              style={{
                border: "2px solid gray",
                padding: "3px",
                display: "inline",
              }}
            >
              arrow_back
            </button>
          </Link>
          <h2 className="mb-4" style={{ display: "inline" }}>
            <b>Billing Cycle: {date}</b>
          </h2>
        </div>
        <br />
        <Form>
          <Row>
            <Cols xxs="12" className="row icon-cards-row mb-2">
              <Cols xs="12" sm="12" md="7" lg="8" className="mb-4 ">
                <Card className="mb-2">
                  <CardBody>
                    <CardTitle className="mx-3 ">
                      <h5>
                        <b>Order Fulfillment</b>
                        <b
                          className="mr-0"
                          style={{ marginRight: "5px", float: "right" }}
                        >
                          Rs. 3000
                        </b>
                      </h5>
                    </CardTitle>
                    <hr className="text-muted" />
                    <br />
                    <div className="mx-3">
                      <Row>
                        <Col md="6">
                          <div>
                            <h6 className="mr-5">
                              Wasseh.com<strong> (1520 orders)</strong>
                            </h6>
                          </div>
                        </Col>
                        <Col md="2" className="text-center">
                          <p>(x 10)</p>
                        </Col>
                        <Col md="4" className="text-right">
                          <p>Rs. 35,000</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div>
                            <h6 className="mr-5">
                              Secondstore.com<strong> (220 orders)</strong>
                            </h6>
                          </div>
                        </Col>
                        <Col md="2" className="text-center">
                          <p>(x 1)</p>
                        </Col>
                        <Col md="4" className="text-right">
                          <p>Rs. 25,000</p>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card className="mb-2">
                  <CardBody>
                    <CardTitle className="mx-3">
                      <h5>
                        <b>Reselling Orders</b>
                        <b
                          className="mr-0"
                          style={{ marginRight: "5px", float: "right" }}
                        >
                          Rs. 5000
                        </b>
                      </h5>
                    </CardTitle>
                    <hr className="text-muted" />
                    <br />
                    <div className="mx-3">
                      <Row>
                        <Col md="6">
                          <div>
                            <h6 className="mr-5">
                              website1.com{" "}
                              <p>
                                <strong> (60 orders)</strong>
                              </p>
                            </h6>
                          </div>
                        </Col>
                        <Col md="2" className="text-center">
                          <p>(x 60)</p>
                        </Col>
                        <Col md="4" className="text-right">
                          <p>Rs. 5,000</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div>
                            <h6 className="mr-5">
                              website2.com{" "}
                              <p>
                                <strong> (10 orders)</strong>
                              </p>
                            </h6>
                          </div>
                        </Col>
                        <Col md="2" className="text-center">
                          <p>(x 60)</p>
                        </Col>
                        <Col md="4" className="text-right">
                          <p>Rs. 8,000</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div>
                            <h6 className="mr-5">
                              website3.com{" "}
                              <p>
                                <strong> (40 orders)</strong>
                              </p>
                            </h6>
                          </div>
                        </Col>
                        <Col md="2" className="text-center">
                          <p>(x 60)</p>
                        </Col>
                        <Col md="4" className="text-right">
                          <p>Rs. 3,000</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div>
                            <h6 className="mr-5">
                              website4.com{" "}
                              <p>
                                <strong> (60 orders)</strong>
                              </p>
                            </h6>
                          </div>
                        </Col>
                        <Col md="2" className="text-center">
                          <p>(x 60)</p>
                        </Col>
                        <Col md="4" className="text-right">
                          <p>Rs. 5,000</p>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card className="mb-2">
                  <CardBody>
                    <CardTitle className="mx-3 mb-0">
                      <h5>
                        <b>SMS</b>
                        <b
                          className="mr-0"
                          style={{ marginRight: "5px", float: "right" }}
                        >
                          Rs. 3000
                        </b>
                      </h5>
                      <hr className="text-muted" />
                      <Row>
                        <Col md="8">
                          <div>
                            <p className="mb-2">
                              <strong> 1000 SMS</strong>
                            </p>
                            <p>
                              There Is No Messages On Your Account Right Now{" "}
                            </p>
                          </div>
                        </Col>
                        <Col md="4" className="text-right">
                          <p>Rs. 3,000</p>
                        </Col>
                      </Row>
                    </CardTitle>
                  </CardBody>
                </Card>
                <Card className="mb-2">
                  <CardBody>
                    <CardTitle className="mx-3 mb-0">
                      <h5>
                        <b>Apps (1 item)</b>
                        <b
                          className="mr-0"
                          style={{ marginRight: "5px", float: "right" }}
                        >
                          Rs. 2000
                        </b>
                      </h5>
                      <hr className="text-muted" />
                      <Row>
                        <Col md="8">
                          <div>
                            <p className="mb-2">
                              <strong>Advanced Reports</strong>
                            </p>
                            <p>
                              There Is No Reports On Your Account Right Now{" "}
                            </p>
                          </div>
                        </Col>
                        <Col md="4" className="text-right">
                          <p>Rs. 5,000</p>
                        </Col>
                      </Row>
                    </CardTitle>
                  </CardBody>
                </Card>
                <Card className="mb-2">
                  <CardBody>
                    <CardTitle className="mx-3 mb-0">
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">
                              <strong>
                                Void orders{" "}
                                <span className="small">
                                  (Return, Void, Cancelled, Manual, Refund
                                  orders)
                                </span>
                              </strong>
                            </h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>
                            <strong>-Rs. 3000</strong>
                          </h6>
                        </Col>
                      </Row>
                      <hr className="text-muted" />
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">
                              <strong>
                                Freetier discount/Max out discount
                              </strong>
                            </h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>
                            <strong>-Rs. 8000</strong>
                          </h6>
                        </Col>
                      </Row>
                      <hr className="text-muted" />
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">
                              <strong>Affiliate Earning</strong>
                            </h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>
                            <strong>-Rs. 33000</strong>
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">Waseeh.com</h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>-Rs. 1000</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">Mainsite.com</h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>-Rs. 1000</h6>
                        </Col>
                      </Row>
                    </CardTitle>
                  </CardBody>
                </Card>
                <Card className="mb-2">
                  <CardBody className="mx-3 ">
                    <CardTitle className="mb-0">
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">
                              <strong>Gross Total</strong>
                            </h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>
                            <strong>-Rs. 3000</strong>
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">Dicount (First month)</h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>-1000</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">Additional Charges</h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>Rs. 00</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">Tax</h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>0%</h6>
                        </Col>
                      </Row>
                    </CardTitle>
                    <hr className="text-muted" />
                    <Row>
                      <Col md="9" className="text-right">
                        <div>
                          <h3 className="mb-2">
                            <strong>Total</strong>
                          </h3>
                        </div>
                      </Col>
                      <Col md="3" className="text-right">
                        <h3>
                          <strong>RS. 45,000</strong>
                        </h3>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div
                  className="mx-3 mt-5 px-3 py-2"
                  style={{ backgroundColor: "#EDEDED" }}
                >
                  <Row>
                    <Col>
                      <h5 className="mb-5">
                        <b>Understanding your Bill</b>
                      </h5>
                    </Col>
                  </Row>
                  <hr className="text-muted" />
                  <Row className="mt-4">
                    <Col>
                      <h5 className="mb-5">
                        <b>Order Fulfillment</b>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin vehicula velit in quamimpe rdiet, at bibendum
                        neque facilisis.
                      </p>
                    </Col>
                    <Col>
                      <h5 className="mb-5">
                        <b>Muiltistore Management</b>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin vehicula velit in quamimpe rdiet, at bibendum
                        neque facilisis.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5 className="mb-5">
                        <b>Reselling</b>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin vehicula velit in quamimpe rdiet, at bibendum
                        neque facilisis.
                      </p>
                    </Col>
                    <Col>
                      <h5 className="mb-5">
                        <b>SMS</b>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin vehicula velit in quamimpe rdiet, at bibendum
                        neque facilisis.
                      </p>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <h5 className="mb-5">
                        <b>Apps</b>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin vehicula velit in quamimpe rdiet, at bibendum
                        neque facilisis.
                      </p>
                    </Col>
                    <Col>
                      <h5 className="mb-5">
                        <b>Void Orderst</b>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin vehicula velit in quamimpe rdiet, at bibendum
                        neque facilisis.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <h6>
                        Email <Link to=" ">billing@shopilam.com</Link> if you
                        have any questions about your{" "}
                        <Link to=" ">Shopilam</Link> bill
                      </h6>
                    </Col>
                  </Row>
                </div>
              </Cols>
              <Cols xs="12" sm="12" md="5" lg="4" className="mb-4 ">
                <Card>
                  <CardBody className="pb-2">
                    <CardTitle className="mx-3 text-center">
                      <h2 className="text-color mb-4">
                        <b>35,000 PKR</b>
                      </h2>
                    </CardTitle>
                    <p
                      className="mb-3 text-light text-center py-2"
                      style={{
                        backgroundColor: "#b69329",
                        borderColor: " #b69329",
                        fontSize: "16px",
                      }}
                    >
                      <strong>Unpaid</strong>
                    </p>
                    <p
                      className="mb-3 text-dark text-center py-2"
                      style={{
                        backgroundColor: "#EDEDED",
                        // borderColor: ' #b69329',
                        fontSize: "16px",
                      }}
                    >
                      <strong>Paid</strong>
                    </p>
                    <div className="mx-3">
                      <div className="mb-2">
                        <h6>
                          <b>PAYMENT METHOD</b>
                        </h6>
                      </div>
                      <div className="d-flex justify-Content-center align-items-center gap-1 mb-4">
                        <div className="border">
                          <img
                            src={Mastercard_Logo}
                            height="25"
                            weight="35"
                            alt="not found"
                          />
                        </div>
                        <p className="mb-0">
                          <b>Bank Transfer</b>
                        </p>
                      </div>
                      <div>
                        <h6 className="mb-2">
                          <strong>BANK NAME</strong>
                        </h6>
                        <p>AlFalah Bank Limited</p>
                      </div>
                      <hr className="text-muted" />
                      <div>
                        <h6 className="mb-3">
                          <b>PAYMENT TIMELINE</b>
                        </h6>
                        <p className="mb-1">Issue Date</p>
                        <p>
                          <strong>{date}</strong>
                        </p>
                        <p className="mb-1">Due Date</p>
                        <p>
                          <strong>{date}</strong>
                        </p>
                        {/* <p className="mb-1">Paid Date</p>
                      <p>
                        <strong>{date}</strong>
                      </p> */}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Cols>
            </Cols>
          </Row>
        </Form>
      </main>
    </>
  );
};

export default BillingCycleShow;
