import React, { useState, useMemo, useRef } from 'react';
import {
  Card,
  CardBody,
  Row,
  Input,
  CardTitle,
  FormGroup,
  Button,
  Label,
  Table,
} from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { Cols } from '../../SeparatorStyle/SeparatorStyle';
import ImageUploading from 'react-images-uploading';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { data } from '../../Seller/data';
import DataTable from '../../tables/table';
import { DateFormatted } from '../../../helpers/dateTime';
import { Spinner } from 'reactstrap';

// import Transactions from './ResellingTransaction';
import defaultColor from './../../../helpers/Utils/defaultValues';

const Orders = () => {
  const ref = useRef(null);

  const [products, setProducts] = useState(data);
  const [dateTime, setDateTime] = useState();
  const [filterData, setFilterData] = useState(products);
  const [showFilterData, setShowFilterData] = useState([]);
  const [show, setShow] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [markDone, setMarkDone] = useState(false);
  const [overLayHide, setOverLayHide] = useState(false);
  const [condenseTable, setCondenseTable] = useState(false);
  const [submitImage, setSubmitImage] = useState(false);
  const [images, setImages] = useState([]);
  const datas = filterData.length === 0 ? products : filterData;
  const maxNumber = 1;
  const acceptType = ['jpg', 'png'];

  const [saveData, setSaveData] = useState([]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setSaveData({
      ...saveData,
      image: imageList[0],
    });
    if (imageList.length > 0) {
      showAni();
      setSubmitImage(false);
    }
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const colsData = [
    {
      Header: 'Date',
      accessor: 'offerExpiry',
      cellClass: 'text-muted w-10',
      Cell: (props) => <>{DateFormatted(props.value, setDateTime, dateTime)}</>,
    },
    {
      Header: 'Count',
      accessor: 'currentQty',
      cellClass: 'text-muted',
      Cell: (props) => <>{numberWithCommas(props.value)}</>,
    },
    {
      Header: 'Amount',
      accessor: 'saleAmount',
      cellClass: 'text-muted',
      Cell: (props) => <>{numberWithCommas(props.value)}</>,
    },
    {
      Header: 'Seller',
      accessor: 'vendor',
      cellClass: 'text-muted',
      Cell: (props) => <>{props.value === '' ? 'NA' : props.value}</>,
    },
    {
      Header: 'Balance',
      accessor: 'inventoryPercent',
      cellClass: 'text-muted',
      Cell: (props) => <>{numberWithCommas(props.value)}</>,
    },
    {
      Header: 'Action',
      accessor: 'id',
      cellClass: 'text-muted',
      Cell: (props) => (
        <>
          <span
            className="iconsminds-credit-card fs-5 text-black pointer"
            onClick={() => showCanvasHandler(props.row.original)}
          ></span>
        </>
      ),
    },
  ];

  const cols = useMemo(() => colsData, [colsData]);

  const handleSearch = (e) => {
    if (e.target.value.length === 0) {
      console.log({ first: e.target.value });
      setFilterData(data);
    } else {
      setFilterData(
        datas.filter((p) =>
          p.vendor.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const blockInvalidChar = (e) => {
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  };

  const submitDataHandler = (values) => {
    console.log('show image length', values);
    if (images.length == 1 && values.notes) {
      setShow(false);
      setCondenseTable(false);
      setSaveData({ ...saveData, amount: values.amount, notes: values.notes });
      setImages([]);
    } else if (images.length == 1) {
      setSaveData({ ...saveData, amount: values.amount });
      setShow(false);
      setImages([]);
      setCondenseTable(false);
    } else {
      setSubmitImage(true);
    }
  };
  console.log(saveData);

  const showCanvasHandler = (data) => {
    setSaveData({
      saller: data?.vendor,
      balanceDue: data?.balance,
    });
    setShow(true);
    setCondenseTable(true);
  };

  const showAni = () => {
    setTimeout(() => {
      setSpinner(true);
      setMarkDone(true);
      setOverLayHide(false);
    }, 1000);
    setTimeout(() => {
      setMarkDone(false);
      setOverLayHide(true);
    }, 2000);
  };

  return (
    <>
      <Row>
        <Cols xxs="12">
          <Card
            className={
              condenseTable ? 'mb-4 w-70 transform-table-pending' : 'mb-4'
            }
          >
            <CardBody>
              <CardTitle className="order_table_card_title mb-0">
                <div className="search-sm my-2 mr-2  global-search-font">
                  <Input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder="Search"
                    // value={search}
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </CardTitle>
              {filterData.length !== 0 ? (
                <DataTable columns={cols} data={filterData} />
              ) : (
                <div className="mt-4">
                  <div className="py-5 mt-3 text-center">
                    <h3 style={{ color: 'red' }}>No Seller Matched!</h3>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Cols>
      </Row>

      {/* /////////////////////////////testing phase */}
      <Offcanvas
        className="canvasWidth"
        show={show}
        onHide={() => {
          setShow(false);
          setCondenseTable(false);
        }}
        placement={'end'}
        scroll={true}
        backdrop={false}
      >
        <Offcanvas.Header closeButton>
          <div>
            <h2 className="float-right bold mt-2 px-2 mb-0">Settle Payment</h2>
          </div>
        </Offcanvas.Header>
        <hr className="my-1" />
        <Offcanvas.Body>
          <div className="h-100 overflow-hidden d-flex flex-column justify-content-between">
            <ul className="list-unstyled">
              <li>
                <span className="bold fs-6">Seller: </span>
                {saveData?.saller}
              </li>
              <li>
                <span className="bold fs-6">Balance Due: </span>
                {saveData?.balanceDue
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </li>
            </ul>
            <Form
              onSubmit={submitDataHandler}
              validate={(values) => {
                const errors = {};
                if (!values.amount) {
                  errors.amount = 'Required';
                }
                // if (!values.uploader) {
                //   errors.uploader = 'Required';
                // }
                return errors;
              }}
              render={({
                handleSubmit,
                values,
                submitting,
                validating,
                valid,
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label htmlFor="amount">
                      Amount <span className="text-danger">*</span>{' '}
                    </Label>
                    <Field name="amount">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="number"
                            min="1"
                            value={saveData.amount}
                            onKeyDown={blockInvalidChar}
                            invalid={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <span className="text-danger">{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="uploader" className="mt-3">
                      Proof of Payment <span className="text-danger">*</span>
                    </Label>
                    <Field name="uploader">
                      {({ input, meta }) => (
                        <div
                          style={{
                            border: '1px solid #DCDCDC',
                            minHeight: '100px',
                          }}
                        >
                          <ImageUploading
                            value={images}
                            onChange={onChange}
                            acceptType={acceptType}
                            maxFileSize={5000000}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                          >
                            {({
                              imageList,
                              onImageUpload,
                              onImageRemove,
                              isDragging,
                              dragProps,
                            }) => (
                              <>
                                <div className="upload__image-wrapper">
                                  {imageList.map((image, index) => (
                                    <>
                                      <div
                                        style={{
                                          width: '100px',
                                          position: 'relative',
                                        }}
                                      >
                                        <img
                                          src={image['data_url']}
                                          alt="image not found"
                                          height="70"
                                          className={
                                            overLayHide
                                              ? 'transimgSet mt-0 w-100'
                                              : spinner
                                              ? 'transimg mt-0 w-100'
                                              : 'transimg mt-0 w-100'
                                          }
                                        />
                                        {markDone && (
                                          <span className="material-icons-outlined markdone">
                                            done
                                          </span>
                                        )}
                                        {!spinner && (
                                          <Spinner
                                            size="sm"
                                            className="markcross"
                                          >
                                            Loading...
                                          </Spinner>
                                        )}

                                        <div className="image-item__btn-wrapper">
                                          <button
                                            className="btn-link"
                                            onClick={() => {
                                              onImageRemove(index);
                                              setSpinner(false);
                                              setMarkDone(false);
                                              setOverLayHide(false);
                                            }}
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </div>
                                {images.length === 0 && (
                                  <div
                                    style={{
                                      display: 'block',
                                      textAlign: 'center',
                                      marginTop: '1.3rem',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <div
                                      onClick={() => {
                                        onImageUpload();
                                      }}
                                      {...dragProps}
                                    >
                                      <span className="material-icons-outlined">
                                        add
                                      </span>
                                      <p>Upload File</p>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </ImageUploading>
                        </div>
                      )}
                    </Field>
                    {submitImage && (
                      <span className="text-danger">required</span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="notes" className="mt-3">
                      Notes
                    </Label>
                    <Field name="notes">
                      {({ input, meta }) => (
                        <div>
                          <Input {...input} type="textarea" col="4" />
                        </div>
                      )}
                    </Field>
                  </FormGroup>

                  <hr className="mt-1" />
                  <div className="d-flex justify-content-center">
                    <Button
                      type="button"
                      color="secondary"
                      className="mr-2"
                      onClick={() => {
                        setShow(false);
                        setCondenseTable(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button color="primary" type="submit" disabled={!valid}>
                      Submit
                    </Button>
                  </div>
                </form>
              )}
            ></Form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Orders;
