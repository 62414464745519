import React, { useState } from 'react';
import { Input } from 'reactstrap';
import Select from 'react-select';

export const ResaleProductsFilters = ({
  setPageLimit,
  setPageNumber,
  setSearchValue,
  searchValue,
  setSearchTrigger,
  searchTrigger,
  vendorOptions,
  vendorFilterValue,
  setVendorFilterValue,
  setEmptyStatus,
  paginationLoading,
  filterLoading,
  setFilterLoading,
}) => {
  // const inventoryOptions = [
  //   { value: '1 to 10', label: '1 to 10' },
  //   { value: '10 to 100', label: '10 to 100' },
  //   { value: '100 to 1000', label: '100 to 1000' },
  //   { value: 'Above 1000', label: 'Above 1000' },
  //   { value: 'Out of Stock', label: 'Out of Stock' },
  // ];

  // const priceOptions = [
  //   { value: '<300', label: '<300' },
  //   { value: '300 to 1000', label: '300 to 1000' },
  //   { value: '1000 to 3000', label: '1000 to 3000' },
  //   { value: '3000 to 8000', label: '3000 to 8000' },
  //   { value: '8000 above', label: '8000 above' },
  // ];

  // const categoryOptions = [];

  const handleSearch = (e) => {
    let searchValue = e.target.value;

    setSearchValue(searchValue);
    setEmptyStatus(false);
    const timeOutId = setTimeout(async () => {
      if (searchValue === e.target.value && searchValue.trim().length > 2) {
        setPageNumber(1);
        setPageLimit(50);

        setSearchTrigger(!searchTrigger);
        setFilterLoading(true);
      }
      if (!searchValue && searchValue === e.target.value) {
        setPageNumber(1);
        setPageLimit(50);

        setSearchTrigger(!searchTrigger);
        setFilterLoading(true);
      }
    }, 2000);
    return () => clearTimeout(timeOutId);
  };

  const handleVendorFilter = async (e) => {
    setVendorFilterValue(e.value);
    setFilterLoading(true);
    setPageNumber(1);
    setPageLimit(50);
  };

  return (
    <div className="container d-flex justify-content-between">
      <div className=" mb-2" style={{ width: '200px' }}>
        <div className=" position-relative">
          <Input
            disabled={paginationLoading || filterLoading}
            className="rounded-3"
            type="text"
            name="keyword"
            value={searchValue}
            id="search"
            placeholder="Search"
            onChange={(e) => {
              handleSearch(e);
            }}
          />
          {searchValue && (
            <span
              className=" position-absolute far fa-close fs-5 text-danger pointer"
              style={{ right: '13px', top: '10px' }}
              onClick={() => {
                setSearchValue('');
                setFilterLoading(true);
                setSearchTrigger(!searchTrigger);
              }}
            ></span>
          )}
        </div>
      </div>
      {/* <div className=" d-flex">
        <div style={{ width: '160px' }}>
          <Select
            isDisabled={paginationLoading || filterLoading}
            isClearable
            // onChange={(e) => {
            //   if (e) {
            //     handleCategoryFilter(e);
            //   } else {
            //     setFilteredApplied((prevState) => {
            //       prevState[0].category = '';
            //       return [...prevState];
            //     });
            //     setFilteredStart(!filteredStart);
            //   }
            // }}
            name="category"
            options={categoryOptions}
            placeholder={'Category'}
            className="mr-2"
          />
        </div>
        <div className="" style={{ width: '140px' }}>
          <Select
            isDisabled={paginationLoading || filterLoading}
            isClearable
            // onChange={(e) => {
            //   if (e) {
            //     handlePriceFilter(e);
            //   } else {
            //     setFilteredApplied((prevState) => {
            //       prevState[3].price = '';
            //       return [...prevState];
            //     });
            //     setFilteredStart(!filteredStart);
            //   }
            // }}
            options={priceOptions}
            placeholder="Price"
            className="mr-2"
          />
        </div>
        <div style={{ width: '140px' }}>
          <Select
            isDisabled={paginationLoading || filterLoading}
            isClearable
            onChange={(e) => {
              if (e) {
                handleInventoryFilter(e);
              } else {
                setFilteredApplied((prevState) => {
                  prevState[2].inventory = '';
                  return [...prevState];
                });
                setFilteredStart(!filteredStart);
              }
            }}
            options={inventoryOptions}
            placeholder="Inventory"
            className="mr-2"
          />
        </div>
        <div style={{ width: '160px' }}>
          <Select
            hideSelectedOptions
            isDisabled={paginationLoading || filterLoading}
            value={
              vendorFilterValue && [
                { value: vendorFilterValue, label: vendorFilterValue },
              ]
            }
            isClearable
            onChange={(e) => {
              if (e) {
                handleVendorFilter(e);
              } else {
                handleVendorFilter((e = { value: '' }));
              }
            }}
            options={vendorOptions}
            placeholder="Vendor"
            className="mr-2"
          />
        </div>
      </div> */}
    </div>
  );
};
