export const data = [
  {
    id: 1,
    pid: 1,
    selected: false,
    sku: "sht-22",
    image:
      "https://cdn.pixabay.com/photo/2014/08/05/10/31/waiting-410328__340.jpg",
    title: "shirts",
    status: "Accept",
    saleAmount: 100000,
    balance: 120000,
    unfilledOrders: 5,
    resellers: 10,
    price: 500,
    cost: 1599,
    type: "Accessories",
    dailySale: 120,
    vendor: "Zaghum Abbas",
    phoneNum: "0303-9551524",
    address:
      "Zaghum Written by Jon Doe Visit us at Example.com Box 564, Disneyland USA",
    collection: "Ladies Watch",
    currentQty: 55,
    qtyThreshold: 200,
    inventoryPercent: 20,
    maxReseller: 5,
    shipping: 100,
    offerExpiry: new Date().toJSON(),
    city: "Lahore",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum esse alias facere earum unde eius impedit possimus distinctio officiis! Provident, dolores illum. Voluptas tempora debitis in nam distinctio et iure.",
  },
  {
    id: 2,
    selected: false,
    pid: 2,
    sku: "sho-23",
    image:
      "https://cdn.pixabay.com/photo/2016/11/19/18/06/feet-1840619__340.jpg",
    title: "shoes",
    saleAmount: 120000,
    balance: 120000,
    unfilledOrders: 10,
    resellers: 10,
    price: 12000,
    type: "Accessories",
    status: "Accept",
    dailySale: 120,
    vendor: "Zaghum Abbas",
    phoneNum: "0311-7085377",
    address:
      "Abbas Written by Jon Doe Visit us at Example.com Box 564, Disneyland USA",
    collection: "Ladies Watch",
    currentQty: 105,
    qtyThreshold: 400,
    inventoryPercent: 15,
    maxReseller: 5,
    shipping: 100,
    offerExpiry: new Date().toJSON(),
    cost: 15250,
    city: "Faisalabad",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum esse alias facere earum unde eius impedit possimus distinctio officiis! Provident, dolores illum. Voluptas tempora debitis in nam distinctio et iure.",
  },
  {
    id: 3,
    selected: false,
    pid: 3,
    sku: "ti-55",
    image:
      "https://cdn.pixabay.com/photo/2016/01/13/00/41/red-1136968__340.jpg",
    title: "ties",
    saleAmount: 15000,
    balance: 15000,
    unfilledOrders: 7,
    resellers: 10,
    price: 30000,
    type: "Accessories",
    status: "Reject",
    dailySale: 120,
    vendor: "Umar Farooq",
    phoneNum: "0311-777777",
    address:
      "Umar Farooq Written by Jon Doe Visit us at Example.com Box 564, Disneyland USA",
    collection: "Men Watch",
    currentQty: 75,
    qtyThreshold: 400,
    inventoryPercent: 10,
    maxReseller: 13,
    shipping: 50,
    offerExpiry: new Date().toJSON(),
    cost: 40000,
    city: "Multan",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum esse alias facere earum unde eius impedit possimus distinctio officiis! Provident, dolores illum. Voluptas tempora debitis in nam distinctio et iure.",
  },
  {
    id: 4,
    selected: false,
    pid: 4,
    sku: "pt-72",
    image:
      "https://cdn.pixabay.com/photo/2016/11/29/09/41/bag-1868758__340.jpg",
    title: "pants",
    saleAmount: 15100,
    balance: 15100,
    unfilledOrders: 5,
    resellers: 10,
    price: 1000,
    type: "Accessories",
    status: "Accept",
    dailySale: 120,
    vendor: "Umar Farooq",
    phoneNum: "0311-7111117",
    address:
      "Umar  Written by Jon Doe Visit us at Example.com Box 564, Disneyland USA",
    collection: "Jeans Shirt",
    currentQty: 25,
    qtyThreshold: 100,
    inventoryPercent: 10,
    maxReseller: 9,
    shipping: 150,
    offerExpiry: new Date().toJSON(),
    cost: 1750,
    city: "islamabad",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum esse alias facere earum unde eius impedit possimus distinctio officiis! Provident, dolores illum. Voluptas tempora debitis in nam distinctio et iure.",
  },
  {
    id: 5,
    selected: false,
    pid: 5,
    sku: "pt-72",
    image:
      "https://cdn.pixabay.com/photo/2016/11/29/09/41/bag-1868758__340.jpg",
    title: "pants",
    saleAmount: 1000,
    balance: 1000,
    unfilledOrders: 10,
    resellers: 10,
    price: 1000,
    type: "Accessories",
    status: "Accept",
    dailySale: 120,
    vendor: "Umar Farooq",
    phoneNum: "0311-7085377",
    address:
      "Farooq Written by Jon Doe Visit us at Example.com Box 564, Disneyland USA",
    collection: "Jeans Shirt",
    currentQty: 25,
    qtyThreshold: 100,
    inventoryPercent: 40,
    maxReseller: 9,
    shipping: 150,
    offerExpiry: new Date().toJSON(),
    cost: 1550,
    city: "Karachi",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum esse alias facere earum unde eius impedit possimus distinctio officiis! Provident, dolores illum. Voluptas tempora debitis in nam distinctio et iure.",
  },
  {
    id: 6,
    selected: false,
    pid: 6,
    sku: "pt-72",
    image:
      "https://cdn.pixabay.com/photo/2016/11/29/09/41/bag-1868758__340.jpg",
    title: "pants",
    saleAmount: 17000,
    balance: 17000,
    unfilledOrders: 5,
    resellers: 10,
    price: 1000,
    type: "Accessories",
    status: "Reject",
    dailySale: 120,
    vendor: "Zaghum Abbas",
    address:
      "Zaghum Abbas Written by Jon Doe Visit us at Example.com Box 564, Disneyland USA",
    phoneNum: "0311-2222222",
    collection: "Jeans Shirt",
    currentQty: 25,
    qtyThreshold: 100,
    inventoryPercent: 30,
    maxReseller: 9,
    shipping: 150,
    offerExpiry: new Date().toJSON(),
    cost: 1599,
    city: "Lahore",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum esse alias facere earum unde eius impedit possimus distinctio officiis! Provident, dolores illum. Voluptas tempora debitis in nam distinctio et iure.",
  },
];
