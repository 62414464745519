import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

const ResellerOrdersTab = ({ activeTab, setActiveTab }) => {
  return (
    <div className="mobile-margin">
      <Nav
        tabs
        className="separator-tabs ml-0 mb-5 "
        style={{ cursor: "pointer" }}
      >
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "Orders",
              "nav-link": true,
            })}
            onClick={() => {
              localStorage.setItem("activeOrderResalePaymentTab", "Orders");
              setActiveTab("Orders");
            }}
            location={{}}
            to="#"
          >
            Orders
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "Pending",
              "nav-link": true,
            })}
            onClick={() => {
              localStorage.setItem("activeOrderResalePaymentTab", "Pending");
              setActiveTab("Pending");
            }}
            location={{}}
            to="#"
          >
            Pending
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "Transaction History",
              "nav-link": true,
            })}
            onClick={() => {
              localStorage.setItem(
                "activeOrderResalePaymentTab",
                "Transaction History"
              );
              setActiveTab("Transaction History");
            }}
            location={{}}
            to="#"
          >
            Transaction History
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

export default ResellerOrdersTab;
