import React, { useCallback, useEffect, useState } from "react";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import {
  Row,
  Alert,
  Spinner,
  Input,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import DataTable from "../../tables/table.jsx";
import { getOrderByDispatchStatus } from "../../../api/orders";
// import {
//   ConsigneeDetails,
//   NotePopup,
//   OrdersDetailsPopup,
// } from "../../Modals/Popups.jsx";
import { formatPhoneNumber } from "../../../helpers/phoneNumber.jsx";
import { sortByDate, DateFormatted } from "../../../helpers/dateTime";
import DateFilters from "../../Date/DateFilters.jsx";
// import { showItemDetails } from "../orderTabs/helperFunctions/tableDetailsInfo.jsx";
import { useHistory } from "react-router-dom";
import { getCookie } from "../../../helpers/cookie";
import { toast } from "react-toastify";
import { getAllStoresLoginUser } from "../../../api";
import { handleSearch } from "../../search/searchHook";
// import { rightAnimate } from "../../FramerMotion/FramerMotion";
import { popOverContent, popOverList } from "../../../popOvers/popOver";
import TableSkeleton from "../../UI/TableSkeleton";
const ManualOrders = ({ setOrderManual, orderManual, setSkeletonTab }) => {
  const [search, setSearch] = useState("");
  // const [orderDetailsData, setOrderDetailsData] = useState();
  // const [noteDetailsData, setNoteDetailsData] = useState("");
  const [manualOrders, setManualOrders] = useState([]);
  // const [dateTime, setDateTime] = useState();
  const [citySearch, setCitySearch] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [count, setCount] = useState(0);
  // const [showResetButton, setShowResetButton] = useState(false);
  // const [consigneeDetails, setConsigneeDetails] = useState();
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [itemDetailsData, setItemDetailsData] = useState();
  const history = useHistory();
  const [showStoreColumn, setShowStoreColumn] = useState(false);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [filtersLoader, setFiltersLoader] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [searchCityTrigger, setSearchCityTrigger] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);

  const loadManualOrders = useCallback(
    async ({
      page = pageNumber,
      limit = pageLimit,
      startDateIs = startDate,
      endDateIs = endDate,
      title = search.trim().length > 2 ? search : "",
      searchByCity = citySearch.trim().length > 2 ? citySearch : "",
      filterByCity = "",
    }) => {
      try {
        setEmptyStatus(false);
        const { data } = await getOrderByDispatchStatus({
          dispatchStatus: "manual",
          isAssigned: false,
          title,
          page,
          limit,
          startDateIs,
          endDateIs,
          searchByCity,
          filterByCity,
        });
        if (data) {
          setManualOrders(data.orders);
          setFiltersLoader(false);
          setLoading(false);
          // setSkeletonTab(false);
          setPaginationLoading(false);
          setCount(data.count);
        }
        if (data.count === 0) {
          setEmptyStatus(true);
        } else {
          setEmptyStatus(false);
        }
        return data.orders;
      } catch (error) {
        console.log("error found when fetch order data", error);
      }
    },
    [
      setManualOrders,
      pageNumber,
      pageLimit,
      endDate,
      searchTrigger,
      searchCityTrigger,
    ]
  );
  const totalPages = Math.ceil(count / pageLimit);

  const loadStores = useCallback(async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      const { stores } = data[0];
      stores.length > 1 && setShowStoreColumn(true);
    } catch (error) {
      toast.error("Error found when fetch stores data!");
      console.log("Error found when fetch stores data!", error);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    // setSkeletonTab(true);
  }, []);

  useEffect(() => {
    let token = getCookie();
    if (token) {
      loadStores();
      loadManualOrders({});
    } else {
      history.push("/signIn");
      toast.error("Your Session is Expired! Login Again...");
    }
    if (orderManual) {
      setOrderManual(false);
    }
  }, [orderManual, loadManualOrders, setOrderManual]);

  const resetFilters = () => {
    setFiltersLoader(true);
    setStartDate(null);
    setEndDate(null);
  };

  // const consigneeDetail = (item, i) => {
  //   let orderItems = item.slice(0, 20);
  //   let itemsLength = item.length;
  //   let hoverIndex;
  //   return (
  //     <div>
  //       {orderItems}
  //       {itemsLength > 20 && (
  //         <>
  //           {" ... "}
  //           <i
  //             className="simple-icon-info pointer"
  //             id={"consigneeDetailPopover" + hoverIndex}
  //             onMouseEnter={() => {
  //               hoverIndex = i;
  //               setConsigneeDetails([item]);
  //             }}
  //             onMouseLeave={() => {
  //               hoverIndex = undefined;
  //               setConsigneeDetails();
  //             }}
  //           />
  //         </>
  //       )}
  //       {consigneeDetails && (
  //         <ConsigneeDetails
  //           consigneeDetails={consigneeDetails}
  //           index={hoverIndex}
  //         />
  //       )}
  //     </div>
  //   );
  // };
  // const orderDetails = (items) => {
  //   let orderItems = items[0].title.slice(0, 30);
  //   let itemsLength = items[0].title.length;
  //   return (
  //     <>
  //       <div>
  //         {`${orderItems}${itemsLength > 20 ? "...." : ""}`}
  //         {itemsLength > 20 ? (
  //           <i
  //             style={{ cursor: "pointer" }}
  //             className="p-2 simple-icon-info "
  //             id="orderDetailPopover"
  //             onMouseEnter={() => {
  //               setOrderDetailsData(items);
  //             }}
  //           />
  //         ) : (
  //           ""
  //         )}
  //         <OrdersDetailsPopup
  //           orderDetailsData={orderDetailsData && orderDetailsData}
  //         />
  //       </div>
  //     </>
  //   );
  // };

  // const noteDetails = (note) => {
  //   let noteText = note.slice(0, 20);
  //   let noteLenght = note.length;
  //   return (
  //     <div>
  //       {`${noteText}${noteLenght > 20 ? "..." : ""}`}

  //       {noteLenght > 20 ? (
  //         <i
  //           style={{ cursor: "pointer" }}
  //           className="p-2 simple-icon-info "
  //           id="noteDetailPopover"
  //           onMouseEnter={() => {
  //             setNoteDetailsData(note);
  //           }}
  //         />
  //       ) : (
  //         ""
  //       )}
  //       <NotePopup noteData={noteDetailsData && noteDetailsData} />
  //     </div>
  //   );
  // };

  const ManualOrdersData =
    manualOrders &&
    manualOrders.length > 0 &&
    manualOrders.map((order, i) => ({
      id: order.orderId,
      orderId: order.name,
      name: popOverContent(`Name-${i}`, order?.shipping_address?.name),
      orderDate: DateFormatted(order.created_at, i),
      address: popOverContent(
        `Adress-${i}`,
        `${order?.shipping_address?.address1 || ""} ${
          order?.shipping_address?.address2 || ""
        }`
      ),
      phone: popOverContent(
        `Phone-${i}`,
        formatPhoneNumber(order?.shipping_address?.phone)
      ),
      note: popOverContent(`Note-${i}`, order.note),
      amount: popOverContent(
        `Total-${i}`,
        Number(order.total_price_set?.shop_money?.amount).toLocaleString()
      ),

      city: popOverContent(`City-${i}`, order?.shipping_address?.city),
      items: (
        <>
          {order?.line_items.length}
          {"  "}
          {order?.line_items.length > 0
            ? popOverList(
                `lineItem-${i}`,
                order.line_items.map((o) => `${o.title} (${o.quantity})`)
              )
            : "N/A"}
        </>
      ),
      storeName: popOverContent(
        `Store-${i}`,
        order.shipperDetail[0]?.shopDetail.name.replace(`${order?.name}-`, "")
      ),
    }));

  const cols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  const myCols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Store",
        accessor: "storeName",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  return (
    <>
      {loading ? (
        <TableSkeleton skeletonLength={10} itemsBar={3} />
      ) : emptyStatus &&
        !search.trim() &&
        !citySearch.trim() &&
        !startDate &&
        !endDate ? (
        <div
          className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
          style={{
            height: "200px",
          }}
        >
          <span>No Order Found!</span>
        </div>
      ) : (
        <Row>
          <Cols xxs="12">
            <div
              className="mb-4 px-2"
              // variants={rightAnimate}
              // initial="hidden"
              // animate="visible"
            >
              <div className="card-title mb-0">
                <div className="d-flex justify-content-between flex-wrap mt-4">
                  <div className="mb-2 me-1 position-relative">
                    <Input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => {
                        handleSearch({
                          e,
                          setPageNumber,
                          setPageLimit,
                          setFilterLoading: setFiltersLoader,
                          setSearch,
                          setSearchTrigger,
                          searchTrigger,
                        });
                      }}
                      className="rounded-3"
                      disabled={paginationLoading || filtersLoader}
                    />
                    {search.trim() && search.trim().length < 3 && (
                      <i
                        className="simple-icon-info pointer position-absolute text-danger fw-bold"
                        style={{ right: "-20px", bottom: "14px" }}
                        id={"SearchInfo"}
                      >
                        {" "}
                        <UncontrolledPopover
                          trigger="hover"
                          placement="top"
                          target={"SearchInfo"}
                        >
                          <PopoverBody className="text-center">
                            <i>Minimum 3 words required!</i>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </i>
                    )}
                    {search &&
                      (filtersLoader && search.trim().length > 2 ? (
                        <Spinner
                          color="primary"
                          size={"sm"}
                          style={{ right: "10px", bottom: "14px" }}
                          className=" position-absolute"
                          type="grow"
                        ></Spinner>
                      ) : (
                        <span
                          className="far fa-close fs-6 position-absolute text-danger pointer"
                          style={{ right: "10px", bottom: "14px" }}
                          onClick={() => {
                            if (search) {
                              setSearch("");
                              setSearchTrigger(!searchTrigger);
                              setFiltersLoader(true);
                            }
                            pageLimit > 50 && setPageLimit(50);
                            pageNumber > 1 && setPageNumber(1);
                          }}
                        ></span>
                      ))}
                  </div>
                  <div className="me-1">
                    <DateFilters
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      selectedDateFilter={selectedDateFilter}
                      setSelectedDateFilter={setSelectedDateFilter}
                      // setShowResetButton={setShowResetButton}
                      loadOrders={loadManualOrders}
                      resetFilters={resetFilters}
                      setFiltersLoader={setFiltersLoader}
                      paginationLoading={paginationLoading}
                      filtersLoader={filtersLoader}
                      allOrdersFlag={"allOrdersFlag"}
                    />
                  </div>
                  <div className="d-flex flex-wrap alignTop">
                    <div className="mb-2 mr-2 position-relative confirm-city-search">
                      <Input
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder="Search Cities"
                        value={citySearch}
                        onChange={(e) => {
                          setEmptyStatus(false);
                          handleSearch({
                            e,
                            setPageNumber,
                            setPageLimit,
                            setFilterLoading: setFiltersLoader,
                            setSearch: setCitySearch,
                            setSearchTrigger: setSearchCityTrigger,
                            searchTrigger: searchCityTrigger,
                          });
                        }}
                        className="rounded-3"
                        disabled={paginationLoading || filtersLoader}
                      />
                      {citySearch &&
                        (filtersLoader && citySearch.trim().length > 2 ? (
                          <Spinner
                            color="primary"
                            size={"sm"}
                            style={{ right: "10px", bottom: "14px" }}
                            className=" position-absolute"
                            type="grow"
                          ></Spinner>
                        ) : (
                          <span
                            className="far fa-close fs-6 position-absolute text-danger pointer"
                            style={{ right: "10px", bottom: "14px" }}
                            onClick={() => {
                              if (citySearch) {
                                setCitySearch("");

                                setSearchCityTrigger(!searchCityTrigger);
                                setFiltersLoader(true);
                              }
                              pageLimit > 50 && setPageLimit(50);
                              pageNumber > 1 && setPageNumber(1);
                            }}
                          ></span>
                        ))}
                    </div>
                    {citySearch.trim() && citySearch.trim().length < 3 && (
                      <div className="d-flex align-items-center pb-2">
                        <i
                          className="simple-icon-info pointer text-danger fw-bold mr-2"
                          // style={{ left: '-20px', bottom: '14px' }}
                          id={"CitySearchInfo"}
                        >
                          {" "}
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target={"CitySearchInfo"}
                          >
                            <PopoverBody className="text-center">
                              <i>Minimum 3 words required!</i>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </i>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {paginationLoading && (
                <div className="mt-3 mx-3">
                  <Alert color="info">
                    <Spinner
                      color="light"
                      size={"sm"}
                      style={{ marginBottom: "3px" }}
                    ></Spinner>{" "}
                    &nbsp;
                    <span style={{ fontSize: "16px", color: "black" }}>
                      Orders Loading!
                    </span>
                  </Alert>
                </div>
              )}

              {filtersLoader ? (
                <TableSkeleton skeletonLength={10} />
              ) : manualOrders?.length === 0 ? (
                <div
                  className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                  style={{
                    height: "200px",
                  }}
                >
                  <span>No Order Matched!</span>
                </div>
              ) : (
                <DataTable
                  setPageLimitInParent={setPageLimit}
                  setPageNumberInParent={setPageNumber}
                  fetchData={loadManualOrders}
                  pageCount={totalPages}
                  columns={showStoreColumn ? myCols : cols}
                  data={ManualOrdersData.sort(sortByDate)}
                  setPaginationLoading={setPaginationLoading}
                  paginationLoading={paginationLoading}
                  totalCount={count}
                />
              )}
            </div>
          </Cols>
        </Row>
      )}
    </>
  );
};

export default ManualOrders;
