import React, { useState } from "react";
import { Breadcrumb, Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import { Link } from "react-router-dom";
import { ListForResaleProducts } from "./ListProducts";
import { ListedProducts } from "./ListedProducts";

const ProductsTabs = () => {
  const [activeTab, setActiveTab] = useState("listForResale");

  return (
    <main>
      <Row className="top-text">
        <Cols xxs="12">
          {activeTab === "listForResale" ? (
            <h1>List Products</h1>
          ) : (
            <h1>Listed Products</h1>
          )}
          <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
            <ol className="breadcrumb pt-0">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard" style={{ color: "black" }}>
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Resale Products
              </li>
            </ol>
          </Breadcrumb>
        </Cols>
      </Row>
      <Nav
        tabs
        className="separator-tabs marginL-md mb-5 "
        style={{ cursor: "pointer" }}
      >
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "listForResale",
              "nav-link": true,
            })} ${activeTab === "listForResale" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeOrderTab", "listForResale");
              setActiveTab("listForResale");
            }}
            location={{}}
            to="#"
          >
            List For Resale
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "listed",
              "nav-link": true,
            })} ${activeTab === "listed" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeOrderTab", "listed");
              setActiveTab("listed");
            }}
            location={{}}
            to="#"
          >
            Listed For Resale
          </NavLink>
        </NavItem>
      </Nav>
      <Row>
        <Col xxs="12">
          {activeTab === "listForResale" ? (
            <ListForResaleProducts />
          ) : (
            <ListedProducts />
          )}
        </Col>
      </Row>
    </main>
  );
};

export default ProductsTabs;
