import React, { useState } from "react";
import { TabContent, TabPane } from "reactstrap";
// import OrderTabs from '../../orders/DispatchOrderTabs/OrderTabs';
import OrderHeader from "../../orders/OrderHeader";
import ResellingOrderTabs from "./ResellingOrderTabs";
import ResellingConfirmedOrders from "./ResellingConfirmedOrders";
import ResellingOpenOrders from "./ResellingOpenOrders";
import ResellingPendingOrders from "./ResellingPendingOrders";
import ResellingOnholdOrders from "./ResellingOnholdOrders";
import ResellingAllOrders from "./ResellingAllOrders";
import ResellingDeliveredOrders from "./ResellingDeliveredOrders";
import ResellingShippedOrders from "./ResellingShippedOrders";
import ResellingUnAssignedOrders from "./ResellingUnAssignedOrders";
import ResellingAssignedOrders from "./ResellingAssignedOrders";
import ResellingCancelledOrders from "./ResellingCancelledOrders";
import ResellingManualOrders from "./ResellingManualOrders";

const ResellingAssignmentOrdersList = () => {
  const activeTabFromStorage = localStorage.getItem("activeExportOrderTab");

  const [activeTab, setActiveTab] = useState(
    activeTabFromStorage ? activeTabFromStorage : "AllOrders"
  );
  const [orderExported, setOrderExported] = useState(false);
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [orderPending, setOrderPending] = useState(false);
  const [orderCancelled, setOrderCancelled] = useState(false);
  const [orderFulfilled, setOrderFulfilled] = useState(false);
  const [orderManual, setOrderManual] = useState(false);

  return (
    <>
      <main>
        <OrderHeader OrdersComponent="Reselling" />
        <div
          className="table_tabs_header px-2"
          style={{
            width: "100%",
            justifyContent: "space-between",
            // display: 'flex',
          }}
        >
          <ResellingOrderTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="AllOrders">
            {activeTab === "AllOrders" ? (
              <ResellingAllOrders
                setOrderConfirmed={setOrderConfirmed}
                setOrderPending={setOrderPending}
                setOrderCancelled={setOrderCancelled}
                setOrderManual={setOrderManual}
              />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="openOrders">
            {activeTab === "openOrders" ? (
              <ResellingOpenOrders
                setOrderConfirmed={setOrderConfirmed}
                setOrderPending={setOrderPending}
                setOrderCancelled={setOrderCancelled}
                setOrderManual={setOrderManual}
              />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="pendingOrders">
            {activeTab === "pendingOrders" ? (
              <ResellingPendingOrders
                setOrderPending={setOrderPending}
                orderPending={orderPending}
                setOrderConfirmed={setOrderConfirmed}
                setOrderCancelled={setOrderCancelled}
              />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="confirmedOrders">
            {activeTab === "confirmedOrders" ? (
              <ResellingConfirmedOrders
                setOrderExported={setOrderExported}
                setOrderConfirmed={setOrderConfirmed}
                orderConfirmed={orderConfirmed}
              />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="assignedOrders">
            {activeTab === "assignedOrders" ? (
              <ResellingAssignedOrders setOrderFulfilled={setOrderFulfilled} />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="unAssignedOrders">
            {activeTab === "unAssignedOrders" ? (
              <ResellingUnAssignedOrders
                // setOrderFulfilled={setOrderFulfilled}
                setOrderExported={setOrderExported}
                orderExported={orderExported}
              />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="onholdOrders">
            {activeTab === "onholdOrders" ? (
              <ResellingOnholdOrders
                // setOrderFulfilled={setOrderFulfilled}
                setOrderExported={setOrderExported}
                orderExported={orderExported}
              />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="shippedOrders">
            {activeTab === "shippedOrders" ? (
              <ResellingShippedOrders setOrderFulfilled={setOrderFulfilled} />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="deliveredOrders">
            {activeTab === "deliveredOrders" ? (
              <ResellingDeliveredOrders
              // setOrderFulfilled={setOrderFulfilled}
              // orderFulfilled={orderFulfilled}
              />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="cancelledOrders">
            {activeTab === "cancelledOrders" ? (
              <ResellingCancelledOrders
                setOrderCancelled={setOrderCancelled}
                orderCancelled={orderCancelled}
              />
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tabId="manualOrders">
            {activeTab === "manualOrders" ? (
              <ResellingManualOrders
                setOrderManual={setOrderManual}
                orderManual={orderManual}
              />
            ) : (
              ""
            )}
          </TabPane>
        </TabContent>
      </main>
    </>
  );
};

export default ResellingAssignmentOrdersList;
