import React, { useState, useMemo } from "react";
import {
  Card,
  CardBody,
  Row,
  Input,
  CardTitle,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Form,
  FormGroup,
} from "reactstrap";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import { data } from "../data";
import DataTable from "../../tables/table";
import Offcanvas from "react-bootstrap/Offcanvas";
import { DateFormatted } from "../../../helpers/dateTime";

const PendingPayments = () => {
  const [products, setProducts] = useState(data);
  const [filterData, setFilterData] = useState(products);
  const [showFilterData, setShowFilterData] = useState([]);
  const [show, setShow] = useState(false);
  const [condenseTable, setCondenseTable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [openAcceptModel, setOpenAcceptModel] = useState(false);
  const [showProceedCanvas, setShowProceedCanvas] = useState(false);
  const [dateTime, setDateTime] = useState();

  const datas = filterData.length === 0 ? products : filterData;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const colsData = [
    {
      Header: "Date",
      accessor: "offerExpiry",
      cellClass: "text-muted w-15",

      Cell: (props) => <>{DateFormatted(props.value, props.value.id)}</>,
    },
    {
      Header: "Reseller",
      accessor: "vendor",
      cellClass: "text-muted ",
      Cell: (props) => <>{props.value === "" ? "NA" : props.value}</>,
    },
    {
      Header: "Amount",
      accessor: "saleAmount",
      cellClass: "text-muted",
      Cell: (props) => <>{numberWithCommas(props.value)}</>,
    },
    {
      Header: "Actions",
      accessor: "id",
      cellClass: "text-muted",
      Cell: (props) => (
        <>
          <span
            className="simple-icon-pencil fs-5 text-black pointer"
            onClick={() => showCanvasHandler(props.value)}
          ></span>
        </>
      ),
    },
  ];

  const cols = useMemo(() => colsData, [colsData]);

  const showCanvasHandler = (id) => {
    const filterData = products.filter((data) => data.pid === id);
    setShowFilterData(filterData);
    setShow(true);
    setCondenseTable(true);
  };

  const handleSearch = (e) => {
    if (e.target.value.length === 0) {
      setFilterData(data);
    } else {
      setFilterData(
        datas.filter((p) =>
          p.vendor.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const expandImageHandler = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <Row>
        <Cols xxs="12">
          <Card
            className={
              condenseTable ? "mb-4 w-70 transform-table-pending" : "mb-4"
            }
          >
            <CardBody>
              <CardTitle className="order_table_card_title mb-0">
                <div className="search-sm my-2 mr-2  global-search-font">
                  <Input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder="Search"
                    // value={search}
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </CardTitle>
              {filterData.length !== 0 ? (
                <DataTable
                  columns={cols}
                  data={filterData}
                  pageCount={1}
                  totalCount={data.length}
                />
              ) : (
                <div className="mt-4">
                  <div className="py-5 mt-3 text-center">
                    <h3 style={{ color: "red" }}>No Reseller Matched!</h3>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Cols>
      </Row>
      <Offcanvas
        className="canvasWidth"
        show={show}
        onHide={() => {
          setShow(false);
          setCondenseTable(false);
        }}
        placement={"end"}
        scroll={true}
        backdrop={false}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}

          <h2 className="float-right bold mt-2 px-2 mb-0">Payment Details</h2>
        </Offcanvas.Header>
        <hr className="my-0" />
        <Offcanvas.Body>
          <div className="h-100 overflow-hidden d-flex flex-column justify-content-between">
            <div
              className="mt-1 customScroll"
              style={{
                minHeight: "85%",
                maxHeight: "85%",
                overflow: "auto",
              }}
            >
              {}
              <ul className="list-unstyled">
                {showFilterData.map((data, index) => (
                  <div key={index}>
                    <li>
                      <span className="bold fs-6">Reseller:</span>{" "}
                      {numberWithCommas(data.vendor)}
                    </li>
                    <li>
                      <span className="bold fs-6">Balance Due:</span>{" "}
                      {numberWithCommas(data.saleAmount)}
                    </li>{" "}
                    <li>
                      <span className="bold fs-6">Amount:</span>{" "}
                      {numberWithCommas(data.saleAmount)}
                    </li>
                    <li className="bold fs-6">
                      Proof of Payment:
                      <img
                        className="mt-3 pointer"
                        src={data.image}
                        alt="image not found"
                        style={{ maxWidth: "100%", height: "auto" }}
                        onClick={expandImageHandler}
                      />
                    </li>
                    <li>
                      <label htmlFor="" className="mt-3">
                        <strong>Notes</strong>
                      </label>
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Dolore a eum, quod natus et similique praesentium
                        rerum quam modi distinctio?
                      </p>
                    </li>
                  </div>
                ))}
              </ul>
            </div>
            <div className="" style={{ height: "15%" }}>
              <hr className="mt-1" />
              <div className="d-flex justify-content-center">
                <Button
                  type="button"
                  className="mr-2"
                  color="danger"
                  outline
                  onClick={() => {
                    // setShow(false);
                    // setCondenseTable(false);
                    setOpenRejectModel(true);
                  }}
                >
                  Reject
                </Button>
                <Button
                  color="primary"
                  className=""
                  onClick={() => setOpenAcceptModel(true)}
                >
                  Accept
                </Button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Reject Pending Payment Modal  */}
      <div className="ModalDelist">
        <Modal
          isOpen={openRejectModel}
          toggle={() => setOpenRejectModel(false)}
        >
          <ModalHeader
            className="p-3 text-muted"
            toggle={() => setOpenRejectModel(false)}
          >
            {" "}
            {/* <strong> Delist Product </strong> */}
          </ModalHeader>
          <ModalBody className="pt-4 pb-2">
            <p>Are you sure you want to reject? </p>
            <Input type="text" placeholder="Reason" />
          </ModalBody>
          <ModalFooter className="py-2">
            <Button color="secondary" onClick={() => setOpenRejectModel(false)}>
              Cancel
            </Button>{" "}
            <Button
              color="danger"
              outline
              className=""
              type="button"
              onClick={() => {
                setShow(false);
                setCondenseTable(false);
                setOpenRejectModel(false);
                setShowProceedCanvas(false);
                setCondenseTable(false);
              }}
            >
              Reject
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <Modal
        isOpen={openAcceptModel}
        toggle={() => setOpenAcceptModel(false)}
        modalClassName="overflow-hidden"
      >
        <ModalHeader
          className="p-3 text-muted"
          toggle={() => setOpenAcceptModel(false)}
        >
          {" "}
          {/* <strong> Delist Product </strong> */}
        </ModalHeader>
        <ModalBody className="pt-4 pb-2">
          <p>Are you sure you want to accept? </p>
        </ModalBody>
        <ModalFooter className="py-2">
          <Button color="secondary" onClick={() => setOpenAcceptModel(false)}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            // outline
            className=""
            type="button"
            onClick={() => {
              setShow(false);
              setOpenAcceptModel(false);
              setShowProceedCanvas(false);
              setCondenseTable(false);
            }}
          >
            Accept
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={openModal}
        toggle={() => setOpenModal(false)}
        modalClassName="overflow-hidden"
      >
        <ModalBody className="p-0">
          {showFilterData.map((data, index) => (
            <div key={index}>
              <img src={data.image} alt="no" />
            </div>
          ))}
        </ModalBody>
      </Modal>
    </>
  );
};

export default PendingPayments;
