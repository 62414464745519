import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";
const url = getAPIURL();
let response = "";

export const getProducts = async ({
  limit,
  page,
  tabStatus,
  status,
  vendor,
  title,
}) => {
  const token = getCookie();
  const params = new URLSearchParams();
  params.append("limit", limit?.toString());
  params.append("page", page?.toString());
  params.append("tabStatus", tabStatus?.toString());
  params.append("prodStatus", status?.toString());
  params.append("vendor", vendor?.toString());
  params.append("search", title?.toString().trim());

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  try {
    response = await axios.get(`${url}/api/products`, config);
  } catch (e) {
    response = e;
  }
  return response;
};

export const getProductDetails = async (shopifyProductId) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    response = await axios.get(
      `${url}/api/products/productDetails/${shopifyProductId}`,
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

export const uploadImageToShopifyUsingBase64 = async () => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    response = await axios.get(
      `${url}/api/products/uploadImageToShopify`,
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

export const addProduct = async (data) => {
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${url}/api/products/addProduct`,
    data,
    config
  );
  return response;
};

export const deleteProduct = async ({ shopifyProductId, storeId }) => {
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const data = { shopifyProductId, storeId };
  const response = await axios.post(
    `${url}/api/products/deleteProduct`,
    data,
    config
  );
  return response;
};

export const updateProduct = async ({
  title,
  body_html,
  status,
  vendor,
  type,
  tags,
  shopifyProductId,
  storeId,
}) => {
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const data = {
    title,
    body_html,
    status,
    vendor,
    type,
    tags,
    shopifyProductId,
    storeId,
  };
  const response = await axios.put(
    `${url}/api/products/updateProductDetails`,
    data,
    config
  );
  return response;
};

export const deleteProductOptions = async (
  shopifyProductId,
  optionName,
  storeId,
  options
) => {
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const data = { shopifyProductId, optionName, storeId, options };
  const response = await axios.post(
    `${url}/api/products/deleteProductOptions`,
    data,
    config
  );
  return response;
};

export const deleteProductOptionsValues = async (
  shopifyProductId,
  optionName,
  optionValue,
  storeId,
  options
) => {
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const data = { shopifyProductId, optionName, optionValue, storeId, options };
  const response = await axios.post(
    `${url}/api/products/deleteProductOptionValues`,
    data,
    config
  );
  return response;
};

export const updateOptions = async (shopifyProductId, storeId, options) => {
  let token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const data = { shopifyProductId, storeId, options };
  const response = await axios.post(
    `${url}/api/products/addProductOptions`,
    data,
    config
  );
  return response;
};

export const getVariantsForSkuMapping = async (storeId) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    response = await axios.post(
      `${url}/api/products/getVariantsForSkuMapping`,
      { storeId: storeId },
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

export const updateVariantSKU = async (variantId, sku, storeId) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    response = await axios.put(
      `${url}/api/products/updateVariantSku`,
      { variantId: variantId, sku: sku, storeId: storeId },
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

export const updateSKUMapping = async (data) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    response = await axios.post(
      `${url}/api/products/skuMapping`,
      { mappedVariants: data },
      config
    );
  } catch (e) {
    response = e;
  }
  return response;
};

export const getMasterStoreVariants = async (storeId) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    response = await axios.post(
      `${url}/api/products/getMasterStoreVariants`,
      { storeId: storeId },
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

export const unMapVariants = async (d) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    response = await axios.patch(
      `${url}/api/products/unMapVariants`,
      { data: d },
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

export const getVariantsForInventoryUpdate = async (data) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    response = await axios.post(
      `${url}/api/products/getVariantsForInventoryUpdate`,
      { storeArray: data },
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};
