import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";

const url = getAPIURL();

export const getCurrentBillingData = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await axios.get(
    `${url}/api/billingLogs/getCurrentMonthBillingLogs`,
    config
  );
};

export const saveBillingLogs = async (storeId, current_total_price) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const data = { storeId: storeId, current_total_price: current_total_price };

  return await axios.post(`${url}/api/billingLogs/saveBilling`, data, config);
};
