import React, { useState, useCallback, useEffect } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  CardTitle,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import {
  defaultCourierCityFun,
  courierCityFun,
  getCourier,
  getUserPreferences,
  savedUpdatedCouriers,
} from "../../api/courier";
import { ErrorMessage } from "../Messages/message";

const DropdownSelectionApp = () => {
  let history = useHistory();
  const [courierCompanyName, setCourierCompanyName] = useState([]);
  const errorMessage = "";
  // const [courier, setCourier] = useState();
  const [courierLabel, setCourierLabel] = useState([]);
  const [updateCouriers, setUpdateCouriers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [courierSpinner, setCourierSpinner] = useState(false);

  const [courierLength, setCourierLength] = useState();
  const [defaultCourierDisable, setDefaultCourierDisable] = useState([]);
  const [label, setLabel] = useState([]);
  const [selectedCourierCities, setSelectedCourierCities] = useState();
  const [defaultCourierCities, setDefaultCourierCities] = useState([]);

  const loadCouriers = useCallback(async () => {
    try {
      const { data } = await getCourier();
      setCourierCompanyName(data);
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  }, []);
  const loadUserPreferences = useCallback(async () => {
    try {
      const { data } = await getUserPreferences();
      setDefaultCourierCities(data.defaultCitiesCourier);
      setSelectedCourierCities(data.courierCities);
      setCourierSpinner(false);
      setDefaultCourierDisable(Object.keys(data.defaultCitiesCourier));
      setCourierLength(data?.couriers.length);
      setLoading(false);
      // setCourier(data);
      setUpdateCouriers(data?.couriers);
      setCourierLabel(data?.couriers?.map((cor) => cor.label));
      data?.couriers?.map((cor) => setLabel(cor.label));
    } catch (error) {
      console.log("error found when fetch user data", error);
    }
  }, []);

  useEffect(() => {
    setCourierSpinner(false);
    setLoading(true);
    if (courierCompanyName.length === 0) {
      loadCouriers();
    }
    loadUserPreferences();
  }, []);

  const courierCardHandler = (courier) => {
    let temp = "";
    let obj = {};
    for (const [key, values] of Object.entries(selectedCourierCities)) {
      if (key === courier.label) {
        temp = key;
        for (const [key, vaal] of Object.entries(defaultCourierCities)) {
          obj[key] = defaultCourierCities[key].push(...values);
          return vaal;
        }
      }
    }
    setSelectedCourierCities((current) => {
      const copy = { ...current };
      delete copy[temp];
      return copy;
    });
    if (courierLabel && courierLabel?.includes(courier.label)) {
      const filterLabel = courierLabel?.filter((item) => {
        return item !== courier.label;
      });

      setCourierLabel(filterLabel);
      setUpdateCouriers(
        updateCouriers &&
          updateCouriers?.filter((item) => item.label !== courier.label)
      );
    } else {
      setCourierLabel([...courierLabel, courier.label]);
      setUpdateCouriers([...updateCouriers, courier]);
    }
  };

  const updateHandler = async () => {
    setCourierSpinner((a) => !a);

    try {
      if (
        updateCouriers?.length === courierLength &&
        courierLabel &&
        courierLabel?.includes(label)
      ) {
        setCourierSpinner(false);
        return;
      } else {
        await savedUpdatedCouriers(updateCouriers);
        loadUserPreferences();
        setCourierSpinner(false);
        toast.success("Selected courier changed successfully!");
        await courierCityFun(selectedCourierCities);
        await defaultCourierCityFun(defaultCourierCities);
      }
    } catch (err) {
      toast.error("Selected courier changed error!");
    }
  };

  const getImages = (name) => {
    if (name.label === "PostEx") {
      return (
        <>
          <img
            src="./img/companiesLogo/postEx.png"
            alt="postEx"
            className="couriersList-images"
          />
        </>
      );
    } else if (name.label === "TPL") {
      return (
        <>
          <img
            src="./img/companiesLogo/Rider.png"
            alt="tpl"
            className="couriersList-images"
          />
        </>
      );
    } else if (name.label === "Leopard") {
      return (
        <>
          <img
            src="./img/companiesLogo/LCS.png"
            alt="leopard"
            className="couriersList-images"
          />
        </>
      );
    } else if (name.label === "Swyft") {
      return (
        <>
          <img
            src="./img/companiesLogo/swyft.png"
            alt="swyft"
            className="couriersList-images"
          />
        </>
      );
    } else if (name.label === "M&P") {
      return (
        <>
          <img
            src="./img/companiesLogo/MP-Logo.png"
            alt="m&p"
            className="couriersList-images"
          />
        </>
      );
    } else if (name.label === "Trax") {
      return (
        <>
          <img
            src="./img/companiesLogo/Trax.png"
            alt="Trax"
            className="couriersList-images"
          />
        </>
      );
    }
  };

  return (
    <main>
      <div>
        <div className="mb-5">
          {errorMessage !== "" ? ErrorMessage(errorMessage) : null}
        </div>

        <Row className="justify-content-center">
          <Cols xxs="10">
            <Card>
              <CardTitle className="text-center pt-5">
                {loading ? (
                  <span className="skeleton skeleton-text w-12"></span>
                ) : (
                  <h1 className="m-0">
                    <b>Couriers List</b>
                  </h1>
                )}
              </CardTitle>
              <CardBody>
                <div className="d-flex justify-content-center my-3 gap-3 flex-wrap px-3">
                  {loading
                    ? new Array(5).fill(0).map((item, i) => {
                        return (
                          <div
                            className="skeleton skeleton-courier-card"
                            key={i + 1}
                          ></div>
                        );
                      })
                    : courierCompanyName &&
                      courierCompanyName?.map((name, i) => (
                        <div
                          style={{ width: "160px", height: "160px" }}
                          key={i + 1}
                        >
                          <Card>
                            <Input
                              disabled={defaultCourierDisable.includes(
                                name.label
                              )}
                              type="checkbox"
                              checked={courierLabel?.includes(name.label)}
                              className="courier-checkbox position-absolute"
                              onChange={() => courierCardHandler(name)}
                            />
                            <CardBody>{getImages(name)}</CardBody>
                          </Card>
                        </div>
                      ))}
                </div>
                <footer className="d-flex justify-content-center">
                  {loading ? (
                    <>
                      <div className="skeleton skeleton-button me-2 my-4"></div>
                      <div className="skeleton skeleton-button me-2 my-4"></div>
                    </>
                  ) : (
                    <>
                      <Button
                        size="sm"
                        className="btn btn-secondary py-2 my-4 me-2"
                        onClick={() => history.goBack()}
                      >
                        Go Back
                      </Button>
                      <Button
                        type="button"
                        outline
                        color="primary"
                        disabled={
                          courierSpinner ||
                          (updateCouriers?.length === courierLength &&
                            courierLabel?.includes(label))
                        }
                        size="lg"
                        className="py-2 my-4 me-2"
                        onClick={updateHandler}
                      >
                        {courierSpinner ? (
                          <Spinner size={"sm"}>Loading...</Spinner>
                        ) : (
                          "Update"
                        )}
                      </Button>
                    </>
                  )}
                </footer>
              </CardBody>
            </Card>
          </Cols>
        </Row>
      </div>
    </main>
  );
};

export default DropdownSelectionApp;
