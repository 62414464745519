import { Row, Card, CardBody, CardTitle, Col } from "reactstrap";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import icons8_mastercard from "../../../images/Billing/icons8-mastercard-60.png";
import icons8_discover_card from "../../../images/Billing/icons8-discover-card-60.png";
import icons8_american_express from "../../../images/Billing/icons8-american-express-60.png";
import icons_visa from "../../../images/Billing/icons8-visa-60.png";
import image67 from "../../../images/Billing/image67.png";
import image71 from "../../../images/Billing/image71.png";
import image72 from "../../../images/Billing/image72.png";
import Rectangle1 from "../../../images/Billing/Rectangle1.svg";
import Group_fb from "../../../images/Billing/Group_fb.svg";
import Group_whatsApp from "../../../images/Billing/Group_whatsApp.svg";
import Group_youtube from "../../../images/Billing/Group_youtube.svg";

const invoiceShow = () => {
  return (
    <>
      <main>
        <Row>
          <Cols xxs="12" className="row icon-cards-row mb-2">
            <Cols xs="12" sm="12" className="mb-4 ">
              <Card>
                <CardBody className="mx-5 text_invoice">
                  <CardTitle className="d-flex justify-content-between align-items-center">
                    <div>
                      {/* <span className="logo-single" /> */}
                      <img
                        src="../logos/black.svg"
                        alt="not found logo"
                        srcset=""
                        height="65px"
                      />
                    </div>
                    <div className="d-flex flex-column gap-2 ">
                      <h3
                        className="light_text_invoice"
                        style={{ alignSelf: "end", fontSize: "30px" }}
                      >
                        Invoice
                      </h3>
                      <h3 className="dark_text_invoice">
                        <strong>#220801-SLCLO-0003</strong>
                      </h3>
                    </div>
                  </CardTitle>
                  <div className="mb-2">
                    <img
                      src={Rectangle1}
                      alt="horizontal line not found"
                      srcset=""
                      width="100%"
                      className="mb-5"
                    />
                  </div>
                  <Row>
                    <Col xs="12" lg="3" className="text-left">
                      <h6>Billed to</h6>
                      <h6>
                        <b className="dark_text_invoice">Ahmed Jameel</b>
                      </h6>
                      <h6 style={{ lineHeight: "23px" }}>
                        www.cottonleftover.com <br /> Pakistan <br /> +92 324
                        7648146
                      </h6>
                    </Col>
                    <Col xs="12" lg="3" className="text-left">
                      <h6>Billed from</h6>
                      <h6>
                        <b className="dark_text_invoice">Shopilam</b>
                      </h6>
                      <h6 style={{ lineHeight: "23px" }}>
                        103 Regent Mall, <br />
                        Faisalabad <br />
                        +92 324 7648146
                      </h6>
                    </Col>
                    <Col xs="12" lg="2"></Col>
                    <Col
                      xs="12"
                      lg="2"
                      className="text-right light_text_invoice"
                    >
                      <h6>Invoice Date</h6>
                      <h6>Due Date</h6>
                      <h6>Payment Terms</h6>
                    </Col>
                    <Col
                      xs="12"
                      lg="2"
                      className="text-right dark_text_invoice"
                    >
                      <h6>
                        <strong>10 Aug, 2022</strong>
                      </h6>
                      <h6>
                        <strong>10 Aug, 2022</strong>
                      </h6>
                      <h6>
                        <strong>7 Days</strong>
                      </h6>
                      <h6 className="light_text_invoice mb-0">
                        Invoice of (PKR)
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div style={{ float: "right" }}>
                        <h1 className="text-right fs-2 mb-5 text-color mt-n3">
                          <strong>Rs. 24,950</strong>
                        </h1>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="py-2 px-5 mb-3"
                    style={{ backgroundColor: "#E2E2E2" }}
                  >
                    <Col lg="1"></Col>
                    <Col xs="12" lg="4">
                      <h5 className="mb-0">
                        <strong> Overview</strong>
                      </h5>
                    </Col>
                    <Col xs="12" lg="3">
                      <h5 className="mb-0">
                        <strong>Quantity</strong>
                      </h5>
                    </Col>
                    <Col xs="12" lg="3" className="text-right">
                      <h5 className="mb-0">
                        <strong>Amount</strong>
                      </h5>
                    </Col>
                    <Col lg="1"></Col>
                  </Row>
                  <Row className=" px-5">
                    <Col lg="1"></Col>
                    <Col xs="12" lg="11">
                      <h5 className="mb-1 dark_text_invoice">
                        <strong>Order Fulfillment</strong>
                      </h5>
                    </Col>
                  </Row>
                  <Row className=" px-5 mb-3">
                    <Col lg="1"></Col>
                    <Col xs="12" lg="4">
                      <p className="mb-1">Waseeh.com</p>
                      <p className="mb-1">abc.com</p>
                    </Col>
                    <Col xs="12" lg="3">
                      <p className="mb-1">355</p>
                      <p className="mb-1">1000</p>
                    </Col>
                    <Col xs="12" lg="3" className="text-right">
                      <p className="mb-1">24,000</p>
                      <p className="mb-1">11,000</p>
                    </Col>
                    <Col lg="1"></Col>
                  </Row>
                  <hr className="text-muted mb-3" />
                  <Row className=" px-5">
                    <Col lg="1"></Col>
                    <Col xs="12" lg="4">
                      <h5 className="mb-1 dark_text_invoice">
                        <strong>Reselling</strong>
                      </h5>
                    </Col>
                  </Row>
                  <Row className=" px-5">
                    <Col lg="1"></Col>
                    <Col xs="12" lg="4">
                      <p className="mb-1">abc.com</p>
                    </Col>
                    <Col xs="12" lg="3">
                      <p className="mb-1">10</p>
                    </Col>
                    <Col xs="12" lg="3" className="text-right">
                      <p className="mb-1">1,500</p>
                    </Col>
                    <Col lg="1"></Col>
                  </Row>
                  <hr className="text-muted mb-3" />
                  <Row className=" px-5">
                    <Col lg="1"></Col>
                    <Col xs="12" lg="4">
                      <h5 className="mb-1 dark_text_invoice">
                        <strong>Apps</strong>
                      </h5>
                    </Col>
                    <Col xs="12" lg="3">
                      <p className="mb-1">5</p>
                    </Col>
                    <Col xs="12" lg="3" className="text-right">
                      <p className="mb-1">1,500</p>
                    </Col>
                    <Col lg="1"></Col>
                  </Row>
                  <hr className="text-muted mb-3" />
                  <Row className=" px-5">
                    <Col lg="1"></Col>
                    <Col xs="12" lg="4">
                      <h5 className="mb-1 dark_text_invoice">
                        <strong>SMS</strong>
                      </h5>
                    </Col>
                    <Col xs="12" lg="3">
                      <p className="mb-1">1,000</p>
                    </Col>
                    <Col xs="12" lg="3" className="text-right">
                      <p className="mb-1">154</p>
                    </Col>
                    <Col lg="1"></Col>
                  </Row>
                  <hr />
                  <Row className=" px-5">
                    <Col lg="1"></Col>
                    <Col xs="12" lg="4">
                      <h5 className="mb-1 dark_text_invoice">
                        <strong>Affiliate income</strong>
                      </h5>
                    </Col>

                    <Col xs="12" lg="6" className="text-right">
                      <p className="mb-1">-1,800</p>
                    </Col>
                    <Col lg="1"></Col>
                  </Row>

                  <hr className="text-muted mb-3" />
                  <Row className=" px-5">
                    <Col lg="1"></Col>
                    <Col xs="12" lg="4">
                      <h5 className="mb-1 dark_text_invoice">
                        <strong>Credit balance</strong>
                      </h5>
                    </Col>
                    <Col xs="12" lg="6" className="text-right">
                      <p className="mb-1">-200</p>
                    </Col>
                    <Col lg="1"></Col>
                  </Row>
                  <hr className="text-muted mb-3" />
                  <Row className=" px-5" style={{ marginBottom: "120px" }}>
                    <Col lg="1"></Col>
                    <Col xs="12" lg="4">
                      <h5 className="mb-1 dark_text_invoice">
                        <strong>Notes</strong>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Mauris aliquam pulvinar dolor urna enim vitae vel.
                        Ultrices eget ut.
                      </p>
                    </Col>
                    <Col xs="12" lg="4" className="text-right pr-0 mb-5">
                      <h6>Sub Total</h6>
                      <h6>Upper Cap Discount</h6>
                      <h6 className="mb-0">GST(10%)</h6>
                      <hr
                        className="text-muted d-inline-block w-40"
                        style={{ marginBottom: "10px" }}
                      />
                      <div className="fs-4 dark_text_invoice">
                        <strong>Total</strong>
                      </div>
                      <hr className="text-muted d-inline-block w-40" />
                    </Col>
                    <Col xs="12" lg="2" className="text-right pl-0">
                      <h6>
                        <strong> Rs 4,500 </strong>
                      </h6>
                      <h6>
                        <strong> Rs 00</strong>
                      </h6>
                      <h6>
                        <strong> Rs 450</strong>
                      </h6>
                      <hr className="text-muted" />
                      <div className="fs-4 dark_text_invoice">
                        <strong>Rs 24,950</strong>
                      </div>
                      <hr className="text-muted" />
                    </Col>
                    <Col lg="1"></Col>
                  </Row>
                  <Row className="mb-5 px-5">
                    <Col xs="12" lg="5">
                      <div className="mb-3 fs-5 dark_text_invoice">
                        <strong>Pay Online</strong>
                      </div>
                      <div className="text-center mb-2">
                        <div
                          className="text-light py-2 fs-5"
                          style={{
                            background: " #652F90",
                            borderRadius: "6px",
                            // minWidth: '330px',
                          }}
                        >
                          <h3 className="my-1">Pay Now</h3>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={icons8_mastercard} alt="not found" />
                        <img src={icons_visa} alt="not found" />
                        <img src={icons8_american_express} alt="not found" />
                        <img src={icons8_discover_card} alt="not found" />
                      </div>
                    </Col>
                    <Col xs="12" lg="4" className=" pl-4">
                      <div className="mb-2 fs-5 mb-3 dark_text_invoice">
                        <strong>Bank Details</strong>
                      </div>
                      <div className="fs-6 mb-2">
                        Account Holder Name: <strong>SHOPILAM</strong>
                      </div>
                      <div className="fs-6 mb-2">
                        Account #: <strong>234435646456456</strong>
                      </div>
                      <div className="fs-6 mb-2">
                        Account Type: <strong>Current</strong>
                      </div>
                      <div className="fs-6 mb-2">
                        Bank Name: <strong>AlFalah Bank Islami</strong>
                      </div>
                    </Col>
                    <Col xs="12" lg="3" className="text-right">
                      <div className="d-flex flex-column gap-3 justify-content-between align-items-center">
                        <div className="d-flex justify-content-between gap-2 align-items-center">
                          <img
                            src={image71}
                            height="30px"
                            width="120px"
                            alt="not found"
                          />
                          <img
                            src={image72}
                            height="40px"
                            width="76px"
                            alt="not found"
                          />
                        </div>
                        <div>
                          <img
                            src={image67}
                            height="120px"
                            width="120px"
                            alt="not found"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <img
                    src={Rectangle1}
                    alt="horizontal line not found"
                    srcset=""
                    width="100%"
                    className="my-5"
                  />
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-between align-items-center my-2 px-3">
                        <div className="d-flex gap-3 align-items-center ">
                          <div className="">
                            <img src={Group_fb} alt="not found" />
                          </div>
                          <div className="">
                            <img src={Group_youtube} alt="not found" />
                          </div>
                          <div className="">
                            <img src={Group_whatsApp} alt="not found" />
                          </div>
                        </div>
                        <div className="d-flex gap-5">
                          <p className="mb-0">+92 300 8661027</p>
                          <p className="mb-0">info@shopilam.com</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Cols>
          </Cols>
        </Row>
      </main>
    </>
  );
};

export default invoiceShow;
