import React, { useCallback, useEffect, useState } from "react";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import Select from "react-select";
import DataTable from "./ResellingOrderTable";

import {
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import SpinnerButton from "../../common/SpinnerButton";
import {
  fulfillOrderItems,
  getOrderByDispatchStatus,
  updateOrderDispatchStatus,
  getCourierSlip,
} from "../../../api/orders";
import { ConsigneeDetails, OrdersDetailsPopup } from "../../Modals/Popups";
import { formatPhoneNumber } from "../../../helpers/phoneNumber";
import { sortByDate, DateFormatted } from "../../../helpers/dateTime";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DateFilters from "../../Date/DateFilters.jsx";
import { showItemDetails } from "../../orders/orderTabs/helperFunctions/tableDetailsInfo";
// import { useHistory } from 'react-router-dom';

const ResellingAssignedOrders = ({ setOrderFulfilled }) => {
  const [search, setSearch] = useState("");
  const [courierAssignedOrders, setCourierAssignedOrders] = useState([]);
  const [checkboxes, setCheckboxes] = useState({});
  const [isAllOrdersChecked, setIsAllOrdersChecked] = useState(false);
  const [selectedOrdersInBulk, setSelectedOrdersInBulk] = useState([]);
  const [assignedOrdersLoaded, setAssignedOrdersLoaded] = useState(false);
  const [orderDetailsData, setOrderDetailsData] = useState();
  const [itemDetailsData, setItemDetailsData] = useState();
  const [dateTime, setDateTime] = useState();
  const [areSelectedOrdersProcessing, setAreSelectedOrdersProcessing] =
    useState(false);
  const [areSelectedOrdersSlipPrinted, setAreSelectedOrdersSlipPrinted] =
    useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [citySearch, setCitySearch] = useState("");
  // const history = useHistory();
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [checkCounter, setCheckCounter] = useState(0);
  const [consigneeDetails, setConsigneeDetails] = useState();
  const [showResetButton, setShowResetButton] = useState(false);
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchNameBool, setSearchNameBool] = useState(false);
  const [searchCityBool, setSearchCityBool] = useState(false);
  const [selectedDispatchStatus, setSelectedDispatchStatus] = useState(null);
  let statusDispatchOptions = [
    { value: "manualOrder", label: "Manual" },
    { value: "cancelOrder", label: "Cancel" },
  ];

  // const fetchAssignedOrders = useCallback(
  //   async ({
  //     page = pageNumber,
  //     limit = pageLimit,
  //     startDateIs = startDate,
  //     endDateIs = endDate,
  //   }) => {
  //     try {
  //       setLoading(true);
  //       const { data } = await getOrderByDispatchStatus({
  //         dispatchStatus: 'assigned',
  //         isAssigned: true,
  //         limit,
  //         page,
  //         startDateIs,
  //         endDateIs,
  //       });
  //       data && setLoading(false);
  //       setTotalPagesCount(Math.ceil(data.count / limit));
  //       setCourierAssignedOrders(data.orders);
  //       setAssignedOrdersLoaded(true);
  //       return data.orders;
  //     } catch (error) {
  //       console.log(
  //         'Error occurred while fetching assigned courier orders: ',
  //         error
  //       );
  //     }
  //   },
  //   [pageNumber, pageLimit, endDate]
  // );

  useEffect(() => {
    setCourierAssignedOrders([
      {
        _id: "628e09554c50fcd10046757d",
        courier: "",
        seller: "Umar",
        sellerOrder: "121358",
        courierAssigned: false,
        dispatchStatus: "confirm",
        deliveryStatus: "not_delivered",
        storeId: "62878032582e6500dc75ac17",
        storeName: "codingstorte.myshopify.com",
        orderId: "4787435471066",
        orderRefNumber: "#1013-CodingStorte",
        cancel_reason: null,
        cancelled_at: null,
        created_at: "2022-05-25T15:47:46+05:00",
        current_total_price: "114.84",
        financial_status: "paid",
        fulfillment_status: null,
        name: "#1013",
        note: null,
        order_number: 1013,
        processed_at: "2022-05-25T15:47:46+05:00",
        subtotal_price: "99.00",
        total_price: "114.84",
        total_price_set: {
          shop_money: {
            amount: "114.84",
          },
        },
        total_weight: 0,
        updated_at: "2022-05-25T15:47:47+05:00",
        line_items: [
          {
            id: 12233094725850,
            price: "70.00",
            quantity: 1,
            sku: "AD-03-black-OS",
            title: "ADIDAS | CLASSIC BACKPACK",
          },
          {
            id: 12233094758618,
            price: "29.00",
            quantity: 1,
            sku: "VN-01-burgandy-4",
            title: "VANS |AUTHENTIC | LO PRO | BURGANDY/WHITE",
          },
        ],
        shipmentTracking: [],
        shipperDetail: [
          {
            shopDetail: {
              name: "CodingStorte",
              email: "usamariaz2844@gmail.com",
              domain: "codingstorte.myshopify.com",
              address1: "Punjab Pakistan",
              city: "Faisalabad",
              phone: "",
              country_name: "Pakistan",
            },
          },
        ],
      },
      {
        _id: "628b7c9c4c50fcd100467422",
        courier: "",
        seller: "Zaghum",
        sellerOrder: "55358",
        courierAssigned: false,
        dispatchStatus: "confirm",
        deliveryStatus: "not_delivered",
        storeId: "62878032582e6500dc75ac17",
        storeName: "codingstorte.myshopify.com",
        orderId: "4785821417690",
        orderRefNumber: "#1008-CodingStorte",
        cancel_reason: null,
        cancelled_at: null,
        created_at: "2022-05-23T17:22:48+05:00",
        current_total_price: "114.84",
        financial_status: "paid",
        fulfillment_status: null,
        name: "#1008",
        note: null,
        order_number: 1008,
        processed_at: "2022-05-23T17:22:48+05:00",
        subtotal_price: "99.00",
        total_price: "114.84",
        total_price_set: {
          shop_money: {
            amount: "114.84",
          },
        },
        total_weight: 0,
        updated_at: "2022-05-23T17:22:49+05:00",
        line_items: [
          {
            id: 12229918916826,
            price: "70.00",
            quantity: 1,
            sku: "AD-03-black-OS",
            title: "ADIDAS | CLASSIC BACKPACK",
          },
          {
            id: 12229918949594,
            price: "29.00",
            quantity: 1,
            sku: "VN-01-burgandy-4",
            title: "VANS |AUTHENTIC | LO PRO | BURGANDY/WHITE",
          },
        ],
        shipmentTracking: [],
        shipperDetail: [
          {
            shopDetail: {
              name: "CodingStorte",
              email: "usamariaz2844@gmail.com",
              domain: "codingstorte.myshopify.com",
              address1: "Punjab Pakistan",
              city: "Faisalabad",
              phone: "",
              country_name: "Pakistan",
            },
          },
        ],
      },
    ]);

    // to run useEffect on orderExported=true again, set to false after data is fetched
    // if (orderExported && courierAssignedOrders.length > 0) {
    //     setOrderExported(false);
    // }
  }, []);

  const resetFilters = async () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedDateFilter(null);
    setAssignedOrdersLoaded(true);
    // await fetchAssignedOrders({});
  };

  const onCheckItem = (event, orderId) => {
    setCheckboxes({ ...checkboxes, [orderId]: event.target.checked });
    // add checked order to list of selected orders.
    if (event.target.checked) {
      setSelectedOrdersInBulk([
        ...selectedOrdersInBulk,
        courierAssignedOrders.find((order) => order.orderId === orderId),
      ]);
    } else {
      if (selectedOrdersInBulk.length > 0) {
        const removeUnCheckedOrder = selectedOrdersInBulk.filter(
          (order) => order.orderId !== orderId
        );
        setSelectedOrdersInBulk(removeUnCheckedOrder);
      }
    }
    if (event.target.checked && orderId) {
      setCheckCounter(checkCounter + 1);
    } else if (!event.target.checked) {
      setCheckCounter(checkCounter - 1);
    }
  };

  const onCheckAllOrders = (e, ordersToMark) => {
    setIsAllOrdersChecked(e.target.checked);
    const orderIdsToMark = {};
    const ordersCompleteData = [];
    ordersToMark.forEach((order) => {
      orderIdsToMark[order.id] = e.target.checked;
      const matchedOrderInAllOrders = courierAssignedOrders.find(
        (completeOrder) => completeOrder.orderId === order.id
      );
      ordersCompleteData.push({ ...matchedOrderInAllOrders });
    });

    // add checked order to list of selected orders.
    if (e.target.checked) {
      setCheckboxes(orderIdsToMark);
      setSelectedOrdersInBulk(ordersCompleteData);
      setCheckCounter(ordersCompleteData.length);
    }
    // remove on uncheck
    else {
      if (selectedOrdersInBulk.length > 0) {
        setCheckboxes({});
        setSelectedOrdersInBulk([]);
        setCheckCounter(selectedOrdersInBulk.length);
      }
    }
    if (!e.target.checked) {
      return setCheckCounter(0);
    }
  };

  // const handleOrdersFulfillment = async () => {
  //   if (Object.keys(checkboxes).length > 0) {
  //     try {
  //       setAreSelectedOrdersProcessing(true);

  //       const fulfilledOrders = [];
  //       for (const order of selectedOrdersInBulk) {
  //         const storeId = order.storeId;
  //         const orderId = order.orderId;
  //         const unFulfilledItemIdsAndQty = order.line_items.map((item) => ({
  //           id: item.id,
  //           quantity: item.fulfillable_quantity,
  //         }));

  //         const { data, status: statusCode } = await fulfillOrderItems(
  //           unFulfilledItemIdsAndQty,
  //           orderId,
  //           storeId
  //         );
  //         if (data.status === 'success' && statusCode === 201) {
  //           const orderDispatchStatusUpdateResult =
  //             await updateOrderDispatchStatus({
  //               orderId,
  //               dispatchStatus: 'fulfilled',
  //               storeId,
  //             });
  //           if (orderDispatchStatusUpdateResult.status === 200) {
  //             // toggle exportedOrder=true, so assignedOrder tab can fetch data automatically
  //             setOrderFulfilled(true);
  //             fulfilledOrders.push(order.orderId);
  //           }
  //         }
  //         toast.success(`Successfully fulfilled order#${order.order_number}`);
  //       }
  //       const remainingAssignedOrders = courierAssignedOrders.filter(
  //         (assignedOrder) => !fulfilledOrders.includes(assignedOrder.orderId)
  //       );
  //       setCourierAssignedOrders(remainingAssignedOrders);
  //       setSelectedOrdersInBulk([]);
  //       setAreSelectedOrdersProcessing(false);
  //       setCheckboxes({});
  //     } catch (e) {
  //       toast.error(`Error while handleOrdersFulfillment: ${e.message}`);
  //     }
  //   } else {
  //     toast.info('Please Select orders before fulfilled labels');
  //   }
  // };

  const consigneeDetail = (item) => {
    let orderItems = item.slice(0, 12);
    let itemsLength = item.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 12 ? "...." : ""}`}
          {itemsLength > 12 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="consigneeDetailPopover"
              onMouseEnter={() => {
                setConsigneeDetails([item]);
              }}
            />
          ) : (
            ""
          )}

          <ConsigneeDetails
            consigneeDetails={consigneeDetails && consigneeDetails}
          />
        </div>
      </>
    );
  };

  const orderDetails = (items) => {
    let orderItems = items[0].title.slice(0, 12);

    let itemsLength = items[0].title.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 12 ? "...." : ""}`}
          {itemsLength > 12 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="orderDetailPopover"
              onMouseEnter={() => {
                setOrderDetailsData(items);
              }}
            />
          ) : (
            ""
          )}
          <OrdersDetailsPopup
            orderDetailsData={orderDetailsData && orderDetailsData}
          />
        </div>
      </>
    );
  };

  // const orderPrintSlipFunction = async () => {
  //   if (Object.keys(checkboxes).length > 0) {
  //     setAreSelectedOrdersSlipPrinted(true);
  //     try {
  //       for (const order of selectedOrdersInBulk) {
  //         if (order.shipmentTracking.length === 0) {
  //           toast.error(
  //             `Tracking Number not found for order#${order.order_number}`
  //           );
  //           setCheckCounter(0);
  //         } else {
  //           const orderRefNumber = order.orderRefNumber;
  //           const trackingNumber = order.shipmentTracking[0].trackingNumber;
  //           const courierCompany = order.courier;
  //           const deliveryType = order.shipmentTracking[0].companyName;
  //           const sku = order.line_items[0].sku;
  //           const id = order.line_items[0].id;
  //           const { data } = await getCourierSlip({
  //             orderRefNumber,
  //             trackingNumber,
  //             courierCompany,
  //             deliveryType,
  //             sku,
  //             id,
  //           });
  //           const link = document.createElement('a');
  //           link.setAttribute(
  //             'href',
  //             'data:"application/pdf;base64,' + data.base64PDF
  //           );
  //           link.setAttribute(
  //             'download',
  //             `${order.order_number}-${
  //               order.storeName.split('.')[0]
  //             }-${deliveryType}.pdf`
  //           );
  //           document.body.appendChild(link);
  //           link.click();
  //           document.body.removeChild(link);
  //           toast.success(
  //             `Successfully download label for order#${order.order_number}`
  //           );
  //           setCheckCounter(0);
  //         }
  //       }
  //       setAreSelectedOrdersSlipPrinted(false);
  //       setSelectedOrdersInBulk([]);
  //       setIsAllOrdersChecked(false);
  //       setCheckboxes(false);
  //     } catch (error) {
  //       toast.error(`${error}`);
  //       setAreSelectedOrdersSlipPrinted(false);
  //       setIsAllOrdersChecked(false);
  //       setCheckboxes(false);
  //       setCheckCounter(0);
  //     }
  //   } else {
  //     toast.info('Please Select orders before download labels');
  //   }
  // };

  const assignedOrdersData =
    courierAssignedOrders.length > 0 &&
    courierAssignedOrders
      .filter((order) => {
        if (search === "") {
          return order;
        } else if (order.hasOwnProperty("shipping_address")) {
          return order.shipping_address.name
            ? order.shipping_address.name
                .toLowerCase()
                .includes(search.toLowerCase())
            : "";
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.address1
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return order;
        } else if (
          order && order?.shipping_address?.phone
            ? formatPhoneNumber(order?.shipping_address?.phone)
                .toLowerCase()
                .includes(search.toLowerCase())
            : "N/A"
        ) {
          return order;
        } else if (order.name.toLowerCase().includes(search.toLowerCase())) {
          return order;
        }
        return false;
      })
      .map((order) => ({
        id: order.orderId,
        name: order.shipping_address ? order.shipping_address.name : "N/A",
        orderDate: DateFormatted(order.created_at, setDateTime, dateTime),
        address: order?.shipping_address?.address1
          ? consigneeDetail(order?.shipping_address?.address1)
          : "N/A",
        phone: order?.shipping_address?.phone
          ? formatPhoneNumber(order?.shipping_address?.phone)
          : "N/A",
        tracking:
          order.shipmentTracking.length > 0
            ? order.shipmentTracking.map((shipment) =>
                order.shipmentTracking.length > 1
                  ? `${shipment.trackingNumber}/`
                  : `${shipment.trackingNumber}`
              )
            : "N/A",
        // weight: order.total_weight,
        amount: Math.trunc(
          order.total_price_set.shop_money.amount
        ).toLocaleString(),
        referenceNo: order.orderId,
        details: orderDetails(order.line_items),
        remarks: order.note ? order.note : "none",
        orderId: order.name,
        seller: order.seller,
        sellerOrder: order.sellerOrder,
        city: order.shipping_address ? order.shipping_address.city : "N/A",
        items:
          order.line_items && order.line_items.length > 0
            ? showItemDetails(
                order.line_items,
                setItemDetailsData,
                itemDetailsData
              )
            : "N/A",
        courier: order.courier,
        storeName: order.shipperDetail[0].shopDetail.name,
      }));

  const cityOrderData =
    courierAssignedOrders.length > 0 &&
    courierAssignedOrders
      .filter((order) => {
        if (citySearch === "") {
          return order;
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.city &&
          order.shipping_address.city
            .toLowerCase()
            .includes(citySearch.toLowerCase())
        ) {
          return order;
        }
        return false;
      })
      .map((order) => ({
        id: order.orderId,
        name: order.shipping_address ? order.shipping_address.name : "N/A",
        orderDate: DateFormatted(order.created_at, setDateTime, dateTime),
        address: order?.shipping_address?.address1
          ? consigneeDetail(order?.shipping_address?.address1)
          : "N/A",
        phone: order?.shipping_address?.phone
          ? formatPhoneNumber(order?.shipping_address?.phone)
          : "N/A",
        // weight: order.total_weight,
        tracking:
          order.shipmentTracking.length > 0
            ? order.shipmentTracking.map((shipment) =>
                order.shipmentTracking.length > 1
                  ? `${shipment.trackingNumber}/`
                  : `${shipment.trackingNumber}`
              )
            : "N/A",
        amount: Math.trunc(
          order.total_price_set.shop_money.amount
        ).toLocaleString(),
        referenceNo: order.orderId,
        details: orderDetails(order.line_items),
        remarks: order.note ? order.note : "none",
        orderId: order.name,
        seller: order.seller,
        sellerOrder: order.sellerOrder,
        city: order.shipping_address ? order.shipping_address.city : "N/A",
        items:
          order.line_items && order.line_items.length > 0
            ? showItemDetails(
                order.line_items,
                setItemDetailsData,
                itemDetailsData
              )
            : "N/A",
        courier: order.courier,
        storeName: order.shipperDetail[0].shopDetail.name,
      }));

  const cols = React.useMemo(
    () => [
      {
        Header: "Order#",
        accessor: "orderId",
        cellClass: "table-text w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "COD",
        accessor: "amount",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      // {
      //   Header: 'Product Details',
      //   accessor: 'details',
      //   cellClass: 'table-text w-6',
      //   Cell: (props) => <>{props.value}</>,
      // },
      {
        Header: "Items(Count Only)",
        accessor: "items",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Seller",
        accessor: "seller",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "SellerOrder#",
        accessor: "sellerOrder",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Store",
        accessor: "storeName",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      // {
      //     Header: "Actions",
      //     accessor: "actions",
      //     cellClass: "text-muted w-6",
      //     Cell: ({cell: {row}}) =>
      //         <Button
      //             value="Confirm"
      //             onClick={(e) => handleOrderDispatchStatus(e, row)}>Confirm</Button>
      // },
      // {
      //   Header: 'Actions',
      //   accessor: 'actions',
      //   cellClass: 'table-text w-3',
      //   Cell: (props) => <>{props.value}</>,
      // },
    ],
    []
  );

  const moreActions = (e) => {
    setSelectedDispatchStatus(e.value);
  };
  return (
    <>
      {assignedOrdersLoaded && courierAssignedOrders.length <= 0 ? (
        <Card className="my-3 py-5">
          <CardBody
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h4 style={{ color: "red" }}>
              <b>No Order Assigned Yet!</b>
            </h4>
            {showResetButton === false ? (
              ""
            ) : (
              <Button
                color="primary"
                className="mt-1 btn-sm"
                onClick={resetFilters}
              >
                Reset Data
              </Button>
            )}
          </CardBody>
        </Card>
      ) : !assignedOrdersLoaded && courierAssignedOrders.length === 0 ? (
        <>
          <div className="loading" />
          <div
            style={{
              height: "70vh",
            }}
          ></div>
        </>
      ) : (
        <Row>
          <Cols xxs="12">
            <Card className="mb-4">
              <CardBody>
                <CardTitle>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className="container"
                  >
                    <div className="search-sm mb-2 global-search-font">
                      <input
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          if (e) {
                            setSearch(e.target.value);
                            setSearchNameBool(true);
                          } else {
                            setSearchNameBool(false);
                          }
                        }}
                      />
                    </div>
                    <div>
                      <DateFilters
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        selectedDateFilter={selectedDateFilter}
                        setSelectedDateFilter={setSelectedDateFilter}
                        setShowResetButton={setShowResetButton}
                        // loadOrders={fetchAssignedOrders}
                        resetFilters={resetFilters}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="city_search_container  mb-2 ml-3"
                    >
                      <div className="search-sm d-inline-block confirm-city-search">
                        <input
                          type="text"
                          name="keyword"
                          id="city_search"
                          placeholder="Search City"
                          value={citySearch}
                          onChange={(e) => {
                            if (e) {
                              setSearchCityBool(true);
                              setCitySearch(e.target.value);
                            } else {
                              setSearchCityBool(false);
                            }
                          }}
                        />
                      </div>
                      {/* <SpinnerButton
                        btnText="Fulfill Orders"
                        isProcessing={areSelectedOrdersProcessing}
                        handler={handleOrdersFulfillment}
                      /> */}
                      <div
                        className="openOrderSelect ml-2 mr-2"
                        style={{ width: "140px" }}
                      >
                        <Select
                          placeholder="Actions"
                          defaultValue={selectedDispatchStatus}
                          options={statusDispatchOptions}
                          isDisabled={
                            selectedOrdersInBulk.length >= 1 ? false : true
                          }
                          onChange={(e) => moreActions(e)}
                        />
                      </div>

                      <SpinnerButton
                        btnText={
                          selectedDispatchStatus === "Download Label"
                            ? "Download Label"
                            : "Submit Orders"
                        }
                        isProcessing={areSelectedOrdersSlipPrinted}
                        // handler={
                        //   selectedDispatchStatus === 'Download Label'
                        //     ? orderPrintSlipFunction
                        //     : handleOrdersFulfillment
                        // }
                      />
                    </div>
                  </div>
                </CardTitle>
                {loading && (
                  <div className="mx-2" style={{ marginBottom: "-5px" }}>
                    <Alert color="success">
                      <Spinner
                        color="success"
                        size={"sm"}
                        style={{ marginBottom: "2px" }}
                      ></Spinner>{" "}
                      &nbsp;
                      <span style={{ fontSize: "15px" }}>Orders Loading!</span>
                    </Alert>
                  </div>
                )}

                {((cityOrderData.length === 0 && searchCityBool) ||
                  (assignedOrdersData.length === 0 && searchNameBool)) && (
                  <h4
                    style={{
                      color: "red",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <b>No Order Matched!</b>
                  </h4>
                )}

                {cityOrderData.length !== 0 &&
                  assignedOrdersData.length !== 0 && (
                    <DataTable
                      setPageLimitInParent={setPageLimit}
                      setPageNumberInParent={setPageNumber}
                      // fetchData={fetchAssignedOrders}
                      pageCount={totalPagesCount}
                      columns={cols}
                      data={
                        citySearch !== ""
                          ? cityOrderData.sort(sortByDate)
                          : assignedOrdersData.sort(sortByDate)
                      }
                      onCheckItem={onCheckItem}
                      checkedOrders={checkboxes}
                      isAllOrdersChecked={isAllOrdersChecked}
                      onCheckAllOrders={onCheckAllOrders}
                      checkCounter={checkCounter}
                    />
                  )}
              </CardBody>
            </Card>
          </Cols>
        </Row>
      )}
    </>
  );
};

export default ResellingAssignedOrders;
