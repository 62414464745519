import React, { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  PopoverBody,
  Row,
  Spinner,
  UncontrolledPopover,
} from 'reactstrap';
import {
  createResellingProducts,
  getAvialableProducts,
} from '../../../api/resellingProducts.js';
import DataTable from '../../tables/table';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Cols } from '../../SeparatorStyle/SeparatorStyle';
import { Link } from 'react-router-dom';
import { ResellerProductsFilter } from './AvailableProductsFilter';
import Select from 'react-select';
import { getAllAccountsInfo } from '../../../api/sellingProducts.js.js';
import ReactPaginate from 'react-paginate';

export const AvailableForResellingProducts = () => {
  let defaultResaleData = {
    sellerPid: '',
    // sellerStoreId: '',
    resellingSKU: '',
    resellingPrice: '',
    publishStatus: 'active',
    resellingInventory: '',
  };

  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchTrigger, setSearchTrigger] = useState(false);

  const [paginationLoading, setPaginationLoading] = useState(false);
  const [vendorFilterValue, setVendorFilterValue] = useState('');

  const [emptyStatus, setEmptyStatus] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [allVendors, setAllVendors] = useState([]);

  const [total, setTotal] = useState('');
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [masterCheck, setMasterCheck] = useState(false);
  const [proceedTo, setProceedTo] = useState(false);
  const [publishResellingLoading, setPublishResellingLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountFilterValue, setAccountFilterValue] = useState([]);
  const [userStores, setUserStores] = useState([]);
  const [storeFilterValue, setStoreFilterValue] = useState([]);

  const [resellingData, setResellingData] = useState(defaultResaleData);

  const handlePageClick = (event) => {
    const newOffset = event.selected;

    if (pageNumber - 1 !== newOffset) {
      window.scrollTo(0, 0);
      setPaginationLoading(true);

      setPageNumber(Number(newOffset) + 1);
    }
  };

  let totalPagesList = Math.ceil(total / pageLimit);

  const toTitleCase = (str) => {
    let replaced = str?.replace(
      /\w\S*/g,
      (txt) => txt?.charAt(0).toUpperCase() + txt?.substr(1).toLowerCase()
    );
    return replaced;
  };

  const loadAvailableProducts = useCallback(
    async ({
      limit = pageLimit,
      page = pageNumber,
      title = searchValue,
      accountUser = accountFilterValue?.map((obj) => obj.value.accountId),
      storeFilter = storeFilterValue?.map((obj) => obj.value),
      vendor = vendorFilterValue,
    }) => {
      setMasterCheck(false);
      setSelectedList([]);
      setShowTable(false);
      try {
        const { data } = await getAvialableProducts({
          limit,
          page,
          title,
          accountUser,
          storeFilter,
          vendor,
        });

        if (data) {
          setProducts(data?.products);
          setTotal(data?.totalCount);

          setLoading(false);
          setFilterLoading(false);
          setPaginationLoading(false);

          data.totalCount === 0 &&
          !searchValue &&
          accountFilterValue.length === 0 &&
          storeFilterValue.length === 0
            ? setEmptyStatus(true)
            : setEmptyStatus(false);
        }
      } catch (err) {
        toast.error('Available Products loading error!');
      }
    },
    [
      pageLimit,
      pageNumber,
      searchValue,
      accountFilterValue,
      storeFilterValue,
      vendorFilterValue,
    ]
  );

  const loadAllAccounts = useCallback(async () => {
    try {
      const { data } = await getAllAccountsInfo();
      if (data) {
        setAccounts(
          data
            .filter((a) => a.userName[0]?.firstname && a.userName[0]?.lastname)
            .map((obj) => {
              return {
                value: { accountId: obj?._id, stores: obj?.stores },
                label:
                  obj.userName[0]?.firstname + ' ' + obj.userName[0]?.lastname,
              };
            })
        );
      }
    } catch (error) {
      toast.error('Sellers loading error!');
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    loadAllAccounts();
  }, []);

  useEffect(() => {
    loadAvailableProducts({});
  }, [loadAvailableProducts]);

  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const onItemCheck = (item, e, ind) => {
    let tempList = products;
    tempList.map((obj, index) => {
      if (index === ind) {
        obj.selected = e.target.checked;
      }
      return obj;
    });
    setSelectedList(tempList.filter((e) => e.selected));
  };
  const [showTable, setShowTable] = useState(false);

  const handleSelect = (product, e, ind) => {
    let tempList = products;
    tempList.map((obj, index) => {
      if (index === ind) {
        obj.selected = e.target.checked;
      } else {
        obj.selected = false;
      }
      return obj;
    });
    setSelectedList(tempList.filter((e) => e.selected));
    tempList.some((e) => e.selected) ? setShowTable(true) : setShowTable(false);
    setResellingData({
      ...defaultResaleData,
      resellingSKU: product?.variant[0]?.sku,
      resellingInventory: product?.sellingData[0]?.inventory,
    });
  };

  const handleMasterCheck = (e) => {
    // let tempList = products;
    setMasterCheck(e.target.checked);
    // tempList.map((obj) => (obj.selected = e.target.checked));
    // setSelectedList(tempList.filter((e) => e.selected));
  };

  const handleCloseSideBar = () => {
    let tempList = products;
    setMasterCheck(false);
    tempList.map((obj) => (obj.selected = false));
    setSelectedList([]);
    setShowTable(false);
  };

  const blockInvalidChar = (e) => {
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  };

  const getUnique = (array) => {
    let uniqueArray = [];

    // Loop through array values
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  };

  const vendorOptions = getUnique(allVendors.map((p) => p.vendor)).map((p) => {
    return { value: p, label: p };
  });

  const handleResaleData = (e) => {
    let { name, value } = e.target;
    setResellingData({ ...resellingData, [name]: value });
  };

  const handleCreateProductsForReselling = async (product) => {
    setPublishResellingLoading(true);
    try {
      const response = await createResellingProducts(product);
      if (response.status === 201) {
        toast.success('Product published for reselling successfully!');
        setPublishResellingLoading(false);
        handleCloseSideBar();

        setLoading(true);
        setPageLimit(50);
        setPageNumber(1);
        loadAvailableProducts({});
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      setPublishResellingLoading(false);
      toast.error('Internal server error!');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let resellingProducts = selectedList.map((s) => {
      return {
        ...defaultResaleData,
        sellerPid: s._id,
        resellingSKU: resellingData.resellingSKU,
        resellingPrice: resellingData.resellingPrice,
        publishStatus: resellingData.publishStatus,
        resellingInventory: resellingData.resellingInventory,
      };
    });

    handleCreateProductsForReselling(resellingProducts[0]);
  };

  const ListForResaleRows =
    products &&
    products?.map((product, index) => {
      return {
        check: (
          <label className="custom-control mb-0 pointer">
            <Input
              className="item-check pointer"
              style={{
                border: '0.1px solid gray',
              }}
              type="checkbox"
              checked={product?.selected}
              label=""
              disabled={paginationLoading || filterLoading}
              onChange={(e) => handleSelect(product, e, index)}
            />
          </label>
        ),
        image: (
          <img
            src={product?.image?.src || './img/products/no-image.png'}
            alt=""
            className="listingImage"
          />
        ),
        sku:
          (
            <>
              {toTitleCase(String(product?.variant[0]?.sku))?.slice(0, 20)}
              {product?.variant[0]?.sku.length > 20 && ' ...'}
              {product?.variant[0]?.sku.length > 20 && (
                <i className="ms-2 simple-icon-info pointer" id={'sku' + index}>
                  <UncontrolledPopover
                    trigger="hover"
                    placement="top"
                    target={'sku' + index}
                  >
                    <PopoverBody className="text-center">
                      {product?.variant[0]?.sku}
                    </PopoverBody>
                  </UncontrolledPopover>
                </i>
              )}
            </>
          ) || 'N/A',
        productName:
          (
            <>
              {toTitleCase(product?.title)?.slice(0, 20)}
              {product?.title.length > 20 && ' ... '}
              {product?.title.length > 20 && (
                <i
                  className=" simple-icon-info pointer"
                  id={'Tooltip-' + index}
                >
                  <UncontrolledPopover
                    trigger="hover"
                    placement="top"
                    target={'Tooltip-' + index}
                  >
                    <PopoverBody className="text-center">
                      {product.title}
                    </PopoverBody>
                  </UncontrolledPopover>
                </i>
              )}
            </>
          ) || 'N/A',

        status: toTitleCase(product.status || 'N/A'),

        saleCount: 'N/A',
        type: product.type || 'N/A',
        collection: product.category || 'N/A',
        storePrice: product.sellingData[0]?.discount || 'N/A',
        currentQuantity: product?.sellingData[0]?.inventory || 'N/A',
        dailySale: 'N/A',
        seller: product?.sellingData[0]?.seller[0]?.userName || 'N/A',
      };
    });

  let ListedForResaleCols = [
    {
      Header: (
        <label className="custom-control mb-0 pointer">
          <Input
            disabled={paginationLoading || filterLoading}
            checked={masterCheck}
            // onChange={(e) => handleMasterCheck(e)}
            type="checkbox"
            className="item-check pointer"
            style={{
              border: '0.1px solid gray',
            }}
          />
        </label>
      ),
      accessor: 'check',

      Cell: (props) => props?.value,
      cellClass: 'w-1',
    },
    {
      Header: 'Image',
      accessor: 'image',
      cellClass: 'text-muted w-5',
      Cell: (props) => props?.value,
    },
    {
      Header: 'SKU',
      accessor: 'sku',
      cellClass: 'w-15',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Name',
      accessor: 'productName',
      cellClass: 'w-15',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Status',
      accessor: 'status',
      cellClass: 'w-10',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Type',
      accessor: 'type',
      cellClass: 'w-10',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Collection',
      accessor: 'collection',
      cellClass: 'w-12',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Store Price',
      accessor: 'storePrice',
      cellClass: 'w-10',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Current Qty',
      accessor: 'currentQuantity',
      cellClass: 'w-10',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Daily Sale',
      accessor: 'dailySale',
      cellClass: 'w-12',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Seller',
      accessor: 'seller',
      cellClass: 'w-12',
      Cell: (props) => props?.value,
    },
  ];

  const ListedForResaleColsMemo = useMemo(
    () => ListedForResaleCols,
    [masterCheck, products, paginationLoading, filterLoading]
  );

  return (
    <main>
      <Row className="top-text">
        <Cols xxs="12">
          <h1>Available Products</h1>
          <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
            <ol className="breadcrumb pt-0">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard" style={{ color: 'black' }}>
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Available Products
              </li>
            </ol>
          </Breadcrumb>
        </Cols>
      </Row>

      {loading ? (
        <div className=" position-relative" style={{ height: '60vh' }}>
          <span className=" loading position-absolute"></span>
        </div>
      ) : emptyStatus ? (
        <Card>
          <div
            className=" d-flex align-items-center justify-content-center text-danger"
            style={{ height: '30vh' }}
          >
            <h3 className="fw-bold">No Products Found!</h3>
          </div>
        </Card>
      ) : !showTable ? (
        <>
          {
            <ResellerProductsFilter
              setPageLimit={setPageLimit}
              setPageNumber={setPageNumber}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              setSearchTrigger={setSearchTrigger}
              searchTrigger={searchTrigger}
              vendorOptions={vendorOptions}
              vendorFilterValue={vendorFilterValue}
              setVendorFilterValue={setVendorFilterValue}
              setFilterLoading={setFilterLoading}
              setEmptyStatus={setEmptyStatus}
              filterLoading={filterLoading}
              accounts={accounts}
              accountFilterValue={accountFilterValue}
              setAccountFilterValue={setAccountFilterValue}
              userStores={userStores}
              setUserStores={setUserStores}
              setStoreFilterValue={setStoreFilterValue}
              storeFilterValue={storeFilterValue}
            />
          }
          {filterLoading ? (
            <div className=" position-relative" style={{ height: '180px' }}>
              <span className=" position-absolute loading"></span>
            </div>
          ) : products.length === 0 ? (
            <Card>
              <div
                className=" d-flex align-items-center justify-content-center text-danger"
                style={{ height: '30vh' }}
              >
                <h3 className="fw-bold">No Products Matched!</h3>
              </div>
            </Card>
          ) : (
            <>
              <div className="row">
                {paginationLoading ? (
                  <div
                    className=" position-relative"
                    style={{ height: '200px' }}
                  >
                    <span
                      className=" position-absolute loading"
                      style={{ height: '40px', width: '40px' }}
                    ></span>
                  </div>
                ) : (
                  products.map((data, i) => (
                    <div key={i} className="col-xs-6 col-lg-3 col-12 mb-4">
                      <div className="card">
                        <div className="position-relative p-5">
                          <img
                            className="card-img-top"
                            height={170}
                            src={
                              data?.image?.src || './img/products/no-image.png'
                            }
                            alt=""
                          />
                        </div>
                        <div className="card-body">
                          <div className="w-100 d-inline-block mb-3">
                            <b className="list-item-heading">
                              {data.title?.slice(0, 20)}
                              {data.title.length > 20 && (
                                <>
                                  {' ... '}
                                  <i
                                    className="simple-icon-info pointer"
                                    id={'title-' + i}
                                  >
                                    <UncontrolledPopover
                                      trigger="hover"
                                      placement="top"
                                      target={'title-' + i}
                                    >
                                      <PopoverBody className="text-center">
                                        {data.title}
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </i>{' '}
                                </>
                              )}
                            </b>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column align-items-start justify-content-center">
                              <p className="mb-0 text-muted">Listing Cost</p>
                              <h6>
                                <strong>
                                  Rs:{' '}
                                  {numberWithCommas(
                                    data.sellingData[0]?.saleAmount
                                  )}
                                </strong>
                              </h6>
                            </div>
                            <div className="d-flex flex-column align-items-start justify-content-center ">
                              <p className="mb-0 text-muted">Retail Price</p>
                              <h6>
                                <strong>
                                  Rs: {numberWithCommas(data.variant[0]?.price)}
                                </strong>
                              </h6>
                            </div>
                          </div>
                          <footer>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex flex-column align-items-start justify-content-center ">
                                <p className="my-1 text-muted">Shipping</p>
                                <h6>
                                  <strong>
                                    {'Rs. ' +
                                      numberWithCommas(
                                        data?.sellingData[0]?.shippingCharges[0]
                                          ?.totalShipCharges
                                      )}
                                  </strong>

                                  <span className="text-muted text-small">
                                    {' '}
                                    1-2 days
                                  </span>
                                </h6>
                              </div>
                              <div className="d-flex flex-column align-items-start justify-content-center align-items-center ">
                                <p className="my-1 text-muted">Quantity</p>
                                <h6>
                                  <strong>
                                    {numberWithCommas(
                                      data.sellingData[0]?.currentQuantity
                                    )}
                                  </strong>
                                </h6>
                              </div>
                            </div>
                            <div className="mt-2">
                              <Button
                                type="button"
                                color="primary"
                                className="mr-1 mt-0 mb-2 float-left"
                                onClick={(e) =>
                                  handleSelect(
                                    data,
                                    (e = { target: { checked: true } }),
                                    i
                                  )
                                }
                              >
                                Select
                              </Button>
                            </div>
                          </footer>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="row mt-4">
                <div className="col-12 col-xs-12">
                  <ReactPaginate
                    forcePage={pageNumber - 1}
                    breakLabel="....."
                    previousLabel={<i className="simple-icon-arrow-left"></i>}
                    nextLabel={<i className="simple-icon-arrow-right"></i>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={totalPagesList}
                    renderOnZeroPageCount={null}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item mx-2'}
                    pageLinkClassName={'page-link bg-white'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link bg-white'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link bg-white'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link bg-white'}
                    activeClassName={'active'}
                  />
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <Card
          className={showTable ? 'w-70 transform-table' : ''}
          style={{ transition: 'transform 0.5s' }}
        >
          <CardBody>
            {
              <ResellerProductsFilter
                setPageLimit={setPageLimit}
                setPageNumber={setPageNumber}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                setSearchTrigger={setSearchTrigger}
                searchTrigger={searchTrigger}
                vendorOptions={vendorOptions}
                vendorFilterValue={vendorFilterValue}
                setVendorFilterValue={setVendorFilterValue}
                setFilterLoading={setFilterLoading}
                setEmptyStatus={setEmptyStatus}
                filterLoading={filterLoading}
                accounts={accounts}
                accountFilterValue={accountFilterValue}
                setAccountFilterValue={setAccountFilterValue}
                userStores={userStores}
                setUserStores={setUserStores}
                setStoreFilterValue={setStoreFilterValue}
                storeFilterValue={storeFilterValue}
              />
            }

            {paginationLoading && (
              <div className="mt-3 mx-3">
                <Alert color="info">
                  <Spinner
                    color="light"
                    size={'sm'}
                    style={{ marginBottom: '3px' }}
                  ></Spinner>{' '}
                  &nbsp;
                  <span style={{ fontSize: '16px', color: 'black' }}>
                    Products Loading!
                  </span>
                </Alert>
              </div>
            )}

            {filterLoading ? (
              <div className=" position-relative" style={{ height: '200px' }}>
                <span className=" position-absolute loading"></span>
              </div>
            ) : products.length === 0 ? (
              <div
                className=" d-flex align-items-center justify-content-center text-danger"
                style={{ height: '30vh' }}
              >
                <h3 className="fw-bold">No Products Matched!</h3>
              </div>
            ) : (
              <DataTable
                fetchData={loadAvailableProducts}
                columns={ListedForResaleColsMemo}
                data={ListForResaleRows}
                setPageLimitInParent={setPageLimit}
                setPageNumberInParent={setPageNumber}
                pageCount={totalPagesList}
                setPaginationLoading={setPaginationLoading}
                paginationLoading={paginationLoading}
              />
            )}
            {
              //---------------//
              <Offcanvas
                show={selectedList.length > 0}
                onHide={() => {
                  setProceedTo(false);
                  handleCloseSideBar();
                }}
                placement={'end'}
                scroll={true}
                backdrop={false}
              >
                <Offcanvas.Header closeButton className="px-3">
                  {proceedTo ? (
                    <div className=" mt-2 d-flex align-content-center">
                      <div className="">
                        <h4 className="p-0 m-0 fw-bold">
                          Reselling Products : {selectedList.length}
                        </h4>
                      </div>
                    </div>
                  ) : (
                    <h4 className="p-0 m-0 fw-bold">
                      Selected Products : {selectedList.length}
                    </h4>
                  )}
                </Offcanvas.Header>
                {<hr className="mt-0 mb-2" />}
                {proceedTo ? (
                  <Offcanvas.Body>
                    <form
                      onSubmit={handleSubmit}
                      className="h-100 overflow-hidden d-flex flex-column"
                    >
                      <div
                        className="px-3 customScroll"
                        style={{
                          minHeight: '85%',
                          maxHeight: '85%',
                          overflow: 'auto',
                        }}
                      >
                        <div>
                          <FormGroup>
                            <Label for="ResalePrice">Price</Label>
                            <Input
                              required
                              value={resellingData.resellingPrice}
                              onKeyDown={blockInvalidChar}
                              onChange={handleResaleData}
                              id="ResalePrice"
                              name="resellingPrice"
                              placeholder="0.00"
                              type="number"
                              min={'0'}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="SKU">SKU</Label>
                            <Input
                              value={resellingData.resellingSKU}
                              disabled
                              id="SKU"
                              name="sku"
                              type="text"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="PublishStatus">Publish Status</Label>
                            <Select
                              name="publishStatus"
                              hideSelectedOptions
                              onChange={(e) => {
                                setResellingData({
                                  ...resellingData,
                                  publishStatus: e.value,
                                });
                              }}
                              isMulti={false}
                              value={[
                                {
                                  value: resellingData.publishStatus,
                                  label: toTitleCase(
                                    resellingData.publishStatus
                                  ),
                                },
                              ]}
                              options={[
                                { value: 'active', label: 'Active' },
                                { value: 'inActive', label: 'inActive' },
                              ]}
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div>
                        <hr className="mt-1" />
                        <div className="d-flex justify-content-center">
                          <Button
                            className="mr-2"
                            color="secondary"
                            onClick={() => {
                              setProceedTo(false);
                            }}
                            disabled={publishResellingLoading}
                          >
                            Cancel
                          </Button>
                          {publishResellingLoading ? (
                            <Button
                              type="submit"
                              color="primary"
                              style={{ width: '80px', height: '36px' }}
                              disabled
                            >
                              <Spinner size="sm" color="light" type="grow">
                                Loading...
                              </Spinner>
                            </Button>
                          ) : (
                            <Button type="submit" color="primary">
                              Submit
                            </Button>
                          )}
                        </div>
                      </div>
                    </form>
                  </Offcanvas.Body>
                ) : (
                  <Offcanvas.Body>
                    <div className="h-100 overflow-hidden d-flex flex-column justify-content-arround">
                      <div
                        className="mt-1 customScroll"
                        style={{
                          minHeight: '86%',
                          maxHeight: '86%',
                          overflow: 'auto',
                        }}
                      >
                        <ul className="list-unstyled">
                          {selectedList?.map((data, i) => (
                            <li key={i}>
                              <div className="d-flex align-items-center mb-3">
                                <span
                                  type="button"
                                  className="btn-close text-reset mr-2 "
                                  style={{ fontSize: '10px' }}
                                  onClick={(e) => {
                                    setMasterCheck(false);
                                    let deleting = selectedList.filter(
                                      (p) => p !== data
                                    );
                                    setSelectedList(deleting);
                                    let setting = products.filter((obj) => {
                                      if (obj === data) {
                                        obj.selected = false;
                                      }
                                      return obj;
                                    });
                                    setProducts([...setting]);
                                    setting.some((e) => e.selected)
                                      ? setShowTable(true)
                                      : setShowTable(false);
                                  }}
                                ></span>
                                <img
                                  src={
                                    data.image?.src ||
                                    './img/products/no-image.png'
                                  }
                                  alt=""
                                  style={{ width: '80px', height: '80px' }}
                                />
                                <div className="ms-2">
                                  <p className="m-0">
                                    {data?.title?.slice(0, 20)}
                                    {data?.title.length > 20 && (
                                      <>
                                        {' ... '}
                                        <i
                                          className=" simple-icon-info pointer position-relative"
                                          id={'Title-' + i}
                                        >
                                          <UncontrolledPopover
                                            trigger="hover"
                                            placement="bottom"
                                            target={'Title-' + i}
                                          >
                                            <PopoverBody className="text-center">
                                              {data.title}
                                            </PopoverBody>
                                          </UncontrolledPopover>
                                        </i>
                                      </>
                                    )}
                                  </p>
                                  <p className="m-0">
                                    <b>Rs: </b>
                                    {numberWithCommas(
                                      data?.sellingData[0]?.discount
                                    )}
                                  </p>
                                  <p className="m-0">
                                    <b>Current Quantity: </b>
                                    {numberWithCommas(
                                      data?.sellingData[0]?.inventory
                                    )}
                                  </p>
                                  <p className="m-0">
                                    <b>Vendor: </b>
                                    {data?.vendor}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="" style={{ height: '14%' }}>
                        <hr className="mt-1" />
                        <div className="d-flex justify-content-center">
                          <Button
                            type="button"
                            color="secondary"
                            className=" mr-2"
                            onClick={() => {
                              setProceedTo(false);
                              handleCloseSideBar();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              setProceedTo(true);
                            }}
                          >
                            Proceed
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Offcanvas.Body>
                )}
              </Offcanvas>
            }
          </CardBody>
        </Card>
      )}
    </main>
  );
};
