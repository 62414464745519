import axios from 'axios';
import { getCookie } from '../helpers/cookie';
import { getAPIURL } from './utils';
const url = getAPIURL();
const token = getCookie();
let response = '';

export const getAvialableProducts = async ({
  limit,
  page,
  title,
  accountUser,
  storeFilter,
  vendor,
}) => {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('page', page.toString());
  params.append('title', title.toString().trim());
  params.append('vendor', vendor.toString());
  params.append('accountUser', accountUser.toString());
  params.append('storeFilter', storeFilter.toString());

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params,
  };
  try {
    response = await axios.get(
      `${url}/api/resellingProducts/getAvailableForResellingProducts`,
      config
    );
  } catch (err) {
    response = err.response.data;
  }
  return response;
};

export const createResellingProducts = async (data) => {
  let token = getCookie();
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    response = await axios.post(
      `${url}/api/resellingProducts/publishResellingProducts`,
      data,
      config
    );
  } catch (err) {
    response = err.response.data;
  }
  return response;
};

export const getPublishedForResellingProducts = async ({
  limit,
  page,
  title,
  sellers,
}) => {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('page', page.toString());
  params.append('title', title.toString().trim());
  params.append('sellersIds', sellers.toString());

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params,
  };
  try {
    response = await axios.get(
      `${url}/api/resellingProducts/getPublishedResellingProducts`,
      config
    );
  } catch (err) {
    response = err.response.data;
  }
  return response;
};

export const updatePublishedResellingProducts = async (data) => {
  let token = getCookie();
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    response = await axios.put(
      `${url}/api/resellingProducts/updatePublishedResellingProducts`,
      data,
      config
    );
  } catch (err) {
    response = err.response.data;
  }
  return response;
};

export const delistPublishedResellingProducts = async (data) => {
  let token = getCookie();
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    response = await axios.post(
      `${url}/api/resellingProducts/delistPublishedResellingProducts`,
      data,
      config
    );
  } catch (err) {
    response = err.response.data;
  }
  return response;
};
