import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { data } from "./data";
import DataTable from "../tables/table";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import {
  Badge,
  Breadcrumb,
  Card,
  Button,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  CardTitle,
  Table,
} from "reactstrap";
import Select from "react-select";
const Resellers = () => {
  const [products, setProducts] = useState(data);
  const [filterData, setFilterData] = useState(products);
  // const [search, setSearch] = useState('');
  const datas = filterData.length === 0 ? products : filterData;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const colsData = [
    {
      Header: "ID",
      accessor: "id",
      cellClass: "",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Name",
      accessor: "vendor",
      cellClass: "",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Listed Product",
      accessor: "maxReseller",
      cellClass: "",
      Cell: (props) => <>{numberWithCommas(props.value)}</>,
    },
    {
      Header: "Sale Count",
      accessor: "dailySale",
      cellClass: "",
      Cell: (props) => (
        <>{props.value === "" ? "NA" : numberWithCommas(props.value)}</>
      ),
    },
    {
      Header: "Sale Amount",
      accessor: "saleAmount",
      cellClass: "",
      Cell: (props) => <>{numberWithCommas(props.value)}</>,
    },
    {
      Header: "Unfulfilled Orders Count",
      accessor: "unfilledOrders",
      cellClass: "",
      Cell: (props) => <>{numberWithCommas(props.value)}</>,
    },
    {
      Header: "Actions",
      accessor: "pid",
      cellClass: "",
      Cell: (props) => (
        <Link to={`/admin/resellerDetail/${props.value}`}>
          <span className="simple-icon-eye pointer fs-5 text-black"></span>
        </Link>
      ),
    },
  ];
  const cols = useMemo(() => colsData, [colsData]);

  // const categoryOptions = [
  //   { value: 'Jeans shirt', label: 'Jeans Shirt' },
  //   { value: 'Men Watch', label: 'Men Watch' },
  //   { value: 'Ladies Watch', label: 'Ladies Watch' },
  // ];

  const handleSearch = (e) => {
    if (e.target.value.length === 0) {
      console.log({ first: e.target.value });
      setFilterData(data);
    } else {
      setFilterData(
        datas.filter((p) =>
          p.vendor.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  return (
    <div>
      <main>
        <Row className="top-text ms-sm-1">
          <Cols xxs="12">
            <h1>Resellers</h1>
            <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" style={{ color: "black" }}>
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Resellers
                </li>
              </ol>
            </Breadcrumb>
          </Cols>
        </Row>
        <Row className="mobileMargin ms-sm-3">
          <Cols xxs="12">
            <Card className="mb-4">
              <CardBody>
                <CardTitle className="order_table_card_title mb-0">
                  <div className="search-sm my-2 mr-2  global-search-font">
                    <Input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder="Search"
                      // value={search}
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </CardTitle>
                {filterData.length !== 0 ? (
                  <DataTable
                    columns={cols}
                    data={filterData}
                    totalCount={data.length}
                    pageCount={1}
                  />
                ) : (
                  <div className="mt-4">
                    <div className="py-5 mt-3 text-center">
                      <h3 style={{ color: "red" }}>No Reseller Matched!</h3>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Cols>
        </Row>
      </main>
    </div>
  );
};

export default Resellers;
