import React, { useState, useMemo } from "react";
import { Card, CardBody, Row, Input, CardTitle, Button } from "reactstrap";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import { data } from "../../Seller/data";
import DataTable from "../../tables/table";
import { DateFormatted } from "../../../helpers/dateTime";

const ResellingTransaction = () => {
  const [products, setProducts] = useState(data);
  const [dateTime, setDateTime] = useState();
  const [filterData, setFilterData] = useState(products);
  const datas = filterData.length === 0 ? products : filterData;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const colsData = [
    {
      Header: "Date",
      accessor: "offerExpiry",
      cellClass: "text-muted w-10",
      Cell: (props) => <>{DateFormatted(props.value, setDateTime, dateTime)}</>,
    },
    {
      Header: "Seller",
      accessor: "vendor",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value === "" ? "NA" : props.value}</>,
    },

    {
      Header: "Amount",
      accessor: "saleAmount",
      cellClass: "text-muted",
      Cell: (props) => <>{numberWithCommas(props.value)}</>,
    },
    {
      Header: "Status",
      accessor: "status",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Balance",
      accessor: "balance",
      cellClass: "text-muted",
      Cell: (props) => <>{numberWithCommas(props.value)}</>,
    },
    {
      Header: "Proof of Payment",
      accessor: "image",
      cellClass: "w-12 position-relative",
      Cell: (props) => (
        <>
          <span className="resellingImageHoverOnSelfPayment">
            <img
              src={props.value}
              alt="image not found"
              width="50"
              height="40"
              className=""
            />
          </span>
        </>
      ),
    },
  ];

  const cols = useMemo(() => colsData, [colsData]);

  const handleSearch = (e) => {
    if (e.target.value.length === 0) {
      console.log({ first: e.target.value });
      setFilterData(data);
    } else {
      setFilterData(
        datas.filter((p) =>
          p.vendor.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  return (
    <>
      <Row>
        <Cols xxs="12">
          <Card>
            <CardBody>
              <CardTitle className="order_table_card_title mb-0">
                <div className="search-sm my-2 mr-2  global-search-font">
                  <Input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder="Search"
                    // value={search}
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </CardTitle>
              {filterData.length !== 0 ? (
                <DataTable columns={cols} data={filterData} />
              ) : (
                <div className="mt-4">
                  <div className="py-5 mt-3 text-center">
                    <h3 style={{ color: "red" }}>No Seller Matched!</h3>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Cols>
      </Row>
    </>
  );
};

export default ResellingTransaction;
