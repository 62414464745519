import React, { useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  PopoverBody,
  Spinner,
  Table,
  UncontrolledPopover,
} from "reactstrap";
import {
  delistSellingProducts,
  getListedProducts,
  updateProductsFromListed,
} from "../../../api/sellingProducts.js.js";
import DataTable from "../../tables/table";
import { ResaleProductsFilters } from "./SellingProductsTableHeader";
import Offcanvas from "react-bootstrap/Offcanvas";

export const ListedProducts = () => {
  let defaultShipRate = { fromPrice: "", toPrice: "", totalShipCharges: "" };
  let defaultResaleData = {
    pId: "",
    discount: "",
    inventory: "",
    threshold: "",
    saleAmount: "",
    currentQuantity: "",
    shippingCharges: [defaultShipRate],
  };

  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [delistLoading, setDelistLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [vendorFilterValue, setVendorFilterValue] = useState("");

  const [emptyStatus, setEmptyStatus] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [allVendors, setAllVendors] = useState([]);

  const [total, setTotal] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [masterCheck, setMasterCheck] = useState(false);

  const [multiShippingCharges, setMultiShippingCharges] = useState([
    defaultShipRate,
  ]);

  const [editResaleData, setEditResaleData] = useState(defaultResaleData);

  let totalPagesList = Math.ceil(total / pageLimit);

  const loadListedProducts = useCallback(
    async ({
      limit = pageLimit,
      page = pageNumber,
      title = searchValue,
      vendor = vendorFilterValue,
    }) => {
      setMasterCheck(false);
      setSelectedList([]);
      try {
        const { data } = await getListedProducts({
          limit,
          page,
          title,
          vendor,
        });

        if (data) {
          setProducts(data.products);
          setTotal(data.totalCount);
          // setAllVendors(data.allVendors);

          setLoading(false);
          setFilterLoading(false);
          setPaginationLoading(false);

          data.totalCount === 0 && !searchValue
            ? setEmptyStatus(true)
            : setEmptyStatus(false);
        }
      } catch (err) {
        toast.error("Resale Products data loading error!");
      }
    },
    [pageLimit, pageNumber, searchTrigger, vendorFilterValue]
  );

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    loadListedProducts({});
  }, [loadListedProducts]);

  const toTitleCase = (str) => {
    let replaced = str?.replace(
      /\w\S*/g,
      (txt) => txt?.charAt(0).toUpperCase() + txt?.substr(1).toLowerCase()
    );
    return replaced;
  };

  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const onItemCheck = (item, e, ind) => {
    // let tempList = products;
    // tempList.map((obj, index) => {
    //   if (index === ind) {
    //     obj.selected = e.target.checked;
    //   }
    //   return obj;
    // });
    // setSelectedList(tempList.filter((e) => e.selected));
  };

  const handleSelect = (product, e, index) => {
    let tempList = products;
    tempList.map((obj, i) => {
      if (index === i) {
        obj.selected = e.target.checked;
      } else {
        obj.selected = false;
      }
      return obj;
    });
    setSelectedList(tempList.filter((e) => e.selected));
    setEditResaleData(JSON.parse(JSON.stringify(product.sellingData[0])));
    setMultiShippingCharges(
      JSON.parse(JSON.stringify(product.sellingData[0].shippingCharges))
    );
  };

  const handleMasterCheck = (e) => {
    // let tempList = products;
    setMasterCheck(e.target.checked);
    // tempList.map((obj) => (obj.selected = e.target.checked));
    // setSelectedList(tempList.filter((e) => e.selected));
  };

  const handleCloseSideBar = () => {
    let tempList = products;
    setMasterCheck(false);
    tempList.map((obj) => (obj.selected = false));
    setEditResaleData(defaultResaleData);
    setSelectedList([]);
  };

  const blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const getUnique = (array) => {
    let uniqueArray = [];

    // Loop through array values
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  };

  const vendorOptions = getUnique(allVendors.map((p) => p.vendor)).map((p) => {
    return { value: p, label: toTitleCase(p) };
  });

  const handleResaleData = (e) => {
    let { name, value } = e.target;
    setEditResaleData({ ...editResaleData, [name]: value });
  };

  const handleShipCharges = (e, index) => {
    let { name, value } = e.target;

    let setValue = multiShippingCharges;
    setValue.filter((obj, ind) => {
      if (ind === index) {
        setValue[ind] = { ...obj, [name]: value };
      }
    });
    setMultiShippingCharges([...setValue]);
    setEditResaleData({ ...editResaleData, shippingCharges: [...setValue] });
  };

  const handleUpdateListedProduct = async (product) => {
    setUpdateLoading(true);
    try {
      const response = await updateProductsFromListed(product);
      if (response.status === 200) {
        toast.success("Product updated successfully!");
        setEditResaleData(defaultResaleData);
        setMultiShippingCharges([defaultShipRate]);
        setMasterCheck(false);
        setSelectedList([]);
        setUpdateLoading(false);
        setPaginationLoading(false);
        setLoading(true);
        setPageLimit(50);
        setPageNumber(1);
        loadListedProducts({});
      } else {
        setUpdateLoading(false);
        toast.error(response.error);
      }
    } catch (error) {
      setUpdateLoading(false);
      toast.error("Internal server error!");
    }
  };

  const handleDelistForResalling = async (_id) => {
    setDelistLoading(true);
    try {
      const response = await delistSellingProducts({ _id });
      if (response.status === 200) {
        toast.success("Product delisted successfully!");
        setEditResaleData(defaultResaleData);
        setMultiShippingCharges([defaultShipRate]);
        setMasterCheck(false);
        setSelectedList([]);
        setDelistLoading(false);
        setLoading(true);
        setPageLimit(50);
        setPageNumber(1);
        loadListedProducts({});
      } else {
        setDelistLoading(false);
        toast.error(response.error);
      }
    } catch (error) {
      setDelistLoading(false);
      toast.error("Internal server error!");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let product = {
      ...editResaleData,
      sellingAmount: selectedList[0]?.sellingData[0]?.saleAmount,
      currentQuantity: selectedList[0]?.sellingData[0]?.currentQuantity,
    };
    handleUpdateListedProduct(product);
  };

  const ListForResaleRows =
    products &&
    products?.map((product, index) => {
      return {
        check: (
          <label className="custom-control mb-0 pointer">
            <Input
              className="item-check pointer"
              style={{
                border: "0.1px solid gray",
              }}
              type="checkbox"
              checked={product?.selected}
              label=""
              disabled={paginationLoading || filterLoading}
              onChange={(e) => handleSelect(product, e, index)}
            />
          </label>
        ),
        image: (
          <img
            src={product?.image?.src || "./img/products/no-image.png"}
            alt=""
            className="listingImage"
          />
        ),
        sku: product.sellingData[0]?.sellingSKU ? (
          <>
            {toTitleCase(product.sellingData[0]?.sellingSKU).slice(0, 20)}
            {product.sellingData[0]?.sellingSKU.length > 20 && " ..."}
            {product.sellingData[0]?.sellingSKU.length > 20 && (
              <i className="ms-2 simple-icon-info pointer" id={"sku" + index}>
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target={"sku" + index}
                >
                  <PopoverBody className="text-center">
                    {product.sellingData[0]?.sellingSKU}
                  </PopoverBody>
                </UncontrolledPopover>
              </i>
            )}
          </>
        ) : (
          "N/A"
        ),
        productName: (
          <>
            {toTitleCase(product.title).slice(0, 20)}
            {product.title.length > 20 && " ..."}
            {product.title.length > 20 && (
              <i
                className="ms-2 simple-icon-info pointer"
                id={"Tooltip-" + index}
              >
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target={"Tooltip-" + index}
                >
                  <PopoverBody className="text-center">
                    {product.title}
                  </PopoverBody>
                </UncontrolledPopover>
              </i>
            )}
          </>
        ),

        saleAmount: product?.sellingData[0]?.saleAmount || "N/A",

        saleCount: "N/A",
        unfulfilledOrders: "N/A",
        resellers: (
          <>
            {product.sellingData[0].resellers.length}{" "}
            {product.sellingData[0].resellers.length > 0 && (
              <i className="simple-icon-info pointer" id={"Resellers-" + index}>
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target={"Resellers-" + index}
                >
                  <PopoverBody className="text-center">
                    <ul className=" list-unstyled">
                      {product.sellingData[0].resellers.map((obj, i) => (
                        <li key={i}>{obj.firstname + " " + obj.lastname}</li>
                      ))}
                    </ul>
                  </PopoverBody>
                </UncontrolledPopover>
              </i>
            )}
          </>
        ),
        offerExpiry: "N/A",

        action: (
          <span
            className="simple-icon-pencil fs-5 pointer"
            // onClick={() => {

            //   setEditResaleData(
            //     JSON.parse(JSON.stringify(product.sellingData[0]))
            //   );
            //   setMultiShippingCharges(
            //     JSON.parse(
            //       JSON.stringify(product.sellingData[0].shippingCharges)
            //     )
            //   );
            // }}
          ></span>
        ),
      };
    });

  let ListedFoorResaleCols = [
    {
      Header: (
        <label className="custom-control mb-0 pointer">
          <Input
            disabled={paginationLoading || filterLoading}
            checked={masterCheck}
            // onChange={(e) => handleMasterCheck(e)}
            type="checkbox"
            className="item-check pointer"
            style={{
              border: "0.1px solid gray",
            }}
          />
        </label>
      ),
      accessor: "check",
      Cell: (props) => props?.value,
      cellClass: "w-1",
    },
    {
      Header: "Image",
      accessor: "image",
      cellClass: "text-muted w-1",
      Cell: (props) => props?.value,
    },
    {
      Header: "SKU",
      accessor: "sku",
      cellClass: "w-12",
      Cell: (props) => props?.value,
    },
    {
      Header: "Name",
      accessor: "productName",
      cellClass: "w-10",
      Cell: (props) => props?.value,
    },
    {
      Header: "Sale Amount",
      accessor: "saleAmount",
      cellClass: "w-10",
      Cell: (props) => props?.value,
    },
    {
      Header: "Sale Count",
      accessor: "saleCount",
      cellClass: "w-10",
      Cell: (props) => props?.value,
    },
    {
      Header: "Unfulfilled Orders",
      accessor: "unfulfilledOrders",
      cellClass: "w-12",
      Cell: (props) => props?.value,
    },
    {
      Header: "Resellers",
      accessor: "resellers",
      cellClass: "w-10",
      Cell: (props) => props?.value,
    },
    {
      Header: "Offer Expiry",
      accessor: "offerExpiry",
      cellClass: "w-10",
      Cell: (props) => props?.value,
    },

    {
      Header: "Actions",
      accessor: "action",
      cellClass: "w-1",
      Cell: (props) => props?.value,
    },
  ];

  const ListedFoorResaleColsMemo = useMemo(
    () => ListedFoorResaleCols,
    [masterCheck, products, paginationLoading, filterLoading]
  );

  console.log({ selectedList });

  return (
    <>
      {loading ? (
        <div className=" position-relative" style={{ height: "60vh" }}>
          <span className=" loading position-absolute"></span>
        </div>
      ) : emptyStatus ? (
        <Card>
          <div
            className=" d-flex align-items-center justify-content-center text-danger"
            style={{ height: "30vh" }}
          >
            <h3 className="fw-bold">No Products Found!</h3>
          </div>
        </Card>
      ) : (
        <Card
          className={selectedList.length > 0 ? "w-70 transform-table" : ""}
          style={{ transition: "transform .5s" }}
        >
          <CardBody>
            {
              <ResaleProductsFilters
                setPageLimit={setPageLimit}
                setPageNumber={setPageNumber}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                setSearchTrigger={setSearchTrigger}
                searchTrigger={searchTrigger}
                vendorOptions={vendorOptions}
                vendorFilterValue={vendorFilterValue}
                setVendorFilterValue={setVendorFilterValue}
                setFilterLoading={setFilterLoading}
                setEmptyStatus={setEmptyStatus}
                filterLoading={filterLoading}
              />
            }

            {paginationLoading && (
              <div className="mt-3 mx-3">
                <Alert color="info">
                  <Spinner
                    color="light"
                    size={"sm"}
                    style={{ marginBottom: "3px" }}
                  ></Spinner>{" "}
                  &nbsp;
                  <span style={{ fontSize: "16px", color: "black" }}>
                    Products Loading!
                  </span>
                </Alert>
              </div>
            )}

            {filterLoading ? (
              <div className=" position-relative" style={{ height: "180px" }}>
                <span className=" position-absolute loading"></span>
              </div>
            ) : products.length === 0 ? (
              <div
                className=" d-flex align-items-center justify-content-center text-danger"
                style={{ height: "30vh" }}
              >
                <h3 className="fw-bold">No Products Matched!</h3>
              </div>
            ) : (
              <DataTable
                fetchData={loadListedProducts}
                columns={ListedFoorResaleColsMemo}
                data={ListForResaleRows}
                setPageLimitInParent={setPageLimit}
                setPageNumberInParent={setPageNumber}
                pageCount={totalPagesList}
                setPaginationLoading={setPaginationLoading}
                paginationLoading={paginationLoading}
                totalCount={total}
              />
            )}
            {
              //---------------//
              <Offcanvas
                show={selectedList.length > 0}
                onHide={() => {
                  handleCloseSideBar();
                }}
                placement={"end"}
                scroll={true}
                backdrop={false}
              >
                <Offcanvas.Header closeButton className="px-3">
                  <div className=" mt-2 d-flex align-content-center w-100 justify-content-between align-items-center">
                    <div className="">
                      <h3 className="p-0 m-0">
                        Edit Products : {selectedList.length}
                      </h3>
                    </div>
                    <div className="pe-2" hidden={updateLoading}>
                      {delistLoading ? (
                        <Button
                          color="danger"
                          style={{ width: "112px", height: "38px" }}
                          disabled
                        >
                          <Spinner size="sm" color="light" type="grow">
                            Loading...
                          </Spinner>
                        </Button>
                      ) : (
                        <Button
                          color="danger"
                          size="sm"
                          style={{ width: "112px", height: "38px" }}
                          onClick={() => {
                            handleDelistForResalling(editResaleData?._id);
                          }}
                        >
                          Delist Product
                        </Button>
                      )}
                    </div>
                  </div>
                </Offcanvas.Header>
                {<hr className="mt-0 mb-2" />}

                <Offcanvas.Body>
                  <form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <div className="d-flex flex-column ">
                      <div style={{ minHeight: "75vh" }}>
                        <FormGroup>
                          <Label for="discountPrice">Discount Price</Label>
                          <Input
                            onChange={(e) => handleResaleData(e)}
                            required
                            disabled
                            onKeyDown={blockInvalidChar}
                            key={null}
                            value={editResaleData.discount}
                            id="discountPrice"
                            name="discount"
                            placeholder="0.00"
                            type="number"
                            min={"0"}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="inventory">Inventory {"(%)"}</Label>
                          <Input
                            onChange={(e) => handleResaleData(e)}
                            required
                            onKeyDown={blockInvalidChar}
                            value={editResaleData.inventory}
                            id="inventory"
                            name="inventory"
                            placeholder="Inventory"
                            type="number"
                            min={"1"}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="threshold">Threshold</Label>
                          <Input
                            onChange={(e) => handleResaleData(e)}
                            required
                            onKeyDown={blockInvalidChar}
                            value={editResaleData.threshold}
                            id="threshold"
                            name="threshold"
                            placeholder="Threshold"
                            type="number"
                            min={"1"}
                          />
                        </FormGroup>
                        <Label for="threshold" className="">
                          Shipping Charges
                        </Label>
                        <div
                          className=" overflow-auto customScroll"
                          style={{ minHeight: "200px", maxHeight: "200px" }}
                        >
                          <Table borderless responsive className="">
                            <thead>
                              <tr>
                                <th className="m-0 p-1 text-start">
                                  From Price
                                </th>
                                <th className="m-0 p-1 text-start">To Price</th>
                                <th className="m-0 p-1 text-start">
                                  Total Price
                                </th>
                                <th className="m-0 p-1 text-start"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {multiShippingCharges.map((v, i) => (
                                <tr key={i}>
                                  <td className="border-0 pe-1">
                                    <div style={{ width: "90px" }}>
                                      <Input
                                        onKeyDown={blockInvalidChar}
                                        onChange={(e) =>
                                          handleShipCharges(e, i)
                                        }
                                        value={v.fromPrice}
                                        required
                                        id="from"
                                        name="fromPrice"
                                        placeholder="0.00"
                                        type="number"
                                        min={"0"}
                                      />
                                    </div>
                                  </td>
                                  <td className="border-0 pe-1">
                                    <div style={{ width: "90px" }}>
                                      <Input
                                        onKeyDown={blockInvalidChar}
                                        onChange={(e) =>
                                          handleShipCharges(e, i)
                                        }
                                        required
                                        value={v.toPrice}
                                        id="toPrice"
                                        name="toPrice"
                                        placeholder="0.00"
                                        type="number"
                                        min={"0"}
                                      />
                                    </div>
                                  </td>
                                  <td className="border-0 pe-1">
                                    <div style={{ width: "120px" }}>
                                      <Input
                                        onKeyDown={blockInvalidChar}
                                        onChange={(e) =>
                                          handleShipCharges(e, i)
                                        }
                                        required
                                        value={v.totalShipCharges}
                                        id="totalCharges"
                                        name="totalShipCharges"
                                        placeholder="0.00"
                                        type="number"
                                        min={"0"}
                                      />
                                    </div>
                                  </td>
                                  <td className="border-0 pe-1">
                                    {i === 0 ? (
                                      <span
                                        className=" fs-4 align-self-center text-primar pointer"
                                        onClick={() => {
                                          setMultiShippingCharges([
                                            ...multiShippingCharges,
                                            defaultShipRate,
                                          ]);
                                        }}
                                      >
                                        +
                                      </span>
                                    ) : (
                                      <span
                                        className=" fs-1 align-self-center text-danger pointer"
                                        onClick={() => {
                                          multiShippingCharges.splice(i, 1);
                                          setMultiShippingCharges([
                                            ...multiShippingCharges,
                                          ]);
                                          setEditResaleData({
                                            ...editResaleData,
                                            shippingCharges: [
                                              ...multiShippingCharges,
                                            ],
                                          });
                                        }}
                                      >
                                        -
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        <div hidden={delistLoading}>
                          <hr className={`mt-1`} />
                          <div className="d-flex justify-content-center">
                            <Button
                              disabled={delistLoading}
                              className=" mr-2"
                              color="secondary"
                              onClick={() => {
                                handleCloseSideBar();
                              }}
                            >
                              Cancel
                            </Button>
                            {updateLoading ? (
                              <Button
                                type="submit"
                                color="primary"
                                style={{ width: "80px", height: "36px" }}
                                disabled
                              >
                                <Spinner size="sm" color="light" type="grow">
                                  Loading...
                                </Spinner>
                              </Button>
                            ) : (
                              <Button type="submit" color="primary">
                                Update
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </Offcanvas.Body>
              </Offcanvas>
            }
          </CardBody>
        </Card>
      )}
    </>
  );
};
