import React, { useCallback, useEffect, useState } from "react";
import { PDFDocument } from "pdf-lib";

import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import Select from "react-select";
import {
  Row,
  Alert,
  Spinner,
  Input,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import DataTable from "./OrderTable.jsx";
import SpinnerButton from "../../common/SpinnerButton.jsx";
import {
  fulfillOrderItems,
  getOrderByDispatchStatus,
  updateOrderDispatchStatus,
  getCourierSlip,
  getTraxLabel,
} from "../../../api/orders";
import {
  ConsigneeDetails,
  NotePopup,
  OrdersDetailsPopup,
} from "../../Modals/Popups.jsx";
import { formatPhoneNumber } from "../../../helpers/phoneNumber.jsx";
import { sortByDate, DateFormatted } from "../../../helpers/dateTime";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DateFilters from "../../Date/DateFilters.jsx";
import { showItemDetails } from "../orderTabs/helperFunctions/tableDetailsInfo.jsx";
import { getCookie } from "../../../helpers/cookie";
import { useHistory } from "react-router-dom";
import { getAllStoresLoginUser } from "../../../api";
import { getUserPreferences } from "../../../api/courier";

// import { isAuthenticated } from "../../../helpers/auth";
import { getLocalStorage } from "../../../helpers/localStorage";
import { handleSearch } from "../../search/searchHook";
// import { rightAnimate } from "../../FramerMotion/FramerMotion";
import { popOverContent, popOverList } from "../../../popOvers/popOver";
import TableSkeleton from "../../UI/TableSkeleton";
import {
  chunkArray,
  handleDownloadPdf,
  mergePDFs,
} from "../../../helpers/downloadMergedPDF";
const AssignedOrders = ({ setOrderFulfilled, setSkeletonTab }) => {
  const [search, setSearch] = useState("");
  const [courierAssignedOrders, setCourierAssignedOrders] = useState([]);
  const [checkboxes, setCheckboxes] = useState({});
  const [isAllOrdersChecked, setIsAllOrdersChecked] = useState(false);
  const [selectedOrdersInBulk, setSelectedOrdersInBulk] = useState([]);
  const [orderDetailsData, setOrderDetailsData] = useState("");
  const [noteDetailsData, setNoteDetailsData] = useState("");
  const [itemDetailsData, setItemDetailsData] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [areSelectedOrdersSlipPrinted, setAreSelectedOrdersSlipPrinted] =
    useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [citySearch, setCitySearch] = useState("");
  const [count, setCount] = useState(0);
  const [checkCounter, setCheckCounter] = useState(0);
  const [consigneeDetails, setConsigneeDetails] = useState();
  // const [showResetButton, setShowResetButton] = useState(false);
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedDispatchStatus, setSelectedDispatchStatus] = useState(null);
  const [showStoreColumn, setShowStoreColumn] = useState(false);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [filtersLoader, setFiltersLoader] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [searchCityTrigger, setSearchCityTrigger] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [startOrdersFrom, setStartOrdersFrom] = useState(null);
  const [endOrdersTo, setendOrdersTo] = useState(null);
  const [endOrdersArr, setEndOrdersArr] = useState([]);
  const [orderNumberdataBool, setOrderNumberdataBool] = useState(false);
  const [completedPDF, setCompletedPDF] = useState(0);

  const history = useHistory();

  let statusDispatchOptions = [
    { value: "fulfillOrder", label: "Fulfill Orders" },
    { value: "Download Label", label: "Download Label" },
  ];

  // let orderFilterOptions = [
  //   { value: "incorrect", label: "Incorrect Cities" },
  //   { value: "correct", label: "Correct Cities" },
  // ];

  const getOrderNumber = (string) => {
    return Number(string?.slice(1));
  };

  const fetchAssignedOrders = useCallback(
    async ({
      page = pageNumber,
      limit = pageLimit,
      startDateIs = startDate,
      endDateIs = endDate,
      title = search.trim().length > 2 ? search : "",
      searchByCity = citySearch.trim().length > 2 ? citySearch : "",
      filterByCity = "",
    }) => {
      try {
        setEmptyStatus(false);
        setendOrdersTo(null);
        setStartOrdersFrom(null);
        setOrderNumberdataBool(false);
        setEndOrdersArr([]);
        const { data } = await getOrderByDispatchStatus({
          dispatchStatus: "assigned",
          isAssigned: true,
          title,
          page,
          limit,
          startDateIs,
          endDateIs,
          searchByCity,
          filterByCity,
        });
        if (data) {
          setCourierAssignedOrders(data.orders);
          setFiltersLoader(false);
          setLoading(false);
          // setSkeletonTab(false);
          setPaginationLoading(false);
          setCount(data.count);
          setCheckboxes({});
          setCheckCounter(0);
          setSelectedOrdersInBulk([]);
        }
        if (data.count === 0) {
          setEmptyStatus(true);
        } else {
          setEmptyStatus(false);
        }
        return data.orders;
      } catch (error) {
        console.log(
          "Error occurred while fetching assigned courier orders: ",
          error
        );
      }
    },
    [pageNumber, pageLimit, endDate, searchTrigger, searchCityTrigger]
  );
  const totalPages = Math.ceil(count / pageLimit);

  const loadStores = useCallback(async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      const { stores } = data[0];
      stores.length > 1 && setShowStoreColumn(true);
    } catch (error) {
      toast.error("Error found when fetch stores data!");
      console.log("Error found when fetch stores data!", error);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    // setSkeletonTab(true);
    loadStores();
  }, []);

  useEffect(() => {
    let token = getCookie();
    if (token) {
      fetchAssignedOrders({});
    } else {
      history.push("/signIn");
      toast.error("Your Session is Expired! Login Again...");
    }
  }, [fetchAssignedOrders]);

  const noteDetails = (note) => {
    let noteText = note.slice(0, 20);
    let noteLenght = note.length;
    return (
      <div>
        {`${noteText}${noteLenght > 20 ? "..." : ""}`}

        {noteLenght > 20 ? (
          <i
            style={{ cursor: "pointer" }}
            className="p-2 simple-icon-info "
            id="noteDetailPopover"
            onMouseEnter={() => {
              setNoteDetailsData(note);
            }}
          />
        ) : (
          ""
        )}
        <NotePopup noteData={noteDetailsData && noteDetailsData} />
      </div>
    );
  };

  const onCheckItem = (event, orderId) => {
    setCheckboxes({ ...checkboxes, [orderId]: event.target.checked });
    if (event.target.checked) {
      setSelectedOrdersInBulk([
        ...selectedOrdersInBulk,
        courierAssignedOrders.find((order) => order.orderId === orderId),
      ]);
    } else {
      if (selectedOrdersInBulk.length > 0) {
        const removeUnCheckedOrder = selectedOrdersInBulk.filter(
          (order) => order.orderId !== orderId
        );
        setSelectedOrdersInBulk(removeUnCheckedOrder);
      }
    }
    if (event.target.checked && orderId) {
      setCheckCounter(checkCounter + 1);
    } else if (!event.target.checked) {
      setCheckCounter(checkCounter - 1);
    }
  };

  const resetFilters = () => {
    setFiltersLoader(true);
    setStartDate(null);
    setEndDate(null);
  };

  const onCheckAllOrders = (e, ordersToMark) => {
    setIsAllOrdersChecked(e.target.checked);
    const orderIdsToMark = {};
    const ordersCompleteData = [];
    ordersToMark.forEach((order) => {
      orderIdsToMark[order.id] = e.target.checked;
      const matchedOrderInAllOrders = courierAssignedOrders.find(
        (completeOrder) => completeOrder.orderId === order.id
      );
      ordersCompleteData.push({ ...matchedOrderInAllOrders });
    });

    if (e.target.checked) {
      setCheckboxes(orderIdsToMark);
      setSelectedOrdersInBulk(ordersCompleteData);
      setCheckCounter(ordersCompleteData.length);
    } else {
      if (selectedOrdersInBulk.length > 0) {
        setCheckboxes({});
        setSelectedOrdersInBulk([]);
        setCheckCounter(selectedOrdersInBulk.length);
      }
    }
    if (!e.target.checked) {
      return setCheckCounter(0);
    }
  };

  const handleOrdersFrom = (e) => {
    setStartOrdersFrom(e);
    const newOptions = courierAssignedOrders
      .filter((obj) => getOrderNumber(obj.name) >= getOrderNumber(e.value))
      .map((obj) => {
        return { value: obj.name, label: obj.name };
      });
    setEndOrdersArr(
      newOptions.sort(function (a, b) {
        return getOrderNumber(a.value) - getOrderNumber(b.value);
      })
    );
  };

  const handleOrdersTo = (e) => {
    setendOrdersTo(e);
  };

  const handleOrdersFulfillment = async () => {
    if (Object.keys(checkboxes).length > 0) {
      try {
        setAreSelectedOrdersSlipPrinted(true);
        const fulfilledOrders = [];
        const successOrders = [];
        for (const order of selectedOrdersInBulk) {
          const storeId = order.storeId;
          const orderId = order.orderId;
          const unFulfilledItemIdsAndQty = order.line_items.map((item) => ({
            id: item.id,
            quantity: item.fulfillable_quantity,
          }));

          const { data, status: statusCode } = await fulfillOrderItems(
            unFulfilledItemIdsAndQty,
            orderId,
            storeId
          );
          if (data.status === "success" && statusCode === 201) {
            const orderDispatchStatusUpdateResult =
              await updateOrderDispatchStatus(
                [orderId],
                ["fulfilled"],
                [storeId]
              );
            if (orderDispatchStatusUpdateResult.status === 200) {
              setOrderFulfilled(true);
              fulfilledOrders.push(order.orderId);
              successOrders.push(order.name);
            }
          }

          setCheckCounter(0);
          setAreSelectedOrdersSlipPrinted(false);
        }
        successOrders.length > 0 &&
          toast.success(`Successfully Fulfilled  ${String(successOrders)}`);
        const remainingAssignedOrders = courierAssignedOrders.filter(
          (assignedOrder) => !fulfilledOrders.includes(assignedOrder.orderId)
        );
        setCourierAssignedOrders(remainingAssignedOrders);
        setSelectedOrdersInBulk([]);
        setCheckboxes({});
        setAreSelectedOrdersSlipPrinted(false);
      } catch (e) {
        setAreSelectedOrdersSlipPrinted(false);
        toast.error(`Error while handling Orders Fulfillment: ${e.message}`);
      }
    } else {
      toast.info("Please Select orders to Fulfill Labels!");
      setAreSelectedOrdersSlipPrinted(false);
    }
  };

  const consigneeDetail = (item, i) => {
    let orderItems = item.slice(0, 20);
    let itemsLength = item.length;
    let hoverIndex;
    return (
      <div>
        {orderItems}
        {itemsLength > 20 && (
          <>
            {" ... "}
            <i
              className="simple-icon-info pointer"
              id={"consigneeDetailPopover" + hoverIndex}
              onMouseEnter={() => {
                hoverIndex = i;
                setConsigneeDetails([item]);
              }}
              onMouseLeave={() => {
                hoverIndex = undefined;
                setConsigneeDetails();
              }}
            />
          </>
        )}
        {consigneeDetails && (
          <ConsigneeDetails
            consigneeDetails={consigneeDetails}
            index={hoverIndex}
          />
        )}
      </div>
    );
  };

  const orderDetails = (items) => {
    let orderItems = items[0].title.slice(0, 12);

    let itemsLength = items[0].title.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 12 ? "...." : ""}`}
          {itemsLength > 12 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="orderDetailPopover"
              onMouseEnter={() => {
                setOrderDetailsData(items);
              }}
            />
          ) : (
            ""
          )}
          <OrdersDetailsPopup
            orderDetailsData={orderDetailsData && orderDetailsData}
          />
        </div>
      </>
    );
  };

  // const orderPrintSlipFunction = async () => {
  //   if (Object.keys(checkboxes).length > 0) {
  //     setAreSelectedOrdersSlipPrinted(true);
  //     try {
  //       for (const order of selectedOrdersInBulk) {
  //         if (order.shipmentTracking.length === 0) {
  //           toast.error(
  //             `Tracking Number not found for Order #${order.order_number}`
  //           );
  //           setCheckCounter(0);
  //         }
  //       }
  //       let length = selectedOrdersInBulk.length;
  //       let pageLength = selectedOrdersInBulk.map((order) => {
  //         if (order.line_items.length > 3) {
  //           length = length + 1;
  //           return length;
  //         } else {
  //           return length;
  //         }
  //       });
  //       const printPages = Math.max(...pageLength);

  //       let name = [];
  //       let orderRefNumber = [];
  //       let trackingNumber = [];
  //       let courierCompany = [];
  //       let deliveryType = [];
  //       let sku = [];
  //       let id = [];

  //       selectedOrdersInBulk
  //         .filter((t) => t.shipmentTracking[0]?.trackingNumber !== undefined)
  //         .map((order) => {
  //           name.push(order?.name);
  //           orderRefNumber.push(order?.orderRefNumber);
  //           trackingNumber.push(order.shipmentTracking[0]?.trackingNumber);
  //           courierCompany.push(order.courier);
  //           deliveryType.push(order.shipmentTracking[0]?.companyName);
  //           sku.push(order.line_items[0]?.sku);
  //           id.push(order.line_items[0]?.id);
  //           return order;
  //         });

  //       const { data } = await getCourierSlip({
  //         name,
  //         orderRefNumber,
  //         trackingNumber,
  //         courierCompany,
  //         deliveryType,
  //         sku,
  //         id,
  //         printPages,
  //       });

  //       if (getCookie("token") && getLocalStorage("user")) {
  //         const link = document.createElement("a");
  //         link.setAttribute(
  //           "href",
  //           'data:"application/pdf;base64,' + data.base64PDF
  //         );

  //         let today = new Date();
  //         let dd = String(today.getDate()).padStart(2, "0");
  //         let mm = String(today.getMonth() + 1).padStart(2, "0");
  //         let yyyy = today.getFullYear();

  //         today = dd + "-" + mm + "-" + yyyy;

  //         if (selectedOrdersInBulk.length === 1) {
  //           for (const order of selectedOrdersInBulk) {
  //             link.setAttribute(
  //               "download",
  //               `${order.order_number}-${order?.shipperDetail[0]?.shopDetail?.name}-${deliveryType}.pdf`
  //             );
  //           }
  //         } else {
  //           let storename = selectedOrdersInBulk.map((order) =>
  //             order.orderRefNumber.replace(`${order.name}-`, "")
  //           )[0];
  //           for (const order of selectedOrdersInBulk) {
  //             link.setAttribute("download", `${today}-${storename}.pdf`);
  //             return order;
  //           }
  //         }
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //         toast.success(
  //           `Label Downloaded Successfully for  ${String(
  //             selectedOrdersInBulk.map((s) => s.name)
  //           )}`
  //         );

  //         await fetchAssignedOrders({});
  //         setSelectedDispatchStatus(null);
  //         setCheckCounter(0);
  //         setAreSelectedOrdersSlipPrinted(false);
  //         setSelectedOrdersInBulk([]);
  //         setIsAllOrdersChecked(false);
  //         setCheckboxes(false);
  //       }
  //     } catch (error) {
  //       toast.error(`${error}`);
  //       setAreSelectedOrdersSlipPrinted(false);
  //       setIsAllOrdersChecked(false);
  //       setCheckboxes(false);
  //       setCheckCounter(0);
  //     }
  //   } else {
  //     toast.info("Please Select Orders for Downloading labels!");
  //   }
  // };

  const orderPrintSlip = async (dataForLables) => {
    try {
      let length = dataForLables.length;
      let pageLength = dataForLables.map((order) => {
        if (order.line_items.length > 3) {
          length = length + 1;
          return length;
        } else {
          return length;
        }
      });
      const printPages = Math.max(...pageLength);

      let name = [];
      let orderRefNumber = [];
      let trackingNumber = [];
      let courierCompany = [];
      let deliveryType = [];
      let sku = [];
      let id = [];

      dataForLables.map((order) => {
        name.push(order?.name);
        orderRefNumber.push(order?.orderRefNumber);
        trackingNumber.push(order.shipmentTracking[0]?.trackingNumber);
        courierCompany.push(order.courier);
        deliveryType.push(
          order.shipmentTracking[0]?.DeliveryType ||
            order.shipmentTracking[0]?.companyName
          // order.courier === "PostEx" || order.courier === "Trax"
          //   ? order.shipmentTracking[0]?.DeliveryType
          //   : order.shipmentTracking[0]?.companyName
        );
        sku.push(order.line_items[0]?.sku);
        id.push(order.line_items[0]?.id);
        return order;
      });

      const { data } = await getCourierSlip({
        name,
        orderRefNumber,
        trackingNumber,
        courierCompany,
        deliveryType,
        sku,
        id,
        printPages,
      });
      return { pdfString: data.base64PDF };
    } catch (error) {
      toast.error(`${error.message || error.response.data || error}`);
    }
  };

  // const handleDownloadPdf = async (pdfdownloadString) => {
  //   let orderNames = [];
  //   if (getCookie("token") && getLocalStorage("user")) {
  //     const link = document.createElement("a");
  //     link.setAttribute(
  //       "href",
  //       'data:"application/pdf;base64,' + pdfdownloadString
  //     );

  //     let today = new Date();
  //     let dd = String(today.getDate()).padStart(2, "0");
  //     let mm = String(today.getMonth() + 1).padStart(2, "0");
  //     let yyyy = today.getFullYear();

  //     today = dd + "-" + mm + "-" + yyyy;

  //     if (selectedOrdersInBulk.length === 1) {
  //       for (const order of selectedOrdersInBulk) {
  //         orderNames.push(order.name);
  //         let deliveryType = order.shipmentTracking[0]?.companyName || "N/A";
  //         link.setAttribute(
  //           "download",
  //           `${order.order_number}-${order?.shipperDetail[0]?.shopDetail?.name}-${deliveryType}.pdf`
  //         );
  //       }
  //     } else {
  //       let storename = selectedOrdersInBulk.map((order) =>
  //         order.orderRefNumber.replace(`${order.name}-`, "")
  //       )[0];
  //       for (const order of selectedOrdersInBulk) {
  //         orderNames.push(order.name);
  //         link.setAttribute(
  //           "download",
  //           `${today}-${JSON.stringify(storename)}.pdf`
  //         );
  //       }
  //     }
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  //   return orderNames;
  // };

  // const handleFetchAndDownloadTraxLabel = async (order, courierToken) => {
  //   const payload = {
  //     courierToken,
  //     orderTracking: order?.shipmentTracking[0]?.trackingNumber,
  //   };
  //   try {
  //     const { pdf } = await getTraxLabel(payload);

  //     const blob = new Blob([pdf], { type: "application/pdf" });
  //     const url = URL.createObjectURL(blob);
  //     console.log({ url });
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = `${order?.name || "Trax"}.pdf`;
  //     link.click();
  //   } catch (error) {
  //     console.log(
  //       "Trax fetch and download error: ",
  //       error?.message || error?.data || error
  //     );
  //   }
  // };

  const handleOrderSlipDownload = async () => {
    if (selectedOrdersInBulk.length) {
      let noTracking = [];
      // let traxOrders = [];
      let dataForLables = selectedOrdersInBulk.filter((o) => {
        if (
          o.shipmentTracking.length &&
          o.shipmentTracking[0]?.trackingNumber
        ) {
          return o;
        } else {
          noTracking.push(o.name);
        }
      });
      if (noTracking.length) {
        toast.warn(`Tracking Number not found for Orders ${noTracking}`, {
          autoClose: false,
        });
      }

      if (dataForLables.length) {
        setAreSelectedOrdersSlipPrinted(true);
        setPaginationLoading(true);
        try {
          const chunkSize = 50; // Set the chunk size to 50
          let pdfStrings = [];
          let chunkingOrders = [];

          // let apiKey = "";
          // if (dataForLables.find((o) => o?.courier === "Trax")) {
          //   const { data } = await getUserPreferences();
          //   const traxApiKey = data?.couriersApiKeys.find(
          //     (c) => c?.name === "Trax"
          //   );
          //   apiKey = traxApiKey?.apiKey;
          // }

          for (let order of dataForLables) {
            // if (order?.courier === "Trax") {
            //   await handleFetchAndDownloadTraxLabel(order, apiKey);
            //   setCompletedPDF((c) => c + 1);
            // } else {
            const { pdfString } = await orderPrintSlip([order]);
            // let base64 = `data:image/png;base64,${pdfString}`
            setCompletedPDF((c) => c + 1);
            pdfStrings.push(pdfString);
            chunkingOrders.push(order);
            if (pdfStrings.length >= chunkSize) {
              const pdfdownloadString = await mergePDFs(pdfStrings);
              if (pdfdownloadString) {
                const downLoadedOrderNames = await handleDownloadPdf(
                  pdfdownloadString,
                  chunkingOrders
                );
                pdfStrings = [];
                chunkingOrders = [];
              }
            }
            // }
          }
          // if (pdfStrings.length) {
          //   const pdfdownloadString = await mergePDFs(pdfStrings);
          //   if (pdfdownloadString) {
          //     const downLoadedOrderNames = await handleDownloadPdf(
          //       pdfdownloadString,
          //       selectedOrdersInBulk
          //     );
          //     toast.success(`Label Downloaded Successfully!}`);
          //   }
          // }
          // if (pdfStrings.length > chunkSize) {
          //   const pdfChunks = await chunkArray(pdfStrings, chunkSize);
          //   const orderChunks = await chunkArray(dataForLables, chunkSize);

          //   for (let i = 0; i < pdfChunks.length; i++) {
          //     const chunk = pdfChunks[i];
          //     const pdfdownloadString = await mergePDFs(chunk);
          //     if (pdfdownloadString) {
          //       const downLoadedOrderNames = await handleDownloadPdf(
          //         pdfdownloadString,
          //         orderChunks[i]
          //       );
          //     }
          //   }
          // } else {
          if (pdfStrings.length) {
            const pdfdownloadString = await mergePDFs(pdfStrings);
            if (pdfdownloadString) {
              const downLoadedOrderNames = await handleDownloadPdf(
                pdfdownloadString,
                chunkingOrders
              );
            }
          }
          // }
          toast.success(`Label Downloaded Successfully!`);
          await fetchAssignedOrders({});
          setSelectedDispatchStatus(null);
          setPaginationLoading(false);
        } catch (error) {
          console.log(`${error.message || error.response.data || error}`);
          toast.error("Failed to download label");
          setPaginationLoading(false);
        }
        setSelectedOrdersInBulk([]);
        setCompletedPDF(0);
        setCheckboxes(false);
        setCheckCounter(0);
        setIsAllOrdersChecked(false);
        setAreSelectedOrdersSlipPrinted(false);
      }
    } else {
      toast.info("Please Select Orders for Downloading labels!");
    }
  };

  const newOrderNumberdata =
    courierAssignedOrders.length > 0 &&
    courierAssignedOrders
      .filter(
        (obj) =>
          getOrderNumber(obj.name) >= getOrderNumber(startOrdersFrom?.value) &&
          getOrderNumber(obj.name) <= getOrderNumber(endOrdersTo?.value)
      )
      .map((order, i) => ({
        id: order.orderId,
        name: order.shipping_address ? order.shipping_address.name : "N/A",
        orderDate: DateFormatted(order.created_at, setDateTime, dateTime, i),
        address: order?.shipping_address?.address1
          ? consigneeDetail(order?.shipping_address?.address1, i)
          : "N/A",
        phone: order?.shipping_address?.phone
          ? formatPhoneNumber(order?.shipping_address?.phone)
          : "N/A",
        note: order?.note ? noteDetails(order.note) : "N/A",
        tracking:
          order.shipmentTracking.length > 0
            ? order.shipmentTracking.map((shipment) =>
                order.shipmentTracking.length > 1
                  ? `${shipment.trackingNumber}/`
                  : `${shipment.trackingNumber}`
              )
            : "N/A",
        amount: order.total_price_set.shop_money.amount
          ? Number(order.total_price_set.shop_money.amount).toLocaleString()
          : "N/A",
        referenceNo: order.orderId,
        details: orderDetails(order.line_items),
        orderId: order.name,
        city: order?.shipping_address?.city
          ? order.shipping_address.city
          : "N/A",
        items:
          order.line_items && order.line_items.length > 0
            ? showItemDetails(
                order.line_items,
                setItemDetailsData,
                itemDetailsData,
                i
              )
            : "N/A",
        courier: order.courier,
        storeName: order.shipperDetail[0].shopDetail.name,
      }));

  const assignedOrdersData =
    courierAssignedOrders.length > 0 &&
    courierAssignedOrders.map((order, i) => ({
      id: order.orderId,
      name: popOverContent(`Name-${i}`, order.shipping_address.name),
      orderDate: DateFormatted(order.created_at, i),
      address: popOverContent(
        `Adress-${i}`,
        `${order?.shipping_address?.address1 || ""} ${
          order?.shipping_address?.address2 || ""
        }`
      ),
      phone: popOverContent(
        `Phone-${i}`,
        formatPhoneNumber(order?.shipping_address?.phone)
      ),
      note: popOverContent(`Note-${i}`, order?.note),
      tracking:
        order.shipmentTracking.length > 0
          ? order.shipmentTracking.map((shipment) =>
              order.shipmentTracking.length > 1
                ? `${shipment.trackingNumber}/`
                : `${shipment.trackingNumber}`
            )
          : "N/A",
      amount: popOverContent(
        `Total-${i}`,
        Number(order?.total_price_set?.shop_money.amount).toLocaleString()
      ),
      // referenceNo: order.orderId,
      // details: orderDetails(order.line_items),
      orderId: order.name,
      city: popOverContent(`City-${i}`, order?.shipping_address?.city),
      items: (
        <>
          {order?.line_items.length}
          {"  "}
          {order?.line_items.length > 0
            ? popOverList(
                `lineItem-${i}`,
                order.line_items.map((o) => `${o.title} (${o.quantity})`)
              )
            : "N/A"}
        </>
      ),
      courier: order.courier || "N/A",
      tracking: order?.shipmentTracking[0].trackingNumber || "N/A",
      storeName: popOverContent(
        `Store-${i}`,
        order.shipperDetail[0]?.shopDetail.name.replace(`${order?.name}-`, "")
      ),
    }));

  const cols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-3 text-start",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Courier",
        accessor: "courier",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Tracking",
        accessor: "tracking",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  const myCols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-3 text-start",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Courier",
        accessor: "courier",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Tracking",
        accessor: "tracking",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Store",
        accessor: "storeName",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  const moreActions = (e) => {
    if (e) {
      setSelectedDispatchStatus(e.label);
    } else {
      setSelectedDispatchStatus(null);
    }
  };
  return (
    <>
      {loading ? (
        <TableSkeleton skeletonLength={10} itemsBar={2} />
      ) : emptyStatus &&
        !search.trim() &&
        !citySearch.trim() &&
        !startDate &&
        !endDate ? (
        <div
          className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
          style={{
            height: "200px",
          }}
        >
          <span>No Order Found!</span>
        </div>
      ) : (
        <Row>
          <Cols xxs="12">
            <div
              className="mb-4 px-2"
              // variants={rightAnimate}
              // initial="hidden"
              // animate="visible"
            >
              <div className="card-title mb-0">
                <div className="d-flex justify-content-between flex-wrap mt-4 ">
                  <div className="mb-2 me-1 position-relative">
                    <Input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => {
                        handleSearch({
                          e,
                          setPageNumber,
                          setPageLimit,
                          setFilterLoading: setFiltersLoader,
                          setSearch,
                          setSearchTrigger,
                          searchTrigger,
                        });
                      }}
                      className="rounded-3"
                      disabled={
                        paginationLoading ||
                        filtersLoader ||
                        selectedOrdersInBulk.length > 0
                      }
                    />
                    {search.trim() && search.trim().length < 3 && (
                      <i
                        className="simple-icon-info pointer position-absolute text-danger fw-bold"
                        style={{ right: "-20px", bottom: "13px" }}
                        id={"SearchInfo"}
                      >
                        {" "}
                        <UncontrolledPopover
                          trigger="hover"
                          placement="top"
                          target={"SearchInfo"}
                        >
                          <PopoverBody className="text-center">
                            <i>Minimum 3 words required!</i>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </i>
                    )}
                    {search &&
                      (filtersLoader && search.trim().length > 2 ? (
                        <Spinner
                          color="primary"
                          size={"sm"}
                          style={{ right: "10px", bottom: "12px" }}
                          className=" position-absolute"
                          type="grow"
                        ></Spinner>
                      ) : (
                        <span
                          className="far fa-close fs-6 position-absolute text-danger pointer"
                          style={{ right: "10px", bottom: "12px" }}
                          onClick={() => {
                            if (search) {
                              setSearch("");

                              setSearchTrigger(!searchTrigger);
                              setFiltersLoader(true);
                            }
                            pageLimit > 50 && setPageLimit(50);
                            pageNumber > 1 && setPageNumber(1);
                          }}
                        ></span>
                      ))}
                  </div>
                  <div className="d-flex align">
                    <DateFilters
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      selectedDateFilter={selectedDateFilter}
                      setSelectedDateFilter={setSelectedDateFilter}
                      // setShowResetButton={setShowResetButton}
                      loadOrders={fetchAssignedOrders}
                      resetFilters={resetFilters}
                      setFiltersLoader={setFiltersLoader}
                      paginationLoading={paginationLoading}
                      filtersLoader={filtersLoader}
                      allOrdersFlag={"allOrdersFlag"}
                      selectedOrdersInBulk={selectedOrdersInBulk}
                    />
                    <div className="mb-1 mr-2 ml-1 position-relative confirm-city-search">
                      <Input
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder="Search Cities"
                        value={citySearch}
                        onChange={(e) => {
                          setEmptyStatus(false);
                          handleSearch({
                            e,
                            setPageNumber,
                            setPageLimit,
                            setFilterLoading: setFiltersLoader,
                            setSearch: setCitySearch,
                            setSearchTrigger: setSearchCityTrigger,
                            searchTrigger: searchCityTrigger,
                          });
                        }}
                        className="rounded-3"
                        disabled={
                          paginationLoading ||
                          filtersLoader ||
                          selectedOrdersInBulk.length > 0
                        }
                      />
                      {citySearch &&
                        (filtersLoader && citySearch.trim().length > 2 ? (
                          <Spinner
                            color="primary"
                            size={"sm"}
                            style={{ right: "10px", bottom: "14px" }}
                            className=" position-absolute"
                            type="grow"
                          ></Spinner>
                        ) : (
                          <span
                            className="far fa-close fs-6 position-absolute text-danger pointer"
                            style={{ right: "10px", bottom: "14px" }}
                            onClick={() => {
                              if (citySearch) {
                                setCitySearch("");

                                setSearchCityTrigger(!searchCityTrigger);
                                setFiltersLoader(true);
                              }
                              pageLimit > 50 && setPageLimit(50);
                              pageNumber > 1 && setPageNumber(1);
                            }}
                          ></span>
                        ))}
                    </div>
                    {citySearch.trim() && citySearch.trim().length < 3 && (
                      <div className="d-flex align-items-center pb-2">
                        <i
                          className="simple-icon-info pointer text-danger fw-bold mr-2"
                          // style={{ left: '-20px', bottom: '14px' }}
                          id={"CitySearchInfo"}
                        >
                          {" "}
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target={"CitySearchInfo"}
                          >
                            <PopoverBody className="text-center">
                              <i>Minimum 3 words required!</i>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </i>
                      </div>
                    )}
                    <div>
                      <Select
                        placeholder="From Order"
                        className="me-2"
                        isClearable
                        isDisabled={
                          newOrderNumberdata.length !== 0 ||
                          selectedOrdersInBulk.length > 0
                        }
                        options={courierAssignedOrders
                          .map((obj) => {
                            return { value: obj.name, label: obj.name };
                          })
                          .sort(function (a, b) {
                            return (
                              getOrderNumber(a.value) - getOrderNumber(b.value)
                            );
                          })}
                        value={startOrdersFrom}
                        onChange={(e) =>
                          !e
                            ? (setStartOrdersFrom(null), setEndOrdersArr([]))
                            : handleOrdersFrom(e)
                        }
                      />
                    </div>
                    <div>
                      <Select
                        placeholder="To Order"
                        className="me-5"
                        isClearable
                        isDisabled={
                          endOrdersArr.length === 0 ||
                          selectedOrdersInBulk.length > 0
                        }
                        options={endOrdersArr}
                        onChange={(e) => {
                          if (!e) {
                            setendOrdersTo(null);
                            setOrderNumberdataBool(false);
                          } else {
                            handleOrdersTo(e);
                            setOrderNumberdataBool(true);
                          }
                          setCheckCounter(0);
                          setCheckboxes({});
                        }}
                        value={endOrdersTo}
                      />
                    </div>
                  </div>
                  <div className=" d-flex flex-wrap">
                    <div
                      className="openOrderSelect me-2 mb-2"
                      style={{ width: "190px" }}
                    >
                      <Select
                        placeholder="Actions"
                        value={
                          selectedDispatchStatus
                            ? selectedDispatchStatus.label
                            : ""
                        }
                        options={statusDispatchOptions}
                        isDisabled={
                          !selectedOrdersInBulk.length ||
                          areSelectedOrdersSlipPrinted ||
                          paginationLoading
                        }
                        onChange={(e) => moreActions(e)}
                        isClearable
                      />
                    </div>

                    <div
                      style={{
                        maxHeight: "36px",
                        minWidth: "139px",
                        marginTop: "2px",
                      }}
                    >
                      {checkCounter === 0 || !selectedDispatchStatus ? (
                        <button
                          disabled
                          type="button"
                          className="btn btn-primary custom-btn "
                          style={{
                            height: "100%",
                            width: "96%",
                          }}
                        >
                          Submit Orders
                        </button>
                      ) : (
                        <div
                          style={
                            areSelectedOrdersSlipPrinted
                              ? {
                                  height: "100%",
                                  width: "100%",
                                }
                              : {
                                  height: "100%",
                                  width: "199%",
                                }
                          }
                        >
                          <SpinnerButton
                            btnText={
                              selectedDispatchStatus === null
                                ? "Submit Orders"
                                : selectedDispatchStatus
                            }
                            isProcessing={areSelectedOrdersSlipPrinted}
                            handler={
                              selectedDispatchStatus === "Download Label"
                                ? handleOrderSlipDownload
                                : handleOrdersFulfillment
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {paginationLoading && (
                <div className="mt-3 mx-3">
                  <Alert color="info">
                    <Spinner
                      color="light"
                      size={"sm"}
                      style={{ marginBottom: "3px" }}
                    ></Spinner>{" "}
                    &nbsp;
                    <span style={{ fontSize: "16px", color: "black" }}>
                      {areSelectedOrdersSlipPrinted
                        ? `Creating labels... ${completedPDF}/${selectedOrdersInBulk.length}`
                        : "Orders Loading!"}
                    </span>
                  </Alert>
                </div>
              )}

              {filtersLoader ? (
                <TableSkeleton skeletonLength={10} />
              ) : courierAssignedOrders?.length === 0 ||
                (newOrderNumberdata.length === 0 && orderNumberdataBool) ? (
                <div
                  className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                  style={{
                    height: "200px",
                  }}
                >
                  <span>No Order Matched!</span>
                </div>
              ) : (
                <DataTable
                  setPageLimitInParent={setPageLimit}
                  setPageNumberInParent={setPageNumber}
                  fetchData={fetchAssignedOrders}
                  pageCount={totalPages}
                  columns={showStoreColumn ? myCols : cols}
                  data={
                    endOrdersTo === null
                      ? assignedOrdersData.sort(sortByDate)
                      : newOrderNumberdata.sort(sortByDate)
                  }
                  onCheckItem={onCheckItem}
                  checkedOrders={checkboxes}
                  isAllOrdersChecked={isAllOrdersChecked}
                  onCheckAllOrders={onCheckAllOrders}
                  checkCounter={checkCounter}
                  loadingBool={loading}
                  selectedOrdersActionBool={areSelectedOrdersSlipPrinted}
                  setPaginationLoading={setPaginationLoading}
                  paginationLoading={paginationLoading}
                  totalCount={count}
                />
              )}
            </div>
          </Cols>
        </Row>
      )}
    </>
  );
};

export default AssignedOrders;
