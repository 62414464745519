import React, { lazy, Suspense } from "react";
import {
  Switch,
  Redirect,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
// import { isMultiColorActive } from '../helpers/Utils/defaultValues';
// import ColorSwitcher from '../components/common/ColorSwitcher.jsx';

// User Routes
import Waiting from "../components/Waiting.jsx";
import CitiesCouriers from "../components/Couriers/CitiesCouriers.jsx";
import CitiesCouriersApp from "../components/Couriers/citiesCouriersApp.jsx";
import CouriersAPiKey from "../components/Couriers/couriersApiKeys/CourierKeys.jsx";
import StoreAccessKey from "../components/store/StoreAccessKeys.jsx";
import ShipperInfo from "../components/store/ShipperInfo";
import OrderTracking from "../views/Tracking.jsx";
import Questionnaire from "../components/Couriers/Questionnaire";
// Admin Routes
import Dashboard from "../components/dashboards/Dashboard.jsx";
import CouriersList from "../components/CourierList/CourierList.jsx";
import CourierSelectionList from "./../components/CourierList/CourierSelectionList";
import CourierSelectionListApp from "./../components/CourierList/CourierSelectionListApp";
import DropdownSelection from "./../components/CourierList/DropdownSelection";
import DropdownSelectionApp from "./../components/CourierList/DropdownSelectionApp";
import Search from "../components/search/Search.jsx";
import AssignmentOrdersList from "../components/orders/DispatchOrderTabs/AssignmentOrdersList.jsx";
import OrdersList from "../components/orders/orderTabs/OrderList.jsx";
import VariantAdd from "../components/Variant/VariantAdd.jsx";
// import AdminAccount from '../components/account/AdminAccount';
import OrderDetails from "../components/orders/OrderDetails.jsx";
import InvoiceCheck from "../views/Invoice.jsx";
import Settings from "../components/setting/AccountSetting.jsx";
import PaymentDetails from "../components/orders/DispatchOrderTabs/PaymentDetails.jsx";
import UserAndPermissions from "../components/UserAndPermissions/UserAndPermissions.jsx";
import Billing from "../components/Billing/Billing.jsx";
import Apps from "../components/appsList/apps.jsx";
import AddStaff from "../components/account/AddStaff.jsx";
import Role from "../components/account/Role.jsx";
import AccountSetting from "../components/account/AccountSetting.jsx";
import CustomerService from "../components/account/CustomerService.jsx";
import AllCustomers from "../components/Customers/AllCustomers";
import BlockList from "../components/BlackList/index.jsx";
import { CustomerDetails } from "../components/Customers/customerDetails";

// import Accounts from "../components/account/Accounts.jsx";
import MyCouriers from "../components/CourierList/MyCouriers.jsx";
// import BillingCycle from "../components/Billing/billingCycle.jsx";
// import BillingHistory from "../components/Billing/billingHistory.jsx";
import StatementOfCharges from "../components/Billing/statmentOfCharges.jsx";
// import Invoice from "../views/Invoice.jsx";
import BillInvoice from "../components/Billing/Invoice.jsx";
import PrevSKUMapping from "../components/Inventory/PrevSKUMapping.jsx";
import SKUMapping from "../components/Inventory/SKUMapping.jsx";
import InventoryUpdate from "../components/Inventory/InventoryUpdate.jsx";
// import Invoice from "../views/Invoice.jsx";

import ProductHeader from "../components/Products/ProductListing/ProductHeader.jsx";
import AddProduct from "../components/Products/ProductActions/AddProduct";
import EditProduct from "../components/Products/ProductActions/EditProduct";

// Seller
import SellerDashboard from "../components/Seller/Dashboard.jsx";
import SellingProducts from "../components/Seller/SellingProducts/ProductsTabs";
import ResalePayments from "../components/Seller/ResalePayments/ResalePayments.jsx";
import ResaleAssignmentOrdersList from "../components/Seller/ResaleOrdersFulfillment/ResaleAssignmentOrdersList.jsx";
import ResaleReports from "../components/Seller/ResaleReports.jsx";
// Reseller
import ResellerDashboard from "../components/Reseller/Dashboard.jsx";
import { AvailableForResellingProducts } from "../components/Reseller/Products/AvailableForResellingProducts.jsx";
import { ResellerListedProducts } from "../components/Reseller/Products/ResellerListedProducts.jsx";
// import ResellingProducts from '../components/Reseller/ResellingProducts/ResellingProducts.jsx';
// import ResellerProducts from '../components/Reseller/ResellerProducts/ResellerProducts.jsx';
import ResellingPayments from "../components/Reseller/ResellingPayment/ResellingPayments.jsx";
import ResellingReports from "../components/Reseller/ResellingReports.jsx";
import Resellers from "../components/Seller/Resellers.jsx";
import ResellerDetail from "../components/Seller/ResellerDetail.jsx";
import ResellingAssignmentOrdersList from "../components/Reseller/ResellingOrdersFulfillment/ResellingAssignmentOrdersList.jsx";
import ResellerEditProduct from "../components/Reseller/ResellerProducts/ResellerEditProduct.jsx";

// Payments
import BillingCycleShow from "../components/Payments/Billing/BillingCycle";
import BillingHistoryShow from "../components/Payments/Billing/BillingHistoryShow";
import PaymentPayouts from "../components/Payments/Payouts/Payouts";
import invoiceShow from "../components/Payments/Billing/invoiceShow";

import EditLabel from "../components/account/EditLabel.jsx";
import StoreSettings from "../components/store/StoresSettings.jsx";
import SwitchAccount from "../components/account/SwitchAccount";
import VariantEdit from "../components/Variant/VariantEdit.jsx";
import VerifyMsg from "../views/VerifyMsg.jsx";
import SignUpStep2 from "../views/SignUpStep2.jsx";
import InventoryHeader from "../components/Products/Inventory/InventoryHeader.jsx";
import Webhooks from "../components/Webhooks";
import Reselling from "../components/Reselling";
import ResellingHead from "../components/Reselling/ResellingProductsHead.jsx";
//Authenticate Routing
const SignIn = lazy(() => import("../views/Signin.jsx"));
const Home = lazy(() => import("../views/Home.jsx"));
const Contact = lazy(() => import("../views/Contact.jsx"));
const SignupRecaptcha = lazy(() => import("../views/SignupRecaptcha.jsx"));
// const Signup = lazy(() => import("../views/Signup"));
const Error = lazy(() => import("../views/Error.jsx"));
const ResetPassword = lazy(() => import("../views/ResetPassword.jsx"));
const NewPassword = lazy(() => import("../views/NewPassword.jsx"));
const AdminRoutes = lazy(() => import("./AdminRoutes"));
const UserRoutes = lazy(() => import("./UserRoutes"));

// const DateTimeRange = lazy(() => import("../components/orders/DateTimeRange.jsx"));

const Routing = () => {
  return (
    <>
      {/* {isMultiColorActive && <ColorSwitcher />} */}
      <Router>
        <Suspense fallback={<div className="" />}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/signIn">
              <SignIn />
            </Route>
            <Route path="/signup">
              <SignupRecaptcha />
            </Route>
            <Route path="/signup-step2">
              <SignUpStep2 />
            </Route>
            <Route path="/verify-email">
              <VerifyMsg />
            </Route>
            <Route exact path="/resetPassword">
              <ResetPassword />
            </Route>
            <Route exact path="/order/tracking/:trackingNumber">
              <OrderTracking />
            </Route>
            <Route path="/newPassword/:token">
              <NewPassword />
            </Route>
            <UserRoutes
              exact
              path="/storeAccessKeys"
              component={StoreAccessKey}
            />
            <UserRoutes
              exact
              path="/shipperInfo/:storeId"
              component={ShipperInfo}
            />
            <UserRoutes exact path="/couriers" component={CouriersList} />
            <UserRoutes exact path="/questionnaire" component={Questionnaire} />
            <UserRoutes
              exact
              path="/couriersApiKey"
              component={CouriersAPiKey}
            />
            <UserRoutes exact path="/waiting" component={Waiting} />
            <AdminRoutes exact path="/admin/dashboard" component={Dashboard} />
            <AdminRoutes
              exact
              path="/admin/webhooks/:Id"
              component={Webhooks}
            />
            <AdminRoutes
              exact
              path="/admin/invoice/:id"
              component={InvoiceCheck}
            />
            <AdminRoutes
              exact
              path="/admin/customers"
              component={AllCustomers}
            />
            <AdminRoutes
              exact
              path="/admin/customers/customerDetails/:id"
              component={CustomerDetails}
            />
            {/* <AdminRoutes exact path="/admin/invoice" component={BillInvoice} /> */}
            <AdminRoutes exact path="/admin/stores" component={StoreSettings} />
            <AdminRoutes
              exact
              path="/admin/stores/StoreSettings"
              component={StoreSettings}
            />
            <AdminRoutes
              exact
              path="/courierlist"
              component={DropdownSelection}
            />
            <AdminRoutes
              exact
              path="/admin/courierlistApp"
              component={DropdownSelectionApp}
            />
            <AdminRoutes
              exact
              path="/admin/Products"
              component={ProductHeader}
            />
            {/* <AdminRoutes
              exact
              path="/admin/Products/inventory"
              component={InventoryHeader}
            /> */}
            <AdminRoutes
              exact
              path="/admin/Products/new"
              component={AddProduct}
            />
            {/* <AdminRoutes
              exact
              path="/admin/editProduct/:id"
              component={EditProduct}
            /> */}
            <AdminRoutes
              exact
              path="/admin/PrevSKUMapping"
              component={PrevSKUMapping}
            />
            <AdminRoutes exact path="/admin/reselling" component={Reselling} />
            <AdminRoutes
              exact
              path="/admin/resellingProducts/:accountId"
              component={ResellingHead}
            />

            {/* Seller Routes */}
            <AdminRoutes
              exact
              path="/admin/sellerDashboard"
              component={SellerDashboard}
            />
            <AdminRoutes
              exact
              path="/admin/resaleProducts"
              component={SellingProducts}
            />
            <AdminRoutes
              exact
              path="/admin/resaleOrders"
              component={ResaleAssignmentOrdersList}
            />
            <AdminRoutes
              exact
              path="/admin/resalePayments"
              component={ResalePayments}
            />
            <AdminRoutes
              exact
              path="/admin/resaleReports"
              component={ResaleReports}
            />
            <AdminRoutes exact path="/admin/Resellers" component={Resellers} />
            <AdminRoutes
              exact
              path="/admin/resellerDetail/:id"
              component={ResellerDetail}
            />

            {/* Reseller Routes */}
            <AdminRoutes
              exact
              path="/admin/resellerDashboard"
              component={ResellerDashboard}
            />
            <AdminRoutes
              exact
              path="/admin/availableForResellingProducts"
              component={AvailableForResellingProducts}
            />
            <AdminRoutes
              exact
              path="/admin/resellerListedProducts"
              component={ResellerListedProducts}
            />
            <AdminRoutes
              exact
              path="/admin/resellerListedProducts"
              component={ResellerListedProducts}
            />
            <AdminRoutes
              exact
              path="/admin/resellerProducts/:proId"
              component={ResellerEditProduct}
            />
            <AdminRoutes
              exact
              path="/admin/resellingOrders"
              component={ResellingAssignmentOrdersList}
            />
            <AdminRoutes
              exact
              path="/admin/resellingPayments"
              component={ResellingPayments}
            />
            <AdminRoutes
              exact
              path="/admin/resellingReports"
              component={ResellingReports}
            />

            {/* ------------------------- */}

            <AdminRoutes
              exact
              path="/admin/SKUMapping"
              component={SKUMapping}
            />
            <AdminRoutes
              exact
              path="/admin/inventoryUpdate"
              component={InventoryUpdate}
            />
            <AdminRoutes
              exact
              path="/admin/assignmentOrders"
              component={AssignmentOrdersList}
            />
            <AdminRoutes exact path="/admin/orders" component={OrdersList} />
            {/* <AdminRoutes exact path="/admin/orders" component={OrdersList} /> */}
            <AdminRoutes
              exact
              path="/admin/orders/:id"
              component={OrderDetails}
            />
            <AdminRoutes exact path="/admin/search" component={Search} />
            {/*<AdminRoutes exact path="/admin/account" component={AdminAccount} />*/}
            <AdminRoutes
              exact
              path="/admin/account/personalSetting"
              component={AccountSetting}
            />
            <AdminRoutes
              exact
              path="/admin/account/switchAccount"
              component={SwitchAccount}
            />
            <AdminRoutes
              exact
              path="/admin/account/editLabel/:id"
              component={EditLabel}
            />
            <AdminRoutes exact path="/admin/setting" component={Settings} />
            <AdminRoutes
              exact
              path="/admin/couriers"
              component={CouriersList}
            />
            <UserRoutes
              exact
              path="/CourierSelectionList"
              component={CourierSelectionList}
            />
            <AdminRoutes
              exact
              path="/admin/CourierSelectionListApp"
              component={CourierSelectionListApp}
            />
            <AdminRoutes
              exact
              path="/admin/myCouriers"
              component={MyCouriers}
            />
            <UserRoutes
              exact
              path="/citiesCouriers"
              component={CitiesCouriers}
            />

            <AdminRoutes
              exact
              path="/admin/citiesCouriersApp"
              component={CitiesCouriersApp}
            />

            <AdminRoutes
              exact
              path="/admin/userAndPermissions"
              component={UserAndPermissions}
            />
            {/* Payments Routes */}
            <AdminRoutes
              exact
              path="/admin/payments/billing"
              component={Billing}
            />
            <AdminRoutes
              exact
              path="/admin/billing/billingCycle"
              component={BillingCycleShow}
            />
            <AdminRoutes
              exact
              path="/admin/payments/billingHistory"
              component={BillingHistoryShow}
            />
            <AdminRoutes
              exact
              path="/admin/payments/statementOfCharges"
              component={StatementOfCharges}
            />
            <AdminRoutes
              exact
              path="/admin/payments/billInvoice"
              component={BillInvoice}
            />
            <AdminRoutes
              exact
              path="/admin/payments/invoice"
              component={invoiceShow}
            />
            <AdminRoutes
              exact
              path="/admin/payments/payouts"
              component={PaymentPayouts}
            />
            {/* ///////////////////// */}

            {/* <AdminRoutes exact path="/admin/billing" component={Billing} />
            <AdminRoutes
              exact
              path="/admin/billing/billingCycle"
              component={BillingCycle}
            />
            <AdminRoutes
              exact
              path="/admin/billing/billingHistory"
              component={BillingHistory}
            />

            <AdminRoutes
              exact
              path="/admin/billing/statementOfCharges"
              component={StatementOfCharges}
            />
            <AdminRoutes
              exact
              path="/admin/billing/billInvoice"
              component={BillInvoice}
            /> */}

            <AdminRoutes exact path="/admin/apps" component={Apps} />
            {/*<AdminRoutes exact path="/admin/accounts" component={Accounts} />*/}
            <AdminRoutes
              exact
              path="/admin/userAndPermissions/addStaff"
              component={AddStaff}
            />
            <AdminRoutes
              exact
              path="/admin/userAndPermissions/role"
              component={Role}
            />
            <AdminRoutes
              exact
              path="/admin/userAndPermissions/customerService"
              component={CustomerService}
            />
            <AdminRoutes exact path="/admin/blockList" component={BlockList} />
            <AdminRoutes
              exact
              path="/admin/PaymentDetails"
              component={PaymentDetails}
            />
            <AdminRoutes exact path="/admin/Products/:id" component={EditProduct} />
            <AdminRoutes
              exact
              path="/admin/products/:proId/variants/addNewVariant"
              component={VariantAdd}
            />
            <AdminRoutes
              exact
              path="/admin/products/:proId/variants/editVariant/:varId"
              component={VariantEdit}
            />
            <Route path="/error">
              <Error />
            </Route>
            <Redirect exact to="/error" />
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default Routing;
