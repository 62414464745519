import React, { useState, useEffect, useCallback, useRef } from "react";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import DataTable from "./ResellingOrderTable";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Button,
  Input,
  Alert,
} from "reactstrap";
import {
  updateOrderDispatchStatus,
  getOrderByDispatchStatus,
} from "../../../api/orders";
import { sortByDate, DateFormatted } from "../../../helpers/dateTime";
import Select from "react-select";
import { OrdersDetailsPopup, ConsigneeDetails } from "../../Modals/Popups";
import { formatPhoneNumberWithButtons } from "../../../helpers/phoneNumber";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveBillingLogs } from "../../../api/billing";
import DateFilters from "../../Date/DateFilters.jsx";
import { getAllCitiesNames } from "../../../api/cities";
import CreatableSelect from "react-select/creatable";
import { getUserPreferences } from "../../../api/courier";
import {
  saveVariants,
  updateShippingCityFromOrders,
  updateShippingConsigneeName,
  updateShippingConsigneePhone,
  updateShippingConsigneeAddress,
} from "../../../api/cityTypos.js";
import { showItemDetails } from "../../orders/orderTabs/helperFunctions/tableDetailsInfo";
const ResellingAllOrders = ({
  setOrderConfirmed,
  setOrderPending,
  setOrderCancelled,
  setOrderManual,
}) => {
  const [search, setSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [unfulfilledOrders, setUnfulfilledOrders] = useState([]);
  const [unFulfilledOrdersLoaded, setUnFulfilledOrdersLoaded] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [orderDetailsData, setOrderDetailsData] = useState();
  const [itemDetailsData, setItemDetailsData] = useState();
  // const [orderToConfirm, setOrderToConfirm] = useState({});
  const [checkboxes, setCheckboxes] = useState({});
  const [isAllOrdersChecked, setIsAllOrdersChecked] = useState(false);
  const dispatchStatusDDRef = useRef(null);
  const [selectedOrdersInBulk, setSelectedOrdersInBulk] = useState([]);
  const [cities, setCities] = useState([]);
  const [cityOrderId, setCityOrderId] = useState();
  const [disabledCity, setDisabledCity] = useState(false);
  const [createOption, setCreateOption] = useState(false);
  const [courierAfterCityUpdate, setCourierAfterCityUpdate] = useState({});
  const [editCityName, setEditCityName] = useState({});
  const [newCity, setNewCity] = useState();
  const [couriers, setCouriers] = useState([]);
  const [citiesCourier, setCitiesCourier] = useState([]);
  const [dataDefaultCourier, setDataDefaultCourier] = useState();
  const shipmentOptionsDDRef = useRef(null);
  const [dateTime, setDateTime] = useState();
  const [areSelectedOrdersProcessing, setAreSelectedOrdersProcessing] =
    useState(false);
  const [consigneeDetails, setConsigneeDetails] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [checkCounter, setCheckCounter] = useState(0);
  const [selectedDispatchStatus, setSelectedDispatchStatus] = useState(null);
  const [optionSelected, setOptionSelected] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const [invalidOrders, setInvalidOrders] = useState([]);
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [header, setHeader] = useState();
  const [loading, setLoading] = useState(false);
  const [searchNameBool, setSearchNameBool] = useState(false);
  const [searchCityBool, setSearchCityBool] = useState(false);

  let statusDispatchOptions = [
    { value: "confirm", label: "Confirm" },
    { value: "pending", label: "Pending" },
    { value: "cancel", label: "Cancel" },
    { value: "manual", label: "Manual" },
  ];

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  // const loadOrders = useCallback(
  //   async ({
  //     page = pageNumber,
  //     limit = pageLimit,
  //     startDateIs = startDate,
  //     endDateIs = endDate,
  //   }) => {
  //     try {
  //       setLoading(true);
  //       const { data } = await getOrderByDispatchStatus({
  //         dispatchStatus: "open",
  //         isAssigned: false,
  //         page,
  //         limit,
  //         startDateIs,
  //         endDateIs,
  //       });
  //       data && setLoading(false);
  //       setTotalPagesCount(Math.ceil(data.count / limit));
  //       setUnfulfilledOrders(data.orders);
  //       setUnFulfilledOrdersLoaded(true);
  //       return data.orders;
  //     } catch (error) {
  //       console.log("error found when fetch order data", error);
  //     }
  //   },
  //   [pageNumber, pageLimit, endDate]
  // );

  // const loadCities = useCallback(async () => {
  //   try {
  //     const { data: citiesData } = await getAllCitiesNames();
  //     console.log({ citiesData });
  //     setCities(citiesData.cities);
  //   } catch (error) {
  //     console.log("error found when fetch order data", error);
  //   }
  // }, []);

  // const loadCouriers = useCallback(async () => {
  //   try {
  //     const { data } = await getUserPreferences();
  //     // setDefaultCourier(Object.keys(data.defaultCityCourier)[0]);
  //     setCouriers(data.couriers);
  //     setCitiesCourier(data.courierCities);
  //     setDataDefaultCourier(data.defaultCitiesCourier);
  //   } catch (error) {
  //     console.log("error found when fetch order data", error);
  //   }
  // }, []);

  useEffect(() => {
    // if (unfulfilledOrders.length === 0) {
    // loadOrders({});
    // loadCities();
    // if (couriers.length <= 0) {
    //   loadCouriers();
    // }
    // }
    setUnfulfilledOrders([
      {
        _id: "628e09554c50fcd10046757d",
        courier: "",
        seller: "Umar",
        sellerOrder: "121358",
        courierAssigned: false,
        dispatchStatus: "confirm",
        deliveryStatus: "not_delivered",
        storeId: "62878032582e6500dc75ac17",
        storeName: "codingstorte.myshopify.com",
        orderId: "4787435471066",
        orderRefNumber: "#1013-CodingStorte",
        cancel_reason: null,
        cancelled_at: null,
        created_at: "2022-05-25T15:47:46+05:00",
        current_total_price: "114.84",
        financial_status: "paid",
        fulfillment_status: null,
        name: "#1013",
        note: null,
        order_number: 1013,
        processed_at: "2022-05-25T15:47:46+05:00",
        subtotal_price: "99.00",
        total_price: "114.84",
        total_price_set: {
          shop_money: {
            amount: "114.84",
          },
        },
        total_weight: 0,
        updated_at: "2022-05-25T15:47:47+05:00",
        line_items: [
          {
            id: 12233094725850,
            price: "70.00",
            quantity: 1,
            sku: "AD-03-black-OS",
            title: "ADIDAS | CLASSIC BACKPACK",
          },
          {
            id: 12233094758618,
            price: "29.00",
            quantity: 1,
            sku: "VN-01-burgandy-4",
            title: "VANS |AUTHENTIC | LO PRO | BURGANDY/WHITE",
          },
        ],
        shipmentTracking: [],
        shipperDetail: [
          {
            shopDetail: {
              name: "CodingStorte",
              email: "usamariaz2844@gmail.com",
              domain: "codingstorte.myshopify.com",
              address1: "Punjab Pakistan",
              city: "Faisalabad",
              phone: "",
              country_name: "Pakistan",
            },
          },
        ],
      },
      {
        _id: "628b7c9c4c50fcd100467422",
        courier: "",
        seller: "Zaghum",
        sellerOrder: "55358",
        courierAssigned: false,
        dispatchStatus: "confirm",
        deliveryStatus: "not_delivered",
        storeId: "62878032582e6500dc75ac17",
        storeName: "codingstorte.myshopify.com",
        orderId: "4785821417690",
        orderRefNumber: "#1008-CodingStorte",
        cancel_reason: null,
        cancelled_at: null,
        created_at: "2022-05-23T17:22:48+05:00",
        current_total_price: "114.84",
        financial_status: "paid",
        fulfillment_status: null,
        name: "#1008",
        note: null,
        order_number: 1008,
        processed_at: "2022-05-23T17:22:48+05:00",
        subtotal_price: "99.00",
        total_price: "114.84",
        total_price_set: {
          shop_money: {
            amount: "114.84",
          },
        },
        total_weight: 0,
        updated_at: "2022-05-23T17:22:49+05:00",
        line_items: [
          {
            id: 12229918916826,
            price: "70.00",
            quantity: 1,
            sku: "AD-03-black-OS",
            title: "ADIDAS | CLASSIC BACKPACK",
          },
          {
            id: 12229918949594,
            price: "29.00",
            quantity: 1,
            sku: "VN-01-burgandy-4",
            title: "VANS |AUTHENTIC | LO PRO | BURGANDY/WHITE",
          },
        ],
        shipmentTracking: [],
        shipperDetail: [
          {
            shopDetail: {
              name: "CodingStorte",
              email: "usamariaz2844@gmail.com",
              domain: "codingstorte.myshopify.com",
              address1: "Punjab Pakistan",
              city: "Faisalabad",
              phone: "",
              country_name: "Pakistan",
            },
          },
        ],
      },
      {
        _id: "628e09554c50fcd10046757d",
        courier: "",
        seller: "Umar",
        sellerOrder: "121358",
        courierAssigned: false,
        dispatchStatus: "confirm",
        deliveryStatus: "not_delivered",
        storeId: "62878032582e6500dc75ac17",
        storeName: "codingstorte.myshopify.com",
        orderId: "4787435471066",
        orderRefNumber: "#1013-CodingStorte",
        cancel_reason: null,
        cancelled_at: null,
        created_at: "2022-05-25T15:47:46+05:00",
        current_total_price: "114.84",
        financial_status: "paid",
        fulfillment_status: null,
        name: "#1013",
        note: null,
        order_number: 1013,
        processed_at: "2022-05-25T15:47:46+05:00",
        subtotal_price: "99.00",
        total_price: "114.84",
        total_price_set: {
          shop_money: {
            amount: "114.84",
          },
        },
        total_weight: 0,
        updated_at: "2022-05-25T15:47:47+05:00",
        line_items: [
          {
            id: 12233094725850,
            price: "70.00",
            quantity: 1,
            sku: "AD-03-black-OS",
            title: "ADIDAS | CLASSIC BACKPACK",
          },
          {
            id: 12233094758618,
            price: "29.00",
            quantity: 1,
            sku: "VN-01-burgandy-4",
            title: "VANS |AUTHENTIC | LO PRO | BURGANDY/WHITE",
          },
        ],
        shipmentTracking: [],
        shipperDetail: [
          {
            shopDetail: {
              name: "CodingStorte",
              email: "usamariaz2844@gmail.com",
              domain: "codingstorte.myshopify.com",
              address1: "Punjab Pakistan",
              city: "Faisalabad",
              phone: "",
              country_name: "Pakistan",
            },
          },
        ],
      },
      {
        _id: "628b7c9c4c50fcd100467422",
        courier: "",
        seller: "Zaghum",
        sellerOrder: "55358",
        courierAssigned: false,
        dispatchStatus: "confirm",
        deliveryStatus: "not_delivered",
        storeId: "62878032582e6500dc75ac17",
        storeName: "codingstorte.myshopify.com",
        orderId: "4785821417690",
        orderRefNumber: "#1008-CodingStorte",
        cancel_reason: null,
        cancelled_at: null,
        created_at: "2022-05-23T17:22:48+05:00",
        current_total_price: "114.84",
        financial_status: "paid",
        fulfillment_status: null,
        name: "#1008",
        note: null,
        order_number: 1008,
        processed_at: "2022-05-23T17:22:48+05:00",
        subtotal_price: "99.00",
        total_price: "114.84",
        total_price_set: {
          shop_money: {
            amount: "114.84",
          },
        },
        total_weight: 0,
        updated_at: "2022-05-23T17:22:49+05:00",
        line_items: [
          {
            id: 12229918916826,
            price: "70.00",
            quantity: 1,
            sku: "AD-03-black-OS",
            title: "ADIDAS | CLASSIC BACKPACK",
          },
          {
            id: 12229918949594,
            price: "29.00",
            quantity: 1,
            sku: "VN-01-burgandy-4",
            title: "VANS |AUTHENTIC | LO PRO | BURGANDY/WHITE",
          },
        ],
        shipmentTracking: [],
        shipperDetail: [
          {
            shopDetail: {
              name: "CodingStorte",
              email: "usamariaz2844@gmail.com",
              domain: "codingstorte.myshopify.com",
              address1: "Punjab Pakistan",
              city: "Faisalabad",
              phone: "",
              country_name: "Pakistan",
            },
          },
        ],
      },
      {
        _id: "628e09554c50fcd10046757d",
        courier: "",
        seller: "Umar",
        sellerOrder: "121358",
        courierAssigned: false,
        dispatchStatus: "confirm",
        deliveryStatus: "not_delivered",
        storeId: "62878032582e6500dc75ac17",
        storeName: "codingstorte.myshopify.com",
        orderId: "4787435471066",
        orderRefNumber: "#1013-CodingStorte",
        cancel_reason: null,
        cancelled_at: null,
        created_at: "2022-05-25T15:47:46+05:00",
        current_total_price: "114.84",
        financial_status: "paid",
        fulfillment_status: null,
        name: "#1013",
        note: null,
        order_number: 1013,
        processed_at: "2022-05-25T15:47:46+05:00",
        subtotal_price: "99.00",
        total_price: "114.84",
        total_price_set: {
          shop_money: {
            amount: "114.84",
          },
        },
        total_weight: 0,
        updated_at: "2022-05-25T15:47:47+05:00",
        line_items: [
          {
            id: 12233094725850,
            price: "70.00",
            quantity: 1,
            sku: "AD-03-black-OS",
            title: "ADIDAS | CLASSIC BACKPACK",
          },
          {
            id: 12233094758618,
            price: "29.00",
            quantity: 1,
            sku: "VN-01-burgandy-4",
            title: "VANS |AUTHENTIC | LO PRO | BURGANDY/WHITE",
          },
        ],
        shipmentTracking: [],
        shipperDetail: [
          {
            shopDetail: {
              name: "CodingStorte",
              email: "usamariaz2844@gmail.com",
              domain: "codingstorte.myshopify.com",
              address1: "Punjab Pakistan",
              city: "Faisalabad",
              phone: "",
              country_name: "Pakistan",
            },
          },
        ],
      },
    ]);
  }, []);

  const getCurrentCityCourier = (destinationCity = " ") => {
    destinationCity = destinationCity.toLowerCase().trim();
    let selectedCourier = null;
    for (const courierOfCities in citiesCourier) {
      const cities = citiesCourier[courierOfCities];
      const cityMatched = cities.some(
        (city) => city.toLowerCase().trim() === destinationCity
      );
      if (cityMatched) {
        selectedCourier = courierOfCities;
      }
    }

    if (dataDefaultCourier && !selectedCourier) {
      selectedCourier = Object.keys(dataDefaultCourier)[0];
    }
    return selectedCourier;
  };

  const setNameValue = async (newName) => {
    if (header === "name") {
      updateShippingConsigneeName(newName, cityOrderId);
    }
    if (header === "address") {
      updateShippingConsigneeAddress(newName, cityOrderId);
    }
    if (header === "phone") {
      updateShippingConsigneePhone(newName, cityOrderId);
    }
  };

  const setValue = async (cityName) => {
    setDisabledCity(true);
    try {
      if (createOption === true) {
        await updateShippingCityFromOrders(newCity, cityOrderId);

        setCreateOption(false);
      } else {
        // console.log({ newCity, cityName, cityOrderId });

        await saveVariants(newCity, cityName, cityOrderId);
      }
      // const updatedCity = data && data.shipping_address.city
      setCityOrderId();
      setDisabledCity(false);
      if (newCity !== undefined) {
        setEditCityName({ orderId: cityOrderId, cityName: newCity });
      }

      const selectedCourier = getCurrentCityCourier(newCity);
      setCourierAfterCityUpdate({
        orderId: cityOrderId,
        courier: selectedCourier,
      });
      const shipmentOption = shipmentOptionsDDRef.current.value;
      // update unfulfillled orders state to reflect change in to update courier in dropdowns

      const updatedCourierUnfulfilledInOrders = unfulfilledOrders.map(
        (order) => {
          if (order.orderId === cityOrderId) {
            return {
              ...order,
              courier: selectedCourier,
              shipmentType: order.shipmentType
                ? order.shipmentType
                : shipmentOption,
              shipping_address: { ...order.shipping_address, city: newCity },
            };
          }
          return order;
        }
      );

      setUnfulfilledOrders(updatedCourierUnfulfilledInOrders);

      // update checked orders state to update courier
      if (selectedOrdersInBulk.length > 0) {
        const updatedCourierInSelectedOrders = selectedOrdersInBulk.map(
          (selectedOrder) => {
            if (selectedOrder.orderId === cityOrderId) {
              return {
                ...selectedOrder,
                courier: selectedCourier,
                shipmentType: selectedOrder.shipmentType
                  ? selectedOrder.shipmentType
                  : shipmentOption,
                shipping_address: {
                  ...selectedOrder.shipping_address,
                  city: newCity,
                },
              };
            }
            return selectedOrder;
          }
        );
        setSelectedOrdersInBulk(updatedCourierInSelectedOrders);
      }
    } catch (error) {
      setCityOrderId();
      setDisabledCity(false);
    }
    // loadOrders({});
  };

  const submitOnEnter = (e, cityName) => {
    if (e.key === "Enter") {
      setValue(cityName);
    }
  };

  const findCity = async (checkNewCity) => {
    setNewCity(checkNewCity);
    let checkCityInDB = await cities.find(
      (checkCity) => checkCity.label === checkNewCity
    );
    if (!checkCityInDB) {
      setCreateOption(true);
    } else {
      setCreateOption(false);
    }
  };

  const consigneeDetail = (item) => {
    let orderItems = item.slice(0, 30);
    let itemsLength = item.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 20 ? "...." : ""}`}
          {itemsLength > 20 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="consigneeDetailPopover"
              onMouseEnter={() => {
                setConsigneeDetails([item]);
              }}
            />
          ) : (
            ""
          )}

          <ConsigneeDetails
            consigneeDetails={consigneeDetails && consigneeDetails}
          />
        </div>
      </>
    );
  };

  const orderDetails = (items) => {
    let orderItems = items[0].title.slice(0, 30);
    let itemsLength = items[0].title.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 20 ? "...." : ""}`}

          {itemsLength > 20 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="orderDetailPopover"
              onMouseEnter={() => {
                setOrderDetailsData(items);
              }}
            />
          ) : (
            ""
          )}
          <OrdersDetailsPopup
            orderDetailsData={orderDetailsData && orderDetailsData}
          />
        </div>
      </>
    );
  };

  const onCheckItem = (event, orderId) => {
    const orderDispatchStatus = dispatchStatusDDRef.current.value;
    setCheckboxes({ ...checkboxes, [orderId]: event.target.checked });
    let checkedOrders = [];
    unfulfilledOrders.forEach((order) => {
      if (order.orderId === orderId) {
        checkedOrders.push({ ...order, dispatchStatus: orderDispatchStatus });
      }
    });
    // add checked order to list of selected orders.
    if (event.target.checked) {
      try {
        if (
          checkedOrders[0].name &&
          checkedOrders[0].created_at &&
          checkedOrders[0].line_items &&
          checkedOrders[0].line_items[0].title &&
          checkedOrders[0].shipping_address &&
          checkedOrders[0].shipping_address.address1 &&
          checkedOrders[0].shipping_address &&
          checkedOrders[0].shipping_address.phone &&
          checkedOrders[0].shipping_address &&
          checkedOrders[0].shipping_address.city &&
          checkedOrders[0].shipping_address &&
          checkedOrders[0].shipping_address.name &&
          checkedOrders[0].total_price_set &&
          checkedOrders[0].total_price_set.shop_money &&
          checkedOrders[0].total_price_set.shop_money.amount &&
          checkedOrders[0].storeName &&
          checkedOrders[0].storeName.split("")[0]
        ) {
          setSelectedOrdersInBulk([...selectedOrdersInBulk, checkedOrders[0]]);
        } else {
          setInvalidOrders([...invalidOrders, checkedOrders[0]]);
        }
      } catch (err) {
        setInvalidOrders([...invalidOrders, checkedOrders[0]]);
      }
    }
    // remove on uncheck
    else {
      if (selectedOrdersInBulk.length > 0) {
        const removeUnCheckedOrder = selectedOrdersInBulk.filter(
          (order) => order.orderId !== orderId
        );
        setSelectedOrdersInBulk(removeUnCheckedOrder);
      }
      if (invalidOrders.length > 0) {
        const removeUnCheckedOrder = invalidOrders.filter(
          (order) => order.orderId !== orderId
        );
        setInvalidOrders(removeUnCheckedOrder);
      }
    }
    if (event.target.checked && orderId) {
      setCheckCounter(checkCounter + 1);
    } else if (!event.target.checked) {
      setCheckCounter(checkCounter - 1);
    }
  };

  const resetFilters = async () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedDateFilter(null);
    setUnFulfilledOrdersLoaded(true);
    // await loadOrders({});
  };

  const onCheckAllOrders = (e, ordersToMark) => {
    setIsAllOrdersChecked(e.target.checked);
    const orderIdsToMark = {};
    const ordersCompleteData = [];
    ordersToMark.forEach((order) => {
      orderIdsToMark[order.id] = e.target.checked;
      const matchedOrderInAllOrders = unfulfilledOrders.find(
        (completeOrder) => completeOrder.orderId === order.id
      );
      ordersCompleteData.push({ ...matchedOrderInAllOrders });
    });

    const valid = ordersCompleteData.filter(
      (v) =>
        v.name &&
        v.created_at &&
        v.line_items &&
        v.line_items[0].title &&
        v.shipping_address &&
        v.shipping_address.address1 &&
        v.shipping_address &&
        v.shipping_address.phone &&
        v.shipping_address &&
        v.shipping_address.city &&
        v.shipping_address &&
        v.shipping_address.name &&
        v.total_price_set &&
        v.total_price_set.shop_money &&
        v.total_price_set.shop_money.amount &&
        v.storeName &&
        v.storeName.split("")[0]
    );

    let unique1 = valid.filter((o) => ordersCompleteData.indexOf(o) === -1);
    let unique2 = ordersCompleteData.filter((o) => valid.indexOf(o) === -1);
    const invalid = unique1.concat(unique2);

    // add checked order to list of selected orders.
    if (e.target.checked) {
      setCheckboxes(orderIdsToMark);
      setSelectedOrdersInBulk(valid);
      setInvalidOrders(invalid);
      setCheckCounter(ordersCompleteData.length);
    }
    // remove on uncheck
    else {
      if (selectedOrdersInBulk.length > 0 || invalid.length > 0) {
        setCheckboxes({});
        setSelectedOrdersInBulk([]);
        setInvalidOrders([]);
        setCheckCounter(selectedOrdersInBulk.length + invalid.length);
      }
    }
    if (!e.target.checked) {
      return setCheckCounter(0);
    }
  };

  // const handleOrderDispatchStatus = (e, data) => {
  //   e.preventDefault();
  //   setOptionSelected(true);
  //   const dispatchAction = e.target.value.toLowerCase();
  //   const ordersWithDispatchStatusUpdated = selectedOrdersInBulk.map(
  //     (selectedOrder) => {
  //       if (selectedOrder.orderId === data.orderId) {
  //         return { ...selectedOrder, dispatchStatus: dispatchAction };
  //       }
  //       return selectedOrder;
  //     }
  //   );
  //   setSelectedOrdersInBulk([...ordersWithDispatchStatusUpdated]);

  //   const invalidOrdersWithDispatchStatusUpdated = invalidOrders.map(
  //     (selectedOrder) => {
  //       if (selectedOrder.orderId === data.orderId) {
  //         return { ...selectedOrder, dispatchStatus: dispatchAction };
  //       }
  //       return selectedOrder;
  //     }
  //   );
  //   setInvalidOrders([...invalidOrdersWithDispatchStatusUpdated]);
  // };

  // const handleOrderStatus = (e) => {
  //   setSelectedDispatchStatus(e.label);
  //   let dispatchAction = e.value.toLowerCase();
  //   setSelectedDispatchStatus(e.value);
  //   const ordersWithDispatchStatusUpdated = selectedOrdersInBulk.map((data) => {
  //     return { ...data, dispatchStatus: dispatchAction };
  //   });
  //   setSelectedOrdersInBulk([...ordersWithDispatchStatusUpdated]);

  //   const invalidOrdersWithDispatchStatusUpdated = invalidOrders.map((data) => {
  //     return { ...data, dispatchStatus: dispatchAction };
  //   });
  //   setInvalidOrders([...invalidOrdersWithDispatchStatusUpdated]);
  // };

  // const handleOrderSubmit = async () => {
  //   if (Object.keys(checkboxes).length > 0) {
  //     let selectedValid = selectedOrdersInBulk.filter(
  //       (order) => order.dispatchStatus !== ""
  //     );
  //     let selectedInvalid = invalidOrders.filter(
  //       (order) => order.dispatchStatus !== ""
  //     );

  //     let unSelectedValid = selectedOrdersInBulk.filter(
  //       (order) => order.dispatchStatus === ""
  //     );
  //     let unSelectedInvalid = invalidOrders.filter(
  //       (order) => order.dispatchStatus === ""
  //     );

  //     if (invalidOrders.length > 0) {
  //       if (unSelectedInvalid.length > 0) {
  //         unSelectedInvalid.map((v) =>
  //           toast.info(`Please Select an Action for Order ${v.name}`)
  //         );
  //       }
  //       if (selectedInvalid.length > 0) {
  //         selectedInvalid.map((invalid) =>
  //           toast.error(`Order with Id ${invalid.name} is invalid!!`)
  //         );
  //       }
  //     }

  //     if (selectedOrdersInBulk.length > 0) {
  //       if (unSelectedValid.length > 0) {
  //         unSelectedValid.map((v) =>
  //           toast.info(`Please Select an Action for Order ${v.name}`)
  //         );
  //       }
  //       if (selectedValid.length > 0) {
  //         if (
  //           (selectedDispatchStatus && isAllOrdersChecked) ||
  //           optionSelected
  //         ) {
  //           setAreSelectedOrdersProcessing(true);
  //           try {
  //             const confirmedOrders = [];
  //             if (selectedValid.length > 0) {
  //               for (const orderToConfirm of selectedValid) {
  //                 const {
  //                   orderId,
  //                   dispatchStatus,
  //                   storeId,
  //                   current_total_price,
  //                 } = orderToConfirm;
  //                 if (dispatchStatus === "confirm") {
  //                   await saveBillingLogs({ storeId, current_total_price });
  //                 }
  //                 const orderDispatchStatusUpdateResult =
  //                   await updateOrderDispatchStatus({
  //                     orderId,
  //                     dispatchStatus,
  //                     storeId,
  //                   });
  //                 if (orderDispatchStatusUpdateResult.status === 200) {
  //                   dispatchStatus === "confirm" && setOrderConfirmed(true);
  //                   dispatchStatus === "pending" && setOrderPending(true);
  //                   dispatchStatus === "cancel" && setOrderCancelled(true);
  //                   dispatchStatus === "manual" && setOrderManual(true);
  //                   confirmedOrders.push(orderToConfirm.orderId);
  //                 }
  //               }
  //             }

  //             if (confirmedOrders.length > 0) {
  //               const remainingOpenOrders = unfulfilledOrders.filter(
  //                 (unfulfilledOrder) =>
  //                   !confirmedOrders.includes(unfulfilledOrder.orderId)
  //               );
  //               setUnfulfilledOrders(remainingOpenOrders);
  //               setSelectedOrdersInBulk([]);
  //               setInvalidOrders([]);
  //               setAreSelectedOrdersProcessing(false);
  //               setCheckCounter(0);
  //               setCheckboxes({});
  //               setIsAllOrdersChecked(false);
  //               setOptionSelected(false);
  //               dispatchStatusDDRef.current = "";
  //               console.log("Confirm Orders: ", confirmedOrders);
  //             }
  //           } catch (e) {
  //             toast.error(`${e.message}`);
  //           }
  //         } else {
  //           toast.info("Please select action to perform on selected orders");
  //         }
  //       }
  //     }
  //   } else {
  //     toast.info("Please select orders!");
  //   }
  // };

  const changeButton = (orderId, Inputheader) => {
    setCityOrderId(orderId);
    setHeader(Inputheader);
  };

  const cancelEditCityMenu = () => {
    setCityOrderId();
    setCreateOption(false);
  };

  const ChangeCityName = (cityName, orderId, Inputheader) => {
    if (orderId === cityOrderId && header === Inputheader) {
      return (
        <>
          {disabledCity === false ? (
            <div className=" d-flex justify-content-center">
              <CreatableSelect
                key={orderId}
                options={cities}
                formatCreateLabel={() => "This is Correct"}
                defaultValue={{
                  label: cityName,
                  value: cityName.toLowerCase(),
                }}
                onKeyDown={() => submitOnEnter(cityName)}
                onChange={(e) => {
                  findCity(e.label);
                }}
              />
              <Button
                onClick={(e) => {
                  setValue(newCity);

                  setCreateOption(false);
                }}
                color="primary"
                size="xs"
                className="mr-2"
                hidden={createOption === false}
              >
                Add Anyway
              </Button>

              {newCity !== cityName && (
                <span
                  className="material-icons-outlined"
                  style={{
                    marginTop: "8px",
                    marginRight: "5px",
                    marginLeft: "7px",
                    float: "center",
                    color: "green",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  hidden={createOption === true}
                  onClick={() => {
                    setValue(cityName);
                  }}
                >
                  done
                </span>
              )}

              <span
                className="material-icons-outlined ms-1"
                onClick={cancelEditCityMenu}
                style={{
                  marginTop: "6px",
                  float: "center",
                  color: "red",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                close
              </span>
            </div>
          ) : (
            <>
              <Select key={orderId} options={cities} isDisabled />
              <Spinner size="sm" color="primary" />
            </>
          )}
        </>
      );
    } else {
      return (
        <div className="">
          {Object.keys(editCityName).length > 0 &&
          editCityName.orderId === orderId
            ? editCityName.cityName
            : cityName}
          <svg
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeButton(orderId, Inputheader);
              setNewCity(cityName);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil mx-2"
            viewBox="0 0 16 16"
          >
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
          </svg>
        </div>
      );
    }
  };

  const ChangeInputFields = (
    inputName,
    orderId,
    Inputheader,
    adressName,
    OrderNo,
    ShipDetail
  ) => {
    if (orderId === cityOrderId && Inputheader === header) {
      return (
        <div className=" d-flex">
          <Input
            onChange={(e) => {
              setNewCity(e.target.value);
            }}
            defaultValue={inputName}
          />
          {newCity !== inputName && (
            <span
              className="material-icons-outlined ms-2 me-1"
              style={{
                marginTop: "6px",
                float: "center",
                marginRight: "13px",
                color: "green",
                fontSize: "20px",
                cursor: "pointer",
              }}
              hidden={createOption === true}
              onClick={() => {
                // setValue(cityName);
                setNameValue(newCity);
              }}
            >
              done
            </span>
          )}

          <span
            className="material-icons-outlined ms-1"
            onClick={cancelEditCityMenu}
            style={{
              marginTop: "8px",
              float: "center",
              color: "red",
              fontSize: "20px",
              cursor: "pointer",
            }}
          >
            close
          </span>
        </div>
      );
    } else {
      return (
        <div
          className={"d-flex justify-content-center ms-2 align-items-center"}
        >
          {Inputheader === "address" && consigneeDetail(inputName)}

          {Inputheader === "phone" &&
            formatPhoneNumberWithButtons(
              inputName,
              adressName,
              OrderNo,
              ShipDetail,
              tooltipOpen,
              toggle
            )}
          {Inputheader === "name" && inputName}

          <svg
            onClick={() => {
              changeButton(orderId, Inputheader);
              setNewCity(inputName);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil mx-2 pointer"
            viewBox="0 0 16 16"
          >
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
          </svg>
        </div>
      );
    }
  };

  const ordersData =
    unfulfilledOrders &&
    unfulfilledOrders.length > 0 &&
    unfulfilledOrders
      .filter((order) => {
        if (search === "") {
          return order;
        } else if (order.hasOwnProperty("shipping_address")) {
          return order.shipping_address.name
            ? order.shipping_address.name
                .toLowerCase()
                .includes(search.toLowerCase())
            : "";
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.address1
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return order;
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.phone
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return order;
        } else if (order.name.toLowerCase().includes(search.toLowerCase())) {
          return order;
        }
        return false;
      })
      .map((order, i) => {
        // const consigneeAdress = consigneeDetail(
        //   order.shipping_address.address1
        // );
        return {
          id: order.orderId,
          orderDate: DateFormatted(order.created_at, i + 1),
          name: order.shipping_address
            ? ChangeInputFields(
                order.shipping_address.name === undefined
                  ? "N/A"
                  : order.shipping_address.name,
                order.orderId,
                "name"
              )
            : "N/A",
          amount: Math.trunc(
            order.total_price_set.shop_money.amount
          ).toLocaleString(),
          referenceNo: order.orderId,
          details: orderDetails(order.line_items),
          remarks: order.note ? order.note : "none",
          address: order.shipping_address?.address1
            ? ChangeInputFields(
                order.shipping_address.address1,
                order.orderId,
                "address"
              )
            : ChangeInputFields("N/A", order.orderId, "address"),
          phone: order?.shipping_address?.phone
            ? ChangeInputFields(
                order.shipping_address.phone,
                // formatPhoneNumberWithButtons(
                //   order.shipping_address.name,
                //   order.order_number,
                //   order.shipperDetail[0].shopDetail.domain,
                //   tooltipOpen,
                //   toggle
                // ),
                order.orderId,
                "phone",
                order.shipping_address.name,
                order.order_number,
                order.shipperDetail[0].shopDetail.domain
              )
            : ChangeInputFields("N/A", order.orderId, "phone"),
          orderId: order.name,
          items:
            order.line_items && order.line_items.length > 0
              ? showItemDetails(
                  order.line_items,
                  setItemDetailsData,
                  itemDetailsData
                )
              : "N/A",
          city: order.shipping_address
            ? ChangeCityName(order.shipping_address.city, order.orderId)
            : ChangeCityName("N/A", order.orderId),
          storeName: order.storeName.split(".")[0],
          storeId: order.storeId,
          seller: order.seller,
          sellerOrder: order.sellerOrder,
          updated_at: order.updated_at,
          created_at: order.created_at,
          actions: (
            <select
              key={order.orderId}
              // onChange={(e) => handleOrderDispatchStatus(e, order)}
              className={"form-control form-select w-75 ml-3"}
              // style={{height:'28px'}}
              ref={dispatchStatusDDRef}
              disabled={checkboxes && checkboxes[order.orderId] ? false : true}
            >
              <option selected disabled value="">
                Select
              </option>
              <option value="Confirm">Confirm</option>
              <option value="Pending">Pending</option>
              <option value="Cancel">Cancel</option>
              <option value="manual">Manual Book</option>
            </select>
          ),
        };
      });

  const cityOrderData =
    unfulfilledOrders &&
    unfulfilledOrders.length > 0 &&
    unfulfilledOrders
      .filter((order) => {
        if (citySearch === "") {
          return order;
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.city &&
          order.shipping_address.city
            .toLowerCase()
            .includes(citySearch.toLowerCase())
        ) {
          return order;
        }
        return false;
      })
      .map((order, i) => ({
        id: order.orderId,
        orderDate: DateFormatted(order.created_at, i + 1),
        name: order.shipping_address
          ? ChangeInputFields(
              order.shipping_address.name === undefined
                ? "N/A"
                : order.shipping_address.name,
              order.orderId,
              "name"
            )
          : "N/A",
        address: order.shipping_address?.address1
          ? ChangeInputFields(
              order.shipping_address.address1,
              order.orderId,
              "address"
            )
          : ChangeInputFields("N/A", order.orderId, "address"),
        phone: order?.shipping_address?.phone
          ? ChangeInputFields(
              order.shipping_address.phone,
              // formatPhoneNumberWithButtons(
              //   order.shipping_address.name,
              //   order.order_number,
              //   order.shipperDetail[0].shopDetail.domain,
              //   tooltipOpen,
              //   toggle
              // ),
              order.orderId,
              "phone",
              order.shipping_address.name,
              order.order_number,
              order.shipperDetail[0].shopDetail.domain
            )
          : ChangeInputFields("N/A", order.orderId, "phone"),
        amount: Math.trunc(
          order.total_price_set.shop_money.amount
        ).toLocaleString(),
        referenceNo: order.orderId,
        details: orderDetails(order.line_items),
        remarks: order.note ? order.note : "none",
        orderId: order.name,
        items:
          order.line_items && order.line_items.length > 0
            ? showItemDetails(
                order.line_items,
                setItemDetailsData,
                itemDetailsData
              )
            : "N/A",
        city: order.shipping_address
          ? ChangeCityName(order.shipping_address.city, order.orderId)
          : ChangeCityName("N/A", order.orderId),
        // city: order.shipping_address ?
        //   ChangeCityName(order.shipping_address.city, order.orderId) : "N/A",
        city: order.shipping_address
          ? ChangeCityName(order.shipping_address.city, order.orderId)
          : ChangeCityName("N/A", order.orderId),
        storeName: order.storeName.split(".")[0],
        storeId: order.storeId,
        seller: order.seller,
        sellerOrder: order.sellerOrder,
        updated_at: order.updated_at,
        created_at: order.created_at,
        actions: (
          <select
            key={order.orderId}
            // onChange={(e) => handleOrderDispatchStatus(e, order)}
            className={"form-control form-select w-75 ml-3"}
            ref={dispatchStatusDDRef}
            disabled={checkboxes && checkboxes[order.orderId] ? false : true}
          >
            <option selected disabled value="">
              Select
            </option>
            <option value="Confirm">Confirm</option>
            <option value="Pending">Pending</option>
            <option value="Cancel">Cancel</option>
            <option value="manual">Manual Book</option>
          </select>
        ),
      }));

  const cols = React.useMemo(
    () => [
      {
        Header: "Order#",
        accessor: "orderId",
        cellClass: "table-text w-3 text-center",
        Cell: (props) => <d>{props.value}</d>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: "table-text w-10 ",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: "table-text w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: "table-text w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: "table-text w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "COD",
        accessor: "amount",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      // {
      //   Header: 'Product Details',
      //   accessor: 'details',
      //   cellClass: 'table-text w-6',
      //   Cell: (props) => <>{props.value}</>,
      // },
      {
        Header: "Items(Count Only)",
        accessor: "items",
        cellClass: "table-text w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: "table-text w-12",
        Cell: (props) => <>{props.value}</>,
      },
      // {
      //   Header: 'Store',
      //   accessor: 'storeName',
      //   cellClass: 'table-text w-6',
      //   Cell: (props) => <>{props.value}</>,
      // },
      {
        Header: "Seller",
        accessor: "seller",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "SellerOrder#",
        accessor: "sellerOrder",
        cellClass: "table-text w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Actions",
        accessor: "actions",
        cellClass: "table-text w-10",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  return (
    <>
      {unFulfilledOrdersLoaded && unfulfilledOrders.length <= 0 ? (
        <Card className="my-3 mr-5 py-5">
          <CardBody
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h4 style={{ color: "red" }}>
              <b>No New Order Recieved!</b>
            </h4>
            {showResetButton === false ? (
              ""
            ) : (
              <Button
                color="primary"
                className="mt-1 btn-sm"
                onClick={resetFilters}
              >
                Reset Data
              </Button>
            )}
          </CardBody>
        </Card>
      ) : !unFulfilledOrdersLoaded && unfulfilledOrders.length === 0 ? (
        <>
          <div className="loading" />
          <div
            style={{
              height: "70vh",
            }}
          ></div>
        </>
      ) : (
        <Row>
          <Cols xxs="12">
            <Card className="mb-4 fullflmnt-tabs">
              <CardBody className={{ padding: "0px" }}>
                <CardTitle className="order_table_card_title">
                  <div className="container fullflmnt-tabs-align">
                    <div className="search-sm mb-2 global-search-font">
                      <input
                        className="mb-2"
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          if (e) {
                            setSearch(e.target.value);
                            setSearchNameBool(true);
                          } else {
                            setSearchNameBool(false);
                          }
                        }}
                      />
                    </div>
                    <div>
                      <DateFilters
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        selectedDateFilter={selectedDateFilter}
                        setSelectedDateFilter={setSelectedDateFilter}
                        setShowResetButton={setShowResetButton}
                        loadOrders={() => {}}
                        resetFilters={resetFilters}
                      />
                    </div>
                    <div className="city_search_container fullfilltabs-sm fullflmnt-tabs-align-start">
                      <div className="search-sm d-inline-block confirm-city-search mb-1 me-2">
                        <input
                          type="text"
                          name="keyword"
                          id="city_search"
                          placeholder="Search City"
                          value={citySearch}
                          onChange={(e) => {
                            if (e) {
                              setSearchCityBool(true);
                              setCitySearch(e.target.value);
                            } else {
                              setSearchCityBool(false);
                            }
                          }}
                        />
                      </div>
                      <div
                        className="openOrderSelect me-2 mb-2"
                        style={{ width: "140px" }}
                      >
                        <Select
                          placeholder="Actions"
                          defaultValue={selectedDispatchStatus}
                          options={statusDispatchOptions}
                          isDisabled={
                            selectedOrdersInBulk.length <= 1 &&
                            invalidOrders.length <= 1
                          }
                          // onChange={(e) => handleOrderStatus(e)}
                        />
                      </div>
                      <button
                        disabled={checkCounter === 0 ? true : false}
                        // onClick={handleOrderSubmit}
                        type="button"
                        className="btn btn-primary custom-btn"
                        style={{
                          height: "35.5px",
                          marginTop: "2px",
                        }}
                      >
                        Submit Order
                        {areSelectedOrdersProcessing ? (
                          <Spinner
                            style={{ width: "1rem", height: "1rem" }}
                            type="grow"
                            color="light"
                          />
                        ) : null}
                      </button>
                    </div>
                  </div>
                </CardTitle>
                {loading && (
                  <div className="mx-2" style={{ marginBottom: "-5px" }}>
                    <Alert color="success">
                      <Spinner
                        color="success"
                        size={"sm"}
                        style={{ marginBottom: "2px" }}
                      ></Spinner>{" "}
                      &nbsp;
                      <span style={{ fontSize: "15px" }}>Orders Loading!</span>
                    </Alert>
                  </div>
                )}

                {((cityOrderData.length === 0 && searchCityBool) ||
                  (ordersData.length === 0 && searchNameBool)) && (
                  <h4
                    style={{
                      color: "red",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <b>No Order Matched!</b>
                  </h4>
                )}

                {cityOrderData.length !== 0 && ordersData.length !== 0 && (
                  <DataTable
                    setPageLimitInParent={setPageLimit}
                    setPageNumberInParent={setPageNumber}
                    // fetchData={loadOrders}
                    pageCount={1}
                    columns={cols}
                    data={
                      citySearch !== ""
                        ? cityOrderData.sort(sortByDate)
                        : ordersData.sort(sortByDate)
                    }
                    onCheckItem={onCheckItem}
                    checkedOrders={checkboxes}
                    isAllOrdersChecked={isAllOrdersChecked}
                    onCheckAllOrders={onCheckAllOrders}
                    checkCounter={checkCounter}
                  />
                )}
              </CardBody>
            </Card>
          </Cols>
        </Row>
      )}
    </>
  );
};

export default ResellingAllOrders;
