import React, { useState, useMemo } from "react";
import { Card, CardBody, Row, Input, CardTitle, Button } from "reactstrap";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import { data } from "../data";
import DataTable from "../../tables/table";
import { DateFormatted } from "../../../helpers/dateTime";

const PendingPayments = () => {
  const [products, setProducts] = useState(data);
  const [filterData, setFilterData] = useState(products);
  const datas = filterData.length === 0 ? products : filterData;
  const [dateTime, setDateTime] = useState();

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const colsData = [
    {
      Header: "Date",
      accessor: "offerExpiry",
      cellClass: "text-muted w-15",
      Cell: (props) => <>{DateFormatted(props.value, props.value.id)}</>,
    },

    {
      Header: "Reseller",
      accessor: "vendor",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value === "" ? "NA" : props.value}</>,
    },
    {
      Header: "Amount",
      accessor: "saleAmount",
      cellClass: "text-muted",
      Cell: (props) => <>{numberWithCommas(props.value)}</>,
    },
    {
      Header: "Status",
      accessor: "status",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Balance",
      accessor: "balance",
      cellClass: "text-muted",
      Cell: (props) => <>{numberWithCommas(props.value)}</>,
    },
  ];

  const cols = useMemo(() => colsData, [colsData]);

  const handleSearch = (e) => {
    if (e.target.value.length === 0) {
      console.log({ first: e.target.value });
      setFilterData(data);
    } else {
      setFilterData(
        datas.filter((p) =>
          p.vendor.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  return (
    <>
      <Row>
        <Cols xxs="12">
          <Card>
            <CardBody>
              <CardTitle className="order_table_card_title mb-0">
                <div className="search-sm my-2 mr-2  global-search-font">
                  <Input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder="Search"
                    // value={search}
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </CardTitle>
              {filterData.length !== 0 ? (
                <DataTable
                  columns={cols}
                  data={filterData}
                  pageCount={1}
                  totalCount={data.length}
                />
              ) : (
                <div className="mt-4">
                  <div className="py-5 mt-3 text-center">
                    <h3 style={{ color: "red" }}>No Reseller Matched!</h3>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Cols>
      </Row>
    </>
  );
};

export default PendingPayments;
