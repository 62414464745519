import React, { useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Fade,
  FormGroup,
  Input,
  Label,
  PopoverBody,
  Spinner,
  Table,
  UncontrolledPopover,
} from "reactstrap";
import {
  listSellingProduct,
  getProductsForReselling,
} from "../../../api/sellingProducts.js.js";
import DataTable from "../../tables/table";
import { ResaleProductsFilters } from "./SellingProductsTableHeader";
import Offcanvas from "react-bootstrap/Offcanvas";

export const ListForResaleProducts = () => {
  let defaultShipRate = { fromPrice: "", toPrice: "", totalShipCharges: "" };
  let defaultSellingData = {
    pId: "",
    discount: "",
    inventory: "",
    threshold: "",
    sellingAmount: "",
    sellingSKU: "",
    shippingCharges: [defaultShipRate],
  };

  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [listingLoading, setListingLoading] = useState(false);
  const [vendorFilterValue, setVendorFilterValue] = useState("");
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [total, setTotal] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [masterCheck, setMasterCheck] = useState(false);
  const [proceedTo, setProceedTo] = useState(false);
  const [multiShippingCharges, setMultiShippingCharges] = useState([
    defaultShipRate,
  ]);

  const [sellingData, setSellingData] = useState(defaultSellingData);

  let totalPagesList = Math.ceil(total / pageLimit);

  const loadProductsForReselling = useCallback(
    async ({
      limit = pageLimit,
      page = pageNumber,
      title = searchValue,
      vendor = vendorFilterValue,
    }) => {
      setMasterCheck(false);
      setSelectedList([]);

      try {
        const { data } = await getProductsForReselling({
          limit,
          page,
          title,
          vendor,
        });

        if (data) {
          setProducts(data.data.products);
          setTotal(data.data.totalCount);
          // setAllVendors(data.data.allVendors);

          setLoading(false);
          setFilterLoading(false);
          setPaginationLoading(false);

          data.data.totalCount === 0 && !searchValue
            ? setEmptyStatus(true)
            : setEmptyStatus(false);
        }
      } catch (err) {
        toast.error("Resale Products data loading error!");
      }
    },
    [pageLimit, pageNumber, searchTrigger, vendorFilterValue]
  );

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    loadProductsForReselling({});
  }, [loadProductsForReselling]);

  const toTitleCase = (str) => {
    let replaced = str?.replace(
      /\w\S*/g,
      (txt) => txt?.charAt(0).toUpperCase() + txt?.substr(1).toLowerCase()
    );
    return replaced;
  };

  const getUnique = (array) => {
    let uniqueArray = [];

    // Loop through array values
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  };

  const vendorOptions = getUnique(allVendors.map((p) => p.vendor)).map((p) => {
    return { value: p, label: toTitleCase(p) };
  });

  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // const onItemCheck = (item, e, ind) => {
  //   let tempList = products;
  //   tempList.map((obj, index) => {
  //     if (index === ind) {
  //       obj.selected = e.target.checked;
  //     }
  //     return obj;
  //   });
  //   setSelectedList(tempList.filter((e) => e.selected));
  // };

  const handleSelect = (p, e, index) => {
    let tempList = products;
    tempList.map((obj, i) => {
      if (index === i) {
        obj.selected = e.target.checked;
      } else {
        obj.selected = false;
      }
      return obj;
    });
    setSelectedList(tempList.filter((e) => e.selected));
  };

  const handleMasterCheck = (e) => {
    let tempList = products;
    setMasterCheck(e.target.checked);
    tempList.map((obj) => (obj.selected = e.target.checked));
    setSelectedList(tempList.filter((e) => e.selected));
  };

  const handleCloseSideBar = () => {
    let tempList = products;
    setMasterCheck(false);
    tempList.map((obj) => (obj.selected = false));
    setSelectedList([]);
  };

  const blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  // const [resalePriceIn, setResalePriceIn] = useState('-');
  // const handleResalePrice = (e) => {
  //   setResalePriceIn(e.target.value);
  // };

  const handleResaleData = (e) => {
    let { name, value } = e.target;
    setSellingData({ ...sellingData, [name]: value });
  };

  const handleShipCharges = (e, index) => {
    let { name, value } = e.target;

    let setValue = multiShippingCharges;
    setValue.filter((obj, ind) => {
      if (ind === index) {
        setValue[ind] = { ...obj, [name]: value };
      }
    });
    setMultiShippingCharges([...setValue]);
    setSellingData({ ...sellingData, shippingCharges: setValue });
  };

  const handleListProductForResale = async (product) => {
    try {
      let response = await listSellingProduct(product);
      if (response.status === 201) {
        toast.success("Product listed successfully!");
        setSellingData(defaultSellingData);
        setMultiShippingCharges([defaultShipRate]);
        setProceedTo(false);
        setSelectedList([]);
        setListingLoading(false);
        setLoading(true);
        setPageLimit(50);
        setPageNumber(1);
        loadProductsForReselling({});
      } else {
        setListingLoading(false);
        toast.error(response.error);
      }
    } catch (err) {
      setListingLoading(false);
      toast.error("Internel server error!");
      console.log({ err });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let resaleProducts = selectedList.map((s) => {
      let product = {
        ...sellingData,
        pId: s._id,
        storeId: s?.storeId,
        sellingAmount: s?.variant?.price,
        currentQuantity: s?.inventory?.currentQuantity,
        sellingSKU: s?.variant?.sku,
        // discount: Number(s.variant.price) - Number(sellingData.discount),
        // inventory: (
        //   (Number(sellingData.inventory) / 100) *
        //   Number(s.inventory.currentQuantity)
        // ).toFixed(2),
      };
      return product;
    });

    setListingLoading(true);
    handleListProductForResale(resaleProducts[0]);
  };
  const ListForResaleRows = products.map((product, index) => {
    return {
      check: (
        <label className="custom-control mb-0 pointer">
          <Input
            className="item-check pointer"
            style={{
              border: "0.1px solid gray",
            }}
            type="checkbox"
            checked={product?.selected}
            label=""
            disabled={paginationLoading || filterLoading}
            onChange={(e) => handleSelect(product, e, index)}
          />
        </label>
      ),
      image: (
        <img
          src={product?.image?.src || "./img/products/no-image.png"}
          alt=""
          className="listingImage"
        />
      ),
      sku: product?.variant?.sku ? (
        <>
          {toTitleCase(product?.variant?.sku).slice(0, 20)}
          {product?.variant?.sku.length > 20 && " ..."}
          {product?.variant?.sku.length > 20 && (
            <i className="ms-2 simple-icon-info pointer" id={"sku" + index}>
              <UncontrolledPopover
                trigger="hover"
                placement="top"
                target={"sku" + index}
              >
                <PopoverBody className="text-center">
                  {product?.variant?.sku}
                </PopoverBody>
              </UncontrolledPopover>
            </i>
          )}
        </>
      ) : (
        "N/A"
      ),
      productName: (
        <>
          {/* <Link
            to={`/admin/editProduct/${product.shopifyProductId}`}
            style={{ color: 'grey' }}
          > */}
          {toTitleCase(product.title).slice(0, 20)}
          {product.title.length > 20 && " ..."}
          {product.title.length > 20 && (
            <i
              className="ms-2 simple-icon-info pointer"
              id={"Tooltip-" + index}
            >
              <UncontrolledPopover
                trigger="hover"
                placement="top"
                target={"Tooltip-" + index}
              >
                <PopoverBody className="text-center">
                  {product.title}
                </PopoverBody>
              </UncontrolledPopover>
            </i>
          )}
          {/* {product.title.length > 12 && ' ...'} */}
          {/* </Link> */}
        </>
      ),

      status: (
        <Badge
          pill
          color={
            product.status === "active"
              ? "success"
              : product.status === "draft"
              ? "warning"
              : "dark"
          }
        >
          {toTitleCase(product.status)}
        </Badge>
      ),

      type: product.type ? toTitleCase(product.type) : "N/A",
      category: product.category ? toTitleCase(product.category) : "N/A",
      storePrice: product?.variant.price
        ? numberWithCommas(product.variant.price)
        : "N/A",
      inventory: numberWithCommas(product.inventory.currentQuantity),
      dailySale: numberWithCommas(product.dailySale),
      vendor: product.vendor ? (
        <>
          {toTitleCase(product.vendor).slice(0, 20)}
          {product.vendor.length > 20 && " ..."}
          {product.vendor.length > 20 && (
            <i className="ms-2 simple-icon-info pointer" id={"Vendor-" + index}>
              <UncontrolledPopover
                trigger="hover"
                placement="top"
                target={"Vendor-" + index}
              >
                <PopoverBody className="text-center">
                  {toTitleCase(product.vendor)}
                </PopoverBody>
              </UncontrolledPopover>
            </i>
          )}
        </>
      ) : (
        "N/A"
      ),
      action: <span className="iconsminds-financial fs-5 fw-bold"></span>,
    };
  });

  let ListForResaleCols = [
    {
      Header: (
        <label className="custom-control mb-0 pointer align-self-center">
          <Input
            className="item-check pointer"
            // style={{ marginTop: '-20px', marginLeft: '13px' }}
            style={{
              border: "0.1px solid gray",
            }}
            type="checkbox"
            name="checkboxAllProducts"
            checked={masterCheck}
            disabled={filterLoading || paginationLoading}
          />
        </label>
      ),
      accessor: "check",
      Cell: (props) => props.value,
      cellClass: "w-1",
    },
    {
      Header: "Image",
      accessor: "image",
      cellClass: " text-muted w-1",
      Cell: (props) => props.value,
    },
    {
      Header: "SKU",
      accessor: "sku",
      cellClass: " w-12",
      Cell: (props) => props.value,
    },
    {
      Header: "Name",
      accessor: "productName",
      cellClass: " w-12",
      Cell: (props) => props.value,
    },
    {
      Header: "Status",
      accessor: "status",
      cellClass: " w-10",
      Cell: (props) => props.value,
    },
    {
      Header: "Type",
      accessor: "type",
      cellClass: " w-10",
      Cell: (props) => props?.value,
    },
    {
      Header: "Collection",
      accessor: "category",
      cellClass: "w-5",
      Cell: (props) => props?.value,
    },
    {
      Header: "Store Price",
      accessor: "storePrice",
      cellClass: "w-10",
      Cell: (props) => props.value,
    },
    {
      Header: "Current QTY",
      accessor: "inventory",
      cellClass: "w-10",
      Cell: (props) => props.value,
    },
    {
      Header: "Daily Sale",
      accessor: "dailySale",
      cellClass: "w-10",
      Cell: (props) => props.value,
    },
    {
      Header: "Vendor",
      accessor: "vendor",
      cellClass: "w-10",
      Cell: (props) => props.value,
    },
    {
      Header: "Resale",
      accessor: "action",
      cellClass: " w-5",
      Cell: (props) => props.value,
    },
  ];
  const ListForResaleColsMemo = useMemo(
    () => ListForResaleCols,
    [masterCheck, products, paginationLoading, filterLoading]
  );

  return (
    <>
      {loading ? (
        <div className=" position-relative" style={{ height: "60vh" }}>
          <span className=" loading position-absolute"></span>
        </div>
      ) : emptyStatus ? (
        <Card>
          <div
            className=" d-flex align-items-center justify-content-center text-danger"
            style={{ height: "30vh" }}
          >
            <h3 className="fw-bold">No Products Found!</h3>
          </div>
        </Card>
      ) : (
        <Card
          className={selectedList.length > 0 ? "w-70 transform-table" : ""}
          style={{ transition: "transform .5s" }}
        >
          <CardBody>
            {
              <ResaleProductsFilters
                setPageLimit={setPageLimit}
                setPageNumber={setPageNumber}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                setSearchTrigger={setSearchTrigger}
                searchTrigger={searchTrigger}
                vendorOptions={vendorOptions}
                vendorFilterValue={vendorFilterValue}
                setVendorFilterValue={setVendorFilterValue}
                setEmptyStatus={setEmptyStatus}
                filterLoading={filterLoading}
                setFilterLoading={setFilterLoading}
              />
            }

            {paginationLoading && (
              <div className="mt-3 mx-3">
                <Alert color="info">
                  <Spinner
                    color="light"
                    size={"sm"}
                    style={{ marginBottom: "3px" }}
                  ></Spinner>{" "}
                  &nbsp;
                  <span style={{ fontSize: "16px", color: "black" }}>
                    Products Loading!
                  </span>
                </Alert>
              </div>
            )}

            {filterLoading ? (
              <div className=" position-relative" style={{ height: "180px" }}>
                <span className=" position-absolute loading"></span>
              </div>
            ) : products.length === 0 ? (
              <div
                className=" d-flex align-items-center justify-content-center text-danger"
                style={{ height: "30vh" }}
              >
                <h3 className="fw-bold">No Products Matched!</h3>
              </div>
            ) : (
              <DataTable
                fetchData={loadProductsForReselling}
                columns={ListForResaleColsMemo}
                data={ListForResaleRows}
                setPageLimitInParent={setPageLimit}
                setPageNumberInParent={setPageNumber}
                pageCount={totalPagesList}
                setPaginationLoading={setPaginationLoading}
                paginationLoading={paginationLoading}
                totalCount={total}
              />
            )}
            {
              //---------------//
              <Offcanvas
                show={selectedList.length > 0}
                onHide={() => {
                  setProceedTo(false);
                  handleCloseSideBar();
                }}
                placement={"end"}
                scroll={true}
                backdrop={false}
              >
                <Offcanvas.Header closeButton className="px-3">
                  {proceedTo ? (
                    <h3 className="m-0">
                      List Products : {selectedList?.length}
                    </h3>
                  ) : (
                    <h3 className="m-0">
                      Total Products : {selectedList?.length}
                    </h3>
                  )}
                </Offcanvas.Header>
                {<hr className="mt-0 mb-2" />}
                {proceedTo ? (
                  <Fade>
                    <Offcanvas.Body>
                      <form
                        onSubmit={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        <div className="d-flex flex-column w-100">
                          <div style={{ minHeight: "75vh" }}>
                            <FormGroup>
                              <Label for="discountPrice">Discount</Label>
                              <Input
                                onChange={(e) => handleResaleData(e)}
                                required
                                onKeyDown={blockInvalidChar}
                                key={null}
                                value={sellingData.discount}
                                id="discountPrice"
                                name="discount"
                                placeholder="0.00"
                                type="number"
                                // max={selectedList[0].variant.price}
                                min={"0"}
                              />
                              {/* <FormGroup check inline>
                          <Input
                            type="radio"
                            className="fs-6 pointer"
                            value={'+'}
                            checked={resalePriceIn === '+'}
                            onChange={handleResalePrice}
                          />
                          <Label check>Price in {'(+)'}</Label>
                        </FormGroup> */}
                              {/* <FormGroup check inline>
                          <Input
                            type="radio"
                            className="fs-6 pointer"
                            value={'-'}
                            checked={resalePriceIn === '-'}
                            onChange={handleResalePrice}
                          />
                          <Label check>Price in {'(-)'}</Label>
                        </FormGroup> */}
                            </FormGroup>
                            <FormGroup>
                              <Label for="inventory">Inventory {"(%)"}</Label>
                              <Input
                                onChange={(e) => handleResaleData(e)}
                                required
                                onKeyDown={blockInvalidChar}
                                value={sellingData.inventory}
                                id="inventory"
                                name="inventory"
                                placeholder="Inventory"
                                type="number"
                                min={"1"}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="threshold">Threshold</Label>
                              <Input
                                onChange={(e) => handleResaleData(e)}
                                required
                                onKeyDown={blockInvalidChar}
                                value={sellingData.threshold}
                                id="threshold"
                                name="threshold"
                                placeholder="Threshold"
                                type="number"
                                min={"1"}
                              />
                            </FormGroup>
                            <Label for="threshold" className="">
                              Shipping Charges
                            </Label>
                            <div
                              className=" overflow-auto customScroll"
                              style={{ minHeight: "200px", maxHeight: "200px" }}
                            >
                              <Table borderless responsive className="">
                                <thead>
                                  <tr>
                                    <th className="m-0 p-1 text-start">
                                      From Price
                                    </th>
                                    <th className="m-0 p-1 text-start">
                                      To Price
                                    </th>
                                    <th className="m-0 p-1 text-start">
                                      Total Price
                                    </th>
                                    <th className="m-0 p-1 text-start"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {multiShippingCharges.map((v, i) => (
                                    <tr key={i}>
                                      <td className="border-0 pe-1">
                                        <div style={{ width: "90px" }}>
                                          <Input
                                            onChange={(e) =>
                                              handleShipCharges(e, i)
                                            }
                                            value={v.fromPrice}
                                            required
                                            id="from"
                                            name="fromPrice"
                                            placeholder="0.00"
                                            type="number"
                                            min={"1"}
                                          />
                                        </div>
                                      </td>
                                      <td className="border-0 pe-1">
                                        <div style={{ width: "90px" }}>
                                          <Input
                                            onChange={(e) =>
                                              handleShipCharges(e, i)
                                            }
                                            required
                                            value={v.toPrice}
                                            id="toPrice"
                                            name="toPrice"
                                            placeholder="0.00"
                                            type="number"
                                            min={"1"}
                                          />
                                        </div>
                                      </td>
                                      <td className="border-0 pe-1">
                                        <div style={{ width: "120px" }}>
                                          <Input
                                            onChange={(e) =>
                                              handleShipCharges(e, i)
                                            }
                                            required
                                            value={v.totalShipCharges}
                                            id="totalCharges"
                                            name="totalShipCharges"
                                            placeholder="0.00"
                                            type="number"
                                            min={"0"}
                                          />
                                        </div>
                                      </td>
                                      <td className="border-0 pe-1">
                                        {i === 0 ? (
                                          <span
                                            className="align-self-center fs-4 text-primary pointer"
                                            onClick={() => {
                                              setMultiShippingCharges([
                                                ...multiShippingCharges,
                                                defaultShipRate,
                                              ]);
                                            }}
                                          >
                                            +
                                          </span>
                                        ) : (
                                          <span
                                            className=" align-self-center fs-1 text-danger pointer"
                                            onClick={() => {
                                              multiShippingCharges.splice(i, 1);
                                              setMultiShippingCharges([
                                                ...multiShippingCharges,
                                              ]);
                                              setSellingData({
                                                ...sellingData,
                                                shippingCharges: [
                                                  ...multiShippingCharges,
                                                ],
                                              });
                                            }}
                                          >
                                            -
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                          <div>
                            <hr className={`mt-1`} />
                            <div className="d-flex justify-content-center">
                              <Button
                                disabled={listingLoading}
                                className=" mr-2"
                                color="secondary"
                                onClick={() => {
                                  setProceedTo(false);
                                }}
                              >
                                Cancel
                              </Button>
                              {listingLoading ? (
                                <Button
                                  type="submit"
                                  color="primary"
                                  style={{ width: "80px", height: "36px" }}
                                  disabled
                                >
                                  <Spinner size="sm" color="light" type="grow">
                                    Loading...
                                  </Spinner>
                                </Button>
                              ) : (
                                <Button type="submit" color="primary">
                                  Submit
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </Offcanvas.Body>
                  </Fade>
                ) : (
                  <Offcanvas.Body>
                    <div className="h-100 overflow-hidden d-flex flex-column justify-content-arround">
                      <div
                        className="mt-1 customScroll"
                        style={{
                          minHeight: "86%",
                          maxHeight: "86%",
                          overflow: "auto",
                        }}
                      >
                        <ul className="list-unstyled">
                          {selectedList?.map((data, i) => (
                            <li key={i}>
                              <div className="d-flex align-items-center mb-3">
                                <span
                                  type="button"
                                  className="btn-close text-reset mr-2 "
                                  style={{ fontSize: "10px" }}
                                  onClick={(e) => {
                                    setMasterCheck(false);
                                    let deleting = selectedList.filter(
                                      (p) => p !== data
                                    );
                                    setSelectedList(deleting);
                                    let setting = products.filter((obj) => {
                                      if (obj === data) {
                                        obj.selected = false;
                                      }
                                      return obj;
                                    });
                                    setProducts([...setting]);
                                  }}
                                ></span>
                                <img
                                  src={
                                    data.image?.src ||
                                    "./img/products/no-image.png"
                                  }
                                  alt="pic not found"
                                  style={{ width: "80px", height: "80px" }}
                                />
                                <div className="ms-2">
                                  <p className="m-0">
                                    {data?.title?.slice(0, 20)}
                                    {data?.title.length > 20 && (
                                      <>
                                        {" ... "}
                                        <i
                                          className=" simple-icon-info pointer position-relative"
                                          id={"Title-" + i}
                                        >
                                          <UncontrolledPopover
                                            trigger="hover"
                                            placement="bottom"
                                            target={"Title-" + i}
                                          >
                                            <PopoverBody className="text-center">
                                              {data.title}
                                            </PopoverBody>
                                          </UncontrolledPopover>
                                        </i>
                                      </>
                                    )}
                                  </p>
                                  <p className="m-0">
                                    <b>Rs: </b>
                                    {numberWithCommas(data.variant.price)}
                                  </p>
                                  <p className="m-0">
                                    <b>Current Quantity: </b>
                                    {numberWithCommas(
                                      data?.inventory.currentQuantity
                                    )}
                                  </p>
                                  <p className="m-0">
                                    <b>Vendor: </b>
                                    {data.vendor}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="" style={{ height: "14%" }}>
                        <hr className="mt-1" />
                        <div className="d-flex justify-content-center">
                          <Button
                            type="button"
                            color="secondary"
                            className=" mr-2"
                            onClick={() => {
                              setProceedTo(false);
                              handleCloseSideBar();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              setProceedTo(true);
                            }}
                          >
                            Proceed
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Offcanvas.Body>
                )}
              </Offcanvas>
            }
          </CardBody>
        </Card>
      )}
    </>
  );
};
