import React, { useState } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import classnames from 'classnames';
import DatatablePagination from '../../wizard/DatatablePagination';
import { Input, Table } from 'reactstrap';
import { useHistory } from 'react-router-dom';
// import { counterIncrement } from 'html2canvas/dist/types/css/property-descriptors/counter-increment';

const DataTable = ({
  setPageLimitInParent,
  setPageNumberInParent,
  columns,
  pageCount: totalPages,
  isAllOrdersChecked,
  checkedOrders,
  onCheckAllOrders,
  onCheckItem,
  data,
  divided = false,
  defaultPageSize = 50,
  fetchData,
  checkCounter,
}) => {
  const history = useHistory();
  const [pageNum, setPageNum] = useState(0);
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageNum, pageSize: defaultPageSize },
      manualPagination: true,
      pageCount: totalPages,
    },
    useSortBy,
    usePagination
  );

  const PageChange = (p) => {
    gotoPage(p);
    setPageNum(p);
    window.scrollTo(0, 0);
    setPageNumberInParent(p + 1);
    fetchData({ page: p + 1, limit: pageSize });
  };

  const PageSizeChange = (s) => {
    defaultPageSize = s;
    setPageSize(s);
    window.scrollTo(0, 0);
    setPageLimitInParent(s);
    fetchData({ page: pageIndex + 1, limit: s });
  };
  const checkedCounter = (counter) => {
    if (counter > 0) {
      return <span>{counter} </span>;
      // } else if (isAllOrdersChecked && counter == false) {
      //   return <span style={{ color: 'gray', fontSize: '12px' }}>{defaultPageSize} order(s)</span>
    }
  };
  const onRowClick = (e, cell) => {
    if (cell.column.id === 'orderId') {
      const { id } = cell.row.original;
      history.push(`/admin/orders/${id}`);
    }
  };

  return (
    <>
      <Table
        striped
        {...getTableProps()}
        className={` ${classnames({
          'table-divided': divided,
        })} my-table-responsive`}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th className="" style={{ width: '1%' }}>
                <p className="text-muted">{checkedCounter(checkCounter)}</p>
                <div className="custom-control custom-checkbox">
                  &nbsp;
                  <Input
                    // style={{ marginLeft: '-10px' }}
                    className="item-check mb-0 mt-2 checkedColor"
                    type="checkbox"
                    checked={isAllOrdersChecked || false}
                    name="checkboxAllOrders"
                    onChange={(e) =>
                      onCheckAllOrders(
                        e,
                        page.map((row) => row.original)
                      )
                    }
                    label=""
                  />
                </div>
              </th>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`${
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'sorted-desc'
                        : 'sorted-asc'
                      : ''
                  }
                   ${columnIndex == 0 ? 'text-center pe-0 ps-0' : ''} `}
                >
                  {column.render('Header')}
                  <span />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <td className="">
                  <label className="lbl-checkbox p-0 ms-1">
                    <div className="custom-control custom-checkbox align-self-center">
                      <Input
                        className="item-check mb-0 checkedColor"
                        type="checkbox"
                        checked={
                          (checkedOrders && checkedOrders[row.original.id]) ||
                          false
                        }
                        name={row.original.id}
                        onChange={(e) =>
                          onCheckItem(e, row.original.id, row.original)
                        }
                        label=""
                      />
                    </div>
                  </label>
                </td>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    className={`table-tab`}
                    key={`td_${cellIndex}`}
                    {...cell.getCellProps({
                      className: cell.column.cellClass,
                    })}
                    onClick={(e) => onRowClick(e, cell)}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <DatatablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[50, 100, 150, 200, 250]}
        showPageSizeOptions={true}
        showPageJump={true}
        defaultPageSize={pageSize}
        onPageChange={(p) => PageChange(p)}
        onPageSizeChange={(s) => PageSizeChange(s)}
        paginationMaxSize={pageCount}
      />
    </>
  );
};

export default DataTable;
