import React, { useState, useEffect, useCallback, useRef } from "react";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import Select from "react-select";
import DataTable from "../../tables/table";

import {
  Row,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Button,
  Alert,
} from "reactstrap";
import {
  updateOrderDispatchStatus,
  getOrderByDispatchStatus,
} from "../../../api/orders";
import { sortByDate, DateFormatted } from "../../../helpers/dateTime";
import { ConsigneeDetails, OrdersDetailsPopup } from "../../Modals/Popups";
import { formatPhoneNumber } from "../../../helpers/phoneNumber";
import { saveBillingLogs } from "../../../api/billing";
import DateFilters from "../../Date/DateFilters.jsx";
import { showItemDetails } from "../../orders/orderTabs/helperFunctions/tableDetailsInfo";
import { toast } from "react-toastify";

const ResellingDeliveredOrders = ({
  setOrderConfirmed,
  setOrderPending,
  orderPending,
  setOrderCancelled,
  setOrderManual,
}) => {
  const [search, setSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [pendingOrders, setPendingOrders] = useState([]);
  const [pendingOrdersLoaded, setPendingOrdersLoaded] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [itemDetailsData, setItemDetailsData] = useState();
  const [orderDetailsData, setOrderDetailsData] = useState();
  const [isAllOrdersChecked, setIsAllOrdersChecked] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});
  const [dateTime, setDateTime] = useState();
  const [selectedOrdersInBulk, setSelectedOrdersInBulk] = useState([]);
  const [areSelectedOrdersProcessing, setAreSelectedOrdersProcessing] =
    useState(false);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [checkCounter, setCheckCounter] = useState(0);
  const [consigneeDetails, setConsigneeDetails] = useState();
  const [showResetButton, setShowResetButton] = useState(false);
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const [searchNameBool, setSearchNameBool] = useState(false);
  const [searchCityBool, setSearchCityBool] = useState(false);
  const dispatchStatusDDRef = useRef(null);
  const [selectedDispatchStatus, setSelectedDispatchStatus] = useState(null);

  let statusDispatchOptions = [{ value: "cancel", label: "Cancel" }];

  // const loadOrders = useCallback(
  //   async ({
  //     page = pageNumber,
  //     limit = pageLimit,
  //     startDateIs = startDate,
  //     endDateIs = endDate,
  //   }) => {
  //     try {
  //       setLoading(true);
  //       const { data } = await getOrderByDispatchStatus({
  //         dispatchStatus: "pending",
  //         isAssigned: false,
  //         page,
  //         limit,
  //         startDateIs,
  //         endDateIs,
  //       });
  //       data && setLoading(false);
  //       setTotalPagesCount(Math.ceil(data.count / limit));
  //       setPendingOrders(data.orders);
  //       setPendingOrdersLoaded(true);
  //       return data.orders;
  //     } catch (error) {
  //       console.log("error found when fetch order data", error);
  //     }
  //   },
  //   [setPendingOrders, pageNumber, pageLimit, endDate]
  // );

  useEffect(() => {
    // // loadOrders({});
    // if (orderPending) {
    //   setOrderPending(false);
    // }
    setPendingOrders([
      {
        _id: "628e09554c50fcd10046757d",
        courier: "",
        seller: "Umar",
        sellerOrder: "121358",
        courier: "M&P",
        courierAssigned: false,
        dispatchStatus: "confirm",
        deliveryStatus: "not_delivered",
        storeId: "62878032582e6500dc75ac17",
        storeName: "codingstorte.myshopify.com",
        orderId: "4787435471066",
        orderRefNumber: "#1013-CodingStorte",
        cancel_reason: null,
        cancelled_at: null,
        created_at: "2022-05-25T15:47:46+05:00",
        current_total_price: "114.84",
        financial_status: "paid",
        fulfillment_status: null,
        name: "#1013",
        note: null,
        order_number: 1013,
        processed_at: "2022-05-25T15:47:46+05:00",
        subtotal_price: "99.00",
        total_price: "114.84",
        total_price_set: {
          shop_money: {
            amount: "114.84",
          },
        },
        total_weight: 0,
        updated_at: "2022-05-25T15:47:47+05:00",
        line_items: [
          {
            id: 12233094725850,
            price: "70.00",
            quantity: 1,
            sku: "AD-03-black-OS",
            title: "ADIDAS | CLASSIC BACKPACK",
          },
          {
            id: 12233094758618,
            price: "29.00",
            quantity: 1,
            sku: "VN-01-burgandy-4",
            title: "VANS |AUTHENTIC | LO PRO | BURGANDY/WHITE",
          },
        ],
        shipmentTracking: [],
        shipperDetail: [
          {
            shopDetail: {
              name: "CodingStorte",
              email: "usamariaz2844@gmail.com",
              domain: "codingstorte.myshopify.com",
              address1: "Punjab Pakistan",
              city: "Faisalabad",
              phone: "",
              country_name: "Pakistan",
            },
          },
        ],
      },
      {
        _id: "628b7c9c4c50fcd100467422",
        courier: "",
        seller: "Zaghum",
        sellerOrder: "55358",
        courier: "M&P",
        courierAssigned: false,
        dispatchStatus: "confirm",
        deliveryStatus: "not_delivered",
        storeId: "62878032582e6500dc75ac17",
        storeName: "codingstorte.myshopify.com",
        orderId: "4785821417690",
        orderRefNumber: "#1008-CodingStorte",
        cancel_reason: null,
        cancelled_at: null,
        created_at: "2022-05-23T17:22:48+05:00",
        current_total_price: "114.84",
        financial_status: "paid",
        fulfillment_status: null,
        name: "#1008",
        note: null,
        order_number: 1008,
        processed_at: "2022-05-23T17:22:48+05:00",
        subtotal_price: "99.00",
        total_price: "114.84",
        total_price_set: {
          shop_money: {
            amount: "114.84",
          },
        },
        total_weight: 0,
        updated_at: "2022-05-23T17:22:49+05:00",
        line_items: [
          {
            id: 12229918916826,
            price: "70.00",
            quantity: 1,
            sku: "AD-03-black-OS",
            title: "ADIDAS | CLASSIC BACKPACK",
          },
          {
            id: 12229918949594,
            price: "29.00",
            quantity: 1,
            sku: "VN-01-burgandy-4",
            title: "VANS |AUTHENTIC | LO PRO | BURGANDY/WHITE",
          },
        ],
        shipmentTracking: [],
        shipperDetail: [
          {
            shopDetail: {
              name: "CodingStorte",
              email: "usamariaz2844@gmail.com",
              domain: "codingstorte.myshopify.com",
              address1: "Punjab Pakistan",
              city: "Faisalabad",
              phone: "",
              country_name: "Pakistan",
            },
          },
        ],
      },
    ]);
  }, []);

  const consigneeDetail = (item) => {
    let orderItems = item.slice(0, 12);
    let itemsLength = item.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 12 ? "...." : ""}`}
          {itemsLength > 12 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="consigneeDetailPopover"
              onMouseEnter={() => {
                setConsigneeDetails([item]);
              }}
            />
          ) : (
            ""
          )}

          <ConsigneeDetails
            consigneeDetails={consigneeDetails && consigneeDetails}
          />
        </div>
      </>
    );
  };

  const orderDetails = (items) => {
    let orderItems = items[0].title.slice(0, 12);
    let itemsLength = items[0].title.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 12 ? "...." : ""}`}
          {itemsLength > 12 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="orderDetailPopover"
              onMouseEnter={() => {
                setOrderDetailsData(items);
              }}
            />
          ) : (
            ""
          )}

          <OrdersDetailsPopup
            orderDetailsData={orderDetailsData && orderDetailsData}
          />
        </div>
      </>
    );
  };

  const onCheckItem = (event, orderId) => {
    const orderDispatchStatus = dispatchStatusDDRef.current.value;
    setCheckboxes({ ...checkboxes, [orderId]: event.target.checked });
    let checkedOrders = [];
    pendingOrders.forEach((order) => {
      if (order.orderId === orderId) {
        checkedOrders.push({ ...order, dispatchStatus: orderDispatchStatus });
      }
    });
    // add checked order to list of selected orders.
    if (event.target.checked) {
      setSelectedOrdersInBulk([...selectedOrdersInBulk, ...checkedOrders]);
    }
    // remove on uncheck
    else {
      if (selectedOrdersInBulk.length > 0) {
        const removeUnCheckedOrder = selectedOrdersInBulk.filter(
          (order) => order.orderId !== orderId
        );
        setSelectedOrdersInBulk(removeUnCheckedOrder);
      }
    }
    if (event.target.checked && orderId) {
      setCheckCounter(checkCounter + 1);
    } else if (!event.target.checked) {
      setCheckCounter(checkCounter - 1);
    }
  };

  const resetFilters = async () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedDateFilter(null);
    setPendingOrdersLoaded(true);
    // await loadOrders({});
  };

  const onCheckAllOrders = (e, ordersToMark) => {
    setIsAllOrdersChecked(e.target.checked);
    const orderIdsToMark = {};
    const ordersCompleteData = [];
    ordersToMark.forEach((order) => {
      orderIdsToMark[order.id] = e.target.checked;
      const matchedOrderInAllOrders = pendingOrders.find(
        (completeOrder) => completeOrder.orderId === order.id
      );
      ordersCompleteData.push({ ...matchedOrderInAllOrders });
    });
    // add checked order to list of selected orders.

    if (e.target.checked) {
      setCheckboxes(orderIdsToMark);
      setSelectedOrdersInBulk(ordersCompleteData);
      setCheckCounter(ordersCompleteData.length);
    }
    // remove on uncheck
    else {
      if (selectedOrdersInBulk.length > 0) {
        setCheckboxes({});
        setSelectedOrdersInBulk([]);
        setCheckCounter(selectedOrdersInBulk.length);
      }
    }
    if (!e.target.checked) {
      return setCheckCounter(0);
    }
  };

  // const handleOrderSubmit = async () => {
  //   if (Object.keys(checkboxes).length > 0) {
  //     const isActionSelected = selectedOrdersInBulk.some(
  //       (order) => order.dispatchStatus !== ""
  //     );
  //     if (!isActionSelected) {
  //       toast.info("Please select action to perform on selected orders");
  //     } else {
  //       setAreSelectedOrdersProcessing(true);
  //       try {
  //         const confirmedOrders = [];
  //         for (const orderToConfirm of selectedOrdersInBulk) {
  //           const { orderId, dispatchStatus, storeId, current_total_price } =
  //             orderToConfirm;
  //           if (dispatchStatus === "confirm") {
  //             await saveBillingLogs({ storeId, current_total_price });
  //           }
  //           const orderDispatchStatusUpdateResult =
  //             await updateOrderDispatchStatus({
  //               orderId,
  //               dispatchStatus,
  //               storeId,
  //             });
  //           if (orderDispatchStatusUpdateResult.status === 200) {
  //             dispatchStatus === "confirm" && setOrderConfirmed(true);
  //             dispatchStatus === "cancel" &&
  //               toast.success("Order Added To Cancelled Successfully!");
  //             dispatchStatus === "confirm" &&
  //               toast.success("Order Confirmed Successfully!");
  //             dispatchStatus === "manual" &&
  //               toast.success("Order Added To Manual Successfully!");
  //             dispatchStatus === "cancel" && setOrderCancelled(true);
  //             dispatchStatus === "manual" && setOrderManual(true);
  //             confirmedOrders.push(orderToConfirm.orderId);
  //           }
  //         }
  //         const remainingOpenOrders = pendingOrders.filter(
  //           (pendingOrders) => !confirmedOrders.includes(pendingOrders.orderId)
  //         );
  //         setPendingOrders(remainingOpenOrders);
  //         setCheckCounter(0);
  //         setSelectedOrdersInBulk([]);
  //         setAreSelectedOrdersProcessing(false);
  //       } catch (e) {
  //         setAreSelectedOrdersProcessing(false);
  //         toast.error(`${e.message}`);
  //       }
  //     }
  //   } else {
  //     toast.info("Please select orders!");
  //   }
  // };

  const ordersData =
    pendingOrders &&
    pendingOrders.length > 0 &&
    pendingOrders
      .filter((order) => {
        if (search === "") {
          return order;
        } else if (order.hasOwnProperty("shipping_address")) {
          return order.shipping_address.name
            ? order.shipping_address.name
                .toLowerCase()
                .includes(search.toLowerCase())
            : "";
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.address1
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return order;
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.phone
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return order;
        } else if (order.name.toLowerCase().includes(search.toLowerCase())) {
          return order;
        }
        return false;
      })
      .map((order) => {
        return {
          id: order.orderId,
          name: order.shipping_address ? order.shipping_address.name : "N/A",
          orderDate: DateFormatted(order.created_at, setDateTime, dateTime),
          address: order?.shipping_address?.address1
            ? consigneeDetail(order?.shipping_address?.address1)
            : "N/A",
          phone: order?.shipping_address?.phone
            ? formatPhoneNumber(order?.shipping_address?.phone)
            : "N/A",
          weight: order.total_weight,
          amount: order.total_price_set.shop_money.amount
            ? Number(order.total_price_set.shop_money.amount).toLocaleString()
            : "N/A",
          referenceNo: order.orderId,
          details: orderDetails(order.line_items),
          remarks: order.note ? order.note : "none",
          orderId: order.name,
          city: order?.shipping_address?.city
            ? order.shipping_address.city
            : "N/A",
          items:
            order.line_items && order.line_items.length > 0
              ? showItemDetails(
                  order.line_items,
                  setItemDetailsData,
                  itemDetailsData
                )
              : "N/A",
          storeName: order.storeName.split(".")[0],
          storeId: order.storeId,
          courier: order.courier,
          seller: order.seller,
          sellerOrder: order.sellerOrder,
          updated_at: order.updatedAt,
          created_at: order.createdAt,
          actions: (
            <select
              key={order.orderId}
              // onChange={(e) => handleOrderDispatchStatus(e, order)}
              className={"form-control form-select"}
              // style={{height:'28px'}}
              ref={dispatchStatusDDRef}
              disabled={checkboxes && checkboxes[order.orderId] ? false : true}
            >
              <option selected disabled value="">
                Select
              </option>

              <option value="Cancel">Cancel</option>
            </select>
          ),
        };
      });

  const cityOrderData =
    pendingOrders &&
    pendingOrders.length > 0 &&
    pendingOrders
      .filter((order) => {
        if (citySearch === "") {
          return order;
        } else if (
          order &&
          order.shipping_address &&
          order.shipping_address.city &&
          order.shipping_address.city
            .toLowerCase()
            .includes(citySearch.toLowerCase())
        ) {
          return order;
        }
        return false;
      })
      .map((order) => ({
        id: order.orderId,
        name: order.shipping_address ? order.shipping_address.name : "N/A",
        orderDate: DateFormatted(order.created_at, setDateTime, dateTime),
        address: order?.shipping_address?.address1
          ? consigneeDetail(order?.shipping_address?.address1)
          : "N/A",
        phone: order?.shipping_address?.phone
          ? formatPhoneNumber(order?.shipping_address?.phone)
          : "N/A",
        weight: order.total_weight,
        amount: order.total_price_set.shop_money.amount
          ? Number(order.total_price_set.shop_money.amount).toLocaleString()
          : "N/A",
        referenceNo: order.orderId,
        details: orderDetails(order.line_items),
        remarks: order.note ? order.note : "none",
        orderId: order.name,
        city: order?.shipping_address?.city
          ? order.shipping_address.city
          : "N/A",
        items:
          order.line_items && order.line_items.length > 0
            ? showItemDetails(
                order.line_items,
                setItemDetailsData,
                itemDetailsData
              )
            : "N/A",
        storeName: order.storeName.split(".")[0],
        storeId: order.storeId,
        courier: order.courier,
        seller: order.seller,
        sellerOrder: order.sellerOrder,
        updated_at: order.updatedAt,
        created_at: order.createdAt,
        actions: (
          <select
            key={order.orderId}
            // onChange={(e) => handleOrderDispatchStatus(e, order)}
            className={"form-control form-select"}
            ref={dispatchStatusDDRef}
            disabled={checkboxes && checkboxes[order.orderId] ? false : true}
          >
            <option selected disabled value="">
              Select
            </option>

            <option value="Cancel">Cancel</option>
          </select>
        ),
      }));

  const cols = React.useMemo(
    () => [
      {
        Header: "Order#",
        accessor: "orderId",
        cellClass: "table-text w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "COD",
        accessor: "amount",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      // {
      //   Header: 'Product Details',
      //   accessor: 'details',
      //   cellClass: 'table-text w-6',
      //   Cell: (props) => <>{props.value}</>,
      // },
      {
        Header: "Items(Count Only)",
        accessor: "items",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Seller",
        accessor: "seller",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "SellerOrder#",
        accessor: "sellerOrder",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Courier",
        accessor: "courier",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Store",
        accessor: "storeName",
        cellClass: "table-text",
        Cell: (props) => <>{props.value}</>,
      },
      // {
      //   Header: 'Store',
      //   accessor: 'storeName',
      //   cellClass: 'table-text w-6',
      //   Cell: (props) => <>{props.value}</>,
      // },
      // {
      //     Header: "Actions",
      //     accessor: "actions",
      //     cellClass: "text-muted w-6",
      //     Cell: ({cell: {row}}) =>
      //         <Button
      //             value="Confirm"
      //             onClick={(e) => handleOrderDispatchStatus(e, row)}>Confirm</Button>
      // },
      // {
      //   Header: 'Actions',
      //   accessor: 'actions',
      //   cellClass: 'table-text w-3',
      //   Cell: (props) => <>{props.value}</>,
      // },
    ],
    []
  );

  // const handleOrderDispatchStatus = (e, data) => {
  //   e.preventDefault();
  //   const dispatchAction = e.target.value.toLowerCase();
  //   const ordersWithDispatchStatusUpdated = selectedOrdersInBulk.map(
  //     (selectedOrder) => {
  //       if (selectedOrder.orderId === data.orderId) {
  //         return { ...selectedOrder, dispatchStatus: dispatchAction };
  //       }
  //       return selectedOrder;
  //     }
  //   );
  //   setSelectedOrdersInBulk([...ordersWithDispatchStatusUpdated]);
  // };

  // const handleOrderStatus = (e) => {
  //   if (e) {
  //     setSelectedDispatchStatus(e.label);
  //     let dispatchAction = e.value.toLowerCase();

  //     const ordersWithDispatchStatusUpdated = selectedOrdersInBulk.map(
  //       (data) => {
  //         return { ...data, dispatchStatus: dispatchAction };
  //       }
  //     );

  //     setSelectedOrdersInBulk([...ordersWithDispatchStatusUpdated]);
  //   } else {
  //     setSelectedDispatchStatus(null);

  //     const ordersWithDispatchStatusUpdated = selectedOrdersInBulk.map(
  //       (data) => {
  //         return { ...data, dispatchStatus: "" };
  //       }
  //     );

  //     setSelectedOrdersInBulk([...ordersWithDispatchStatusUpdated]);
  //   }
  // };

  return (
    <>
      {pendingOrdersLoaded && pendingOrders.length <= 0 ? (
        <Card className="my-3 py-5">
          <CardBody
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h4 style={{ color: "red" }}>
              <b>No Order Pending!</b>
            </h4>
            {showResetButton === false ? (
              ""
            ) : (
              <Button
                color="primary"
                className="mt-1 btn-sm"
                onClick={resetFilters}
              >
                Reset Data
              </Button>
            )}
          </CardBody>
        </Card>
      ) : !pendingOrdersLoaded && pendingOrders.length === 0 ? (
        <>
          <div className="loading" />
          <div
            style={{
              height: "70vh",
            }}
          ></div>
        </>
      ) : (
        <Row>
          <Cols xxs="12">
            <Card className="mb-4">
              <CardBody className={{ padding: "0px" }}>
                <CardTitle className="order_table_card_title">
                  <div className="container d-flex justify-content-between flex-wrap">
                    <div className="search-sm mb-2 global-search-font me-1">
                      <input
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          if (e) {
                            setSearch(e.target.value);
                            setSearchNameBool(true);
                          } else {
                            setSearchNameBool(false);
                          }
                        }}
                      />
                    </div>
                    <div>
                      <DateFilters
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        selectedDateFilter={selectedDateFilter}
                        setSelectedDateFilter={setSelectedDateFilter}
                        setShowResetButton={setShowResetButton}
                        // loadOrders={loadOrders}
                        resetFilters={resetFilters}
                      />
                    </div>
                    <div className="city_search_container d-flex flex-wrap alignTop">
                      <div className="search-sm d-inline-block confirm-city-search me-2 mb-1">
                        <input
                          type="text"
                          name="keyword"
                          id="city_search"
                          placeholder="Search City"
                          value={citySearch}
                          onChange={(e) => {
                            if (e) {
                              setSearchCityBool(true);
                              setCitySearch(e.target.value);
                            } else {
                              setSearchCityBool(false);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </CardTitle>
                {loading && (
                  <div className="mx-2" style={{ marginBottom: "-5px" }}>
                    <Alert color="success">
                      <Spinner
                        color="success"
                        size={"sm"}
                        style={{ marginBottom: "2px" }}
                      ></Spinner>{" "}
                      &nbsp;
                      <span style={{ fontSize: "15px" }}>Orders Loading!</span>
                    </Alert>
                  </div>
                )}

                {((cityOrderData.length === 0 && searchCityBool) ||
                  (ordersData.length === 0 && searchNameBool)) && (
                  <h4
                    style={{
                      color: "red",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <b>No Order Matched!</b>
                  </h4>
                )}

                {cityOrderData.length !== 0 && ordersData.length !== 0 && (
                  <DataTable
                    setPageLimitInParent={setPageLimit}
                    setPageNumberInParent={setPageNumber}
                    // fetchData={loadOrders}
                    pageCount={totalPagesCount}
                    columns={cols}
                    data={
                      citySearch !== ""
                        ? cityOrderData.sort(sortByDate)
                        : ordersData.sort(sortByDate)
                    }
                    onCheckItem={onCheckItem}
                    checkedOrders={checkboxes}
                    isAllOrdersChecked={isAllOrdersChecked}
                    onCheckAllOrders={onCheckAllOrders}
                    checkCounter={checkCounter}
                    FixedPageSize={50}
                  />
                )}
              </CardBody>
            </Card>
          </Cols>
        </Row>
      )}
    </>
  );
};

export default ResellingDeliveredOrders;
