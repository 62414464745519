import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

const ResellingOrderTabs = ({ activeTab, setActiveTab }) => {
  return (
    <>
      <Nav
        tabs
        className="separator-tabs ml-0 mb-5"
        style={{ cursor: "pointer" }}
      >
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "assignedOrders",
              "nav-link": true,
            })} ${activeTab === "assignedOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "assignedOrders");
              setActiveTab("assignedOrders");
            }}
            location={{}}
            to="#"
          >
            {"Booked"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={` ${classnames({
              active: activeTab === "fulfilledOrders",
              "nav-link": true,
            })} ${activeTab === "fulfilledOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "fulfilledOrders");
              setActiveTab("fulfilledOrders");
            }}
            location={{}}
            to="#"
          >
            {"Fulfilled"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={` ${classnames({
              active: activeTab === "cancelledOrders",
              "nav-link": true,
            })} ${activeTab === "cancelledOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "cancelledOrders");
              setActiveTab("cancelledOrders");
            }}
            location={{}}
            to="#"
          >
            {"Cancelled"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={` ${classnames({
              active: activeTab === "manualOrders",
              "nav-link": true,
            })} ${activeTab === "manualOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "manualOrders");
              setActiveTab("manualOrders");
            }}
            location={{}}
            to="#"
          >
            {"Manual"}
          </NavLink>
        </NavItem>
      </Nav>
    </>
  );
};

export default ResellingOrderTabs;
